import { getDate } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import Table from 'components/Table/Table'
import AppRoutes from 'constants/appRoutes'
import i18n from 'i18n'
import PhoneFlag from 'pages/CRMUserDetail/ComponenteTablas/PhoneFlag'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Flag } from 'semantic-ui-react'
import { getManagerList } from 'store/actions'

export const ManagerList = ({ t, getManagerList, managerList, isLoading, error, history }) => {
	useEffect(() => {
		getManagerList()
	}, [])


	const headerToRender = {
		id: t('id', { ns: 'naming' }),
		name: t('name', { ns: 'naming' }),
		email: t('email', { ns: 'naming' }),
		customerCount: t('customerActiveAssigned', { ns: 'naming' }),
		efficiency: t('efficiency', { ns: 'naming' }),
		registerDate: t('registerDate', { ns: 'naming' }),
		languages: t('languages', { ns: 'naming' }),
		phone: t('phone', { ns: 'naming' }),
	}

	const calculateEfficiency = (activeCustomersCount, customersCount) => {
		if (customersCount === 0) return 0
		return Math.round((activeCustomersCount / customersCount) * 100)
	}

	const mapManagers = (managers) => {
		if (managers.length === 0) return []
		return managers.map(manager => {
			const efficiency = calculateEfficiency(manager.activeCustomersCount, manager.customersCount)
			return {
				id: manager.id,
				name: `${manager.personalInformation.firstName} ${manager.personalInformation.lastName}`,
				email: manager.loginCredentials.email,
				registerDate: getDate(manager.statistics.registerDate),
				languages: manager.languages != null && manager.languages.length > 0 && <div className='d-flex flex-row'>{
					manager.languages.map((language, index) => {
						if (language == null) return
						return (
							<Flag key={`${manager.id}_${language.toLowerCase()}_${index}`} name={language} />
						)
					})
				}</div>,
				phone: <PhoneFlag prefix={manager.phoneData.phone.prefix} phoneNumber={manager.phoneData.phone.number} />,
				customerCount: <span><span className="m-5 rounded-pill bg-secondary text-white">{manager.activeCustomersCount}</span><span className="m-5 rounded-pill bg-secondary text-white">{manager.customersCount}</span></span>,
				efficiency: <span>{efficiency}%</span>


			}
		})
	}

	const managerToRender = useMemo(() => mapManagers(managerList), [managerList, i18n.language])

	return (
		<div className="page-content">
			<MetaTags>
				<title>Proto&Go! | {t('managers', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('configuration', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('managers', { ns: 'naming' }),
						link: '/managers',
					},
				]}
			/>
			<button
				type="button"
				className="btn btn-secondary my-2"
				onClick={e => {
					history.push('/create-manager')
				}}
			>
				{t('add_new_manager', { ns: 'naming' })}
			</button>
			<div >
				{
					error ? <Alert type="danger" fitContent message={error} /> : isLoading ? <Loader className="font-size-18" /> :
						<Table
							header={headerToRender}
							selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
							items={managerToRender}
							pageToNavigate={AppRoutes.MANAGER_DETAIL_ROUTE_NO_ID}
							language="es"
							customFilter
							rowQuantityList={[
								{ text: '8', value: 8 },

								{ text: '16', value: 16 },

								{ text: '32', value: 32 },
							]}
							allowAllItemsPerPage
							defaultSort={{
								dataField: 'id',
								order: 'asc',
							}}
							paginationProps={{
								custom: true,
								sizePerPage: 20,
							}}
							styleForFieldList={[
								{
									field: 'id',
									styles: [{
										type: 'link',
									}],
								},
								{
									field: 'registerDate',
									styles: [{ type: 'list', class: 'd-block' }],
								},
							]}
						/>

				}

			</div>
		</div>
	)
}

ManagerList.propTypes = {
	t: PropTypes.func,
	getManagerList: PropTypes.func,
	managerList: PropTypes.array,
	error: PropTypes.object,
	isLoading: PropTypes.bool,
	history: PropTypes.func,
}

const mapStateToProps = (state) => ({
	managerList: state.Users.managerList,
	error: state.Users.error,
	isLoading: state.Users.isLoading,
})

const mapDispatchToProps = { getManagerList }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ManagerList)))
