import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { deleteModifiedBlueprints } from 'store/actions'

export const DeleteModifiedBlueprintsModal = ({ t, parts, deleteModifiedBlueprints, isLoading, isDeleteBlueprintsCompleted, isOpenModal, closeModal }) => {

	const partIds = parts?.map(part => part.id.value)

	useEffect(() => {
		if (isDeleteBlueprintsCompleted) {
			location.reload()
		}
	}, [isDeleteBlueprintsCompleted])

	const mapParts = () => {
		return parts?.map((part, index) => {
			return (
				<div
					key={index}
					style={{ marginBottom: '60px' }}
					className="col-md-3"
				>
					<div
						className="mt-2"
						style={{ border: '1.5px solid lightgray', borderRadius: '5px', height: '100%' }}
					>
						<div
							style={{ flex: 1 }}
							className="d-flex justify-content-center align-items-center bg-gray p-3"
						>
							<img src={part.imageUrl} alt={part.name} width="80" height="80" />
						</div>
						<div className="d-flex flex-column p-3">
							<h5>{part.id.value}</h5>
							<p style={{ wordBreak: 'break-all', color: 'gray' }}>{part.name}.stp</p>
							{part?.blueprints[1]?.name && (
								<p style={{ wordBreak: 'break-all', color: 'gray' }}>
									<i className="mdi mdi-file-pdf-outline align-middle me-2" style={{ color: 'red' }} />{part?.blueprints[1]?.name}
								</p>
							)}
						</div>
					</div>
				</div>
			)
		})
	}

	return (
		<Modal
			title={'deleteBluePrint'}
			size='lg'
			body={
				<div className='p-4 '>{parts?.length === 0 ? <div>{t('select_at_least_one', { ns: 'naming' })}</div> :
					<div>
						{t('sure_delete_modified_blueprints', { ns: 'naming' })}
						<div className='d-flex justify-content-center'>
							<div className="row mt-2 mb-2 mx-4" style={{ width: '100%' }}>{mapParts()}</div>
						</div>

					</div>
				}</div>
			}
			buttons={[<button disabled={parts.length === 0} className='btn btn-primary' onClick={() => deleteModifiedBlueprints({ data: { partIds } })} key={'delete_blueprint'}>{isLoading ? <Loader /> : t('delete', { ns: 'naming' })}</button>]}
			isOpen={isOpenModal}
			closeModal={closeModal}
		/>
	)
}

DeleteModifiedBlueprintsModal.propTypes = {
	t: PropTypes.func,
	parts: PropTypes.array,
	isLoading: PropTypes.bool,
	isDeleteBlueprintsCompleted: PropTypes.bool,
	isOpenModal: PropTypes.bool,
	closeModal: PropTypes.func,
	deleteModifiedBlueprints: PropTypes.func,
}

const mapStateToProps = (state) => ({
	isLoading: state.CloudStorage.isLoading,
	isDeleteBlueprintsCompleted: state.CloudStorage.isDeleteBlueprintsCompleted,
})

const mapDispatchToProps = {
	deleteModifiedBlueprints,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeleteModifiedBlueprintsModal))
