import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addExpeditionOrder, addScannedPieceToExpeditionOrder, getOpenedExpeditionOrders, getProductionItemsByOrderId, scanOrderExpeditionPart, setLastScannedPart, setSelectedExpeditionOrderId } from 'store/actions'
import useScanDetection from 'use-scan-detection'
import barcodeScanner from '../../../assets/images/logistic/barcodeScanner.png'
import Loader from '../../../components/Common/Loader'
import SelectedExpeditionOrder from './SelectedExpeditionOrder'

const ExpeditionOrderInfo = (
	{
		t,
		selectedExpeditionOrderId,
		setSelectedExpeditionOrderId,
		isLoadingOrders,
		getProductionItemsByOrderId,
		productionItemsByOrderId,
		addExpeditionOrder,
		expeditionOrderList,
		addScannedPieceToExpeditionOrder,
		setManualScanned,
		manualScanned,
		openedExpeditonOrders,
		boxList,
		scanOrderExpeditionPart,
		setLastScannedPart,
	}) => {
	const productionItems = useMemo(() => productionItemsByOrderId || [], [productionItemsByOrderId])
	const selectedOrder = useMemo(() => expeditionOrderList.find((order) => order.id === selectedExpeditionOrderId?.orderId) || {}, [expeditionOrderList, selectedExpeditionOrderId])
	const [scannedPart, setScannedPart] = useState(null)
	const [scannedQuantity, setScannedQuantity] = useState(null)
	const [scannedOrder, setScannedOrder] = useState(null)
	const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
	const [errorMessages, setErrorMessages] = useState('')
	const [isBoxModalOpen, setIsBoxModalOpen] = useState(false)
	const INCIDENCE = 'partIncidenceError'
	const NOT_VERIFIED = 'partNotVerifiedError'

	const getScannedPartData = (code) => {
		if (code.includes('-')) {
			const shiftIndex = code.indexOf('ShiftS')
			const orderNumber = code.substring(0, shiftIndex)
			const startIndex = code.indexOf('-')
			const endIndex = code.lastIndexOf('-')
			const idPart = code.substring(startIndex + 1, endIndex)
			const quantity = code.substring(endIndex + 1)
			return { orderNumber, idPart, quantity }
		} else {
			const shiftIndex = code.indexOf('ShiftS')
			const orderNumber = code.substring(0, shiftIndex)
			const startIndex = code.indexOf('\'')
			const endIndex = code.lastIndexOf('\'')
			const idPart = code.substring(startIndex + 1, endIndex)
			const quantity = code.substring(endIndex + 1)
			return { orderNumber, idPart, quantity }
		}
	}

	const scanPart = (code) => {
		const { orderNumber, idPart, quantity } = getScannedPartData(code)
		const orderExists = expeditionOrderList.some((order) => order.id == orderNumber)
		if (!orderExists) return
		setScannedOrder(Number(orderNumber))
		setScannedPart(Number(idPart))
		setLastScannedPart(Number(idPart))
		setScannedQuantity(Number(quantity))
		setSelectedExpeditionOrderId(Number(orderNumber))
		setIsBoxModalOpen(true)
	}

	useScanDetection({
		onComplete: (code) => {
			scanPart(code)
		},
	})

	const checkBoxCode = (orderId) => {
		const boxCodes = []
		boxList.forEach((box) => {
			if (box.orderId == orderId) {
				boxCodes.push(box.ref)
			}
		})
		return boxCodes.join(', ')
	}

	const checkIfCompleted = (order) => {
		let completed = true
		order.orderItems.forEach((item) => {
			if (item.shippedQuantity < item.quantity) {
				completed = false
			}
		})
		return completed
	}

	useEffect(() => {
		if (openedExpeditonOrders.length > 0) {
			openedExpeditonOrders.forEach((order) => {
				const parsedOrderDetail = {
					...order,
					expeditions: [],
					boxCode: checkBoxCode(order.id),
					billingInfo: order.shippingAddress,
					completed: checkIfCompleted(order),
				}
				addExpeditionOrder(parsedOrderDetail, order.id)
			})
		}
	}, [openedExpeditonOrders])

	useEffect(() => {
		if (selectedExpeditionOrderId && selectedExpeditionOrderId?.orderId != 0) {
			getProductionItemsByOrderId({ orderId: selectedExpeditionOrderId?.orderId })
		}
	}, [selectedExpeditionOrderId])

	useEffect(() => {
		if (scannedPart && scannedOrder && scannedQuantity && (selectedOrder?.id == scannedOrder)) {
			const scannedItem = selectedOrder?.orderItems?.find((item) => item.part.id === scannedPart)
			const previousScannedQuantity = selectedOrder?.orderItems?.find((item) => item.part.id === scannedPart)?.scannedQuantity
			const totalScannedQuantity = previousScannedQuantity + scannedQuantity
			const shippedQuantity = selectedOrder?.orderItems?.find((item) => item.part.id === scannedPart)?.shippedQuantity
			const leftToScanQuantity = selectedOrder?.orderItems?.find((item) => item.part.id === scannedPart)?.quantity - shippedQuantity

			if (scannedItem?.qualityState == null) {
				setErrorMessages(NOT_VERIFIED)
				setIsOpenErrorModal(true)
				setScannedPart(null)
				setScannedQuantity(null)
				setScannedOrder(null)
				return
			}
			if (scannedItem?.qualityState == false) {
				setErrorMessages(INCIDENCE)
				setIsOpenErrorModal(true)
			}

			addScannedPieceToExpeditionOrder(scannedPart, scannedOrder, scannedQuantity)
			scanOrderExpeditionPart({
				data: {
					orderId: scannedOrder,
					partId: scannedPart,
					scannedQuantity: scannedQuantity,
				},
			})
			setScannedPart(null)
			setScannedQuantity(null)
			setScannedOrder(null)

		}
	}, [scannedPart, scannedOrder, scannedQuantity, selectedOrder])

	useEffect(() => {
		if (manualScanned) {
			const regex = /^(\d+)S\d+-(\d+)-(\d+)$/
			const resultado = manualScanned.match(regex)
			const orderExists = expeditionOrderList.some((order) => order.id == resultado[1])
			if (!orderExists) return
			setScannedOrder(Number(resultado[1]))
			setScannedPart(Number(resultado[2]))
			setLastScannedPart(Number(resultado[2]))
			setScannedQuantity(Number(resultado[3]))
			setSelectedExpeditionOrderId(Number(resultado[1]))
			setManualScanned(null)
		}
	}, [manualScanned])

	return (
		<>
			{!selectedExpeditionOrderId ? (
				<div
					className="d-flex flex-column align-content-center text-center bg-transparent"
					style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}
				>
					<h3 className="mt-4">{t('scanPiece', { ns: 'naming' })}</h3>
					<img src={barcodeScanner}
						alt="barcodeScanner"
						style={{ height: '500px', width: '100%', objectFit: 'contain', background: 'transparent' }}
					/>
				</div>
			) : (
				<>
					{isLoadingOrders ? (
						<div className="d-flex justify-content-center">
							<Loader className="fs-1 p-4" />
						</div>
					) : (
						<>
							<SelectedExpeditionOrder orderInfo={selectedOrder} productionItems={productionItems} />
							<Modal
								isOpen={isOpenErrorModal}
								closeModal={() => setIsOpenErrorModal(false)}
								title={t('error_message', { ns: 'naming' })}
								body={
									<div>
										<p className="pt-3 ps-3 pe-3">{t(errorMessages, { ns: 'naming' })}</p>
									</div>
								}
							/>
						</>
					)
					}
				</>
			)}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		isLoadingOrders: state.Orders.isLoading,
		productionItemsByOrderId: state.Productions.productionItemsByOrderId,
		expeditionOrderList: state.Orders.expeditionOrderList,
		openedExpeditonOrders: state.Orders.openedExpeditonOrders,
		boxList: state.Orders.boxList,
	}
}

ExpeditionOrderInfo.propTypes = {
	t: PropTypes.func,
	selectedExpeditionOrderId: PropTypes.any,
	setSelectedExpeditionOrderId: PropTypes.func,
	isLoadingOrders: PropTypes.bool,
	getProductionItemsByOrderId: PropTypes.func,
	productionItemsByOrderId: PropTypes.any,
	expeditionOrderList: PropTypes.any,
	addExpeditionOrder: PropTypes.func,
	addScannedPieceToExpeditionOrder: PropTypes.func,
	manualScanned: PropTypes.number,
	setManualScanned: PropTypes.func,
	getOpenedExpeditionOrders: PropTypes.func,
	openedExpeditonOrders: PropTypes.any,
	boxList: PropTypes.any,
	scanOrderExpeditionPart: PropTypes.func,
	setLastScannedPart: PropTypes.func,
}

export default connect(mapStateToProps, {
	getProductionItemsByOrderId,
	addExpeditionOrder,
	addScannedPieceToExpeditionOrder,
	getOpenedExpeditionOrders,
	setSelectedExpeditionOrderId,
	scanOrderExpeditionPart,
	setLastScannedPart,
})(
	withTranslation()(ExpeditionOrderInfo),
)
