import AppRoutes from 'constants/appRoutes'
//Icons

//Pages
import Pages404 from '../pages/Utility/pages-404'
import Pages500 from '../pages/Utility/pages-500'
import PagesComingsoon from '../pages/Utility/pages-comingsoon'
import PagesMaintenance from '../pages/Utility/pages-maintenance'

//MY SANDBOX
import Sandbox from '../pages/Sandbox/Sandbox'

import Offer from '../pages/Offer/Offer'
import OfferList from '../pages/OfferList/OfferList'
import Quote from '../pages/Quote/Quote'
import QuoteList from '../pages/QuoteList/QuoteList'

import HolidaysCalendar from 'pages/CalendarHolidays/index'
import Inbox from 'pages/Inbox/Inbox'
import SupplierTable from 'pages/Suppliers/SupplierList'
import RedirectLogin from '../components/Common/RedirectLogin'

import Account from 'pages/Account/Account'
import ForgetPassword from 'pages/Authentication/ForgetPassword'
import Login from 'pages/Authentication/Login'
import ResetPassword from 'pages/Authentication/ResetPassword'
import BillingList from 'pages/BillingList/BillingList'
import Boxes from 'pages/Boxes/Boxes'
import Carrier from 'pages/Carrier/Carrier'
import ControlPanel from 'pages/ControlPanel/ControlPanel'
import DashboardsContainer from 'pages/Dashboards/DashboardsContainer'
import DiscountVoucher from 'pages/DiscountVoucher/DiscountVoucher'
import Expeditions from 'pages/Expeditions/Expeditions'
import Incidences from 'pages/Incidences/Incidences'
import LogisticShipments from 'pages/LogisticsShipments/LogisticsShipments'
import ManagerDetail from 'pages/Managers/ManagerDetail/ManagerDetail'
import ManagerList from 'pages/Managers/ManagersList/ManagerList'
import NewProduction from 'pages/NewProduction/NewProduction'
import OrderDetail from 'pages/OrderDetail/OrderDetail'
import OrderInvoiceGenerator from 'pages/OrderInvoiceGenerator/OrderInvoiceGenerator'
import OrderInvoiceGeneratorLogin from 'pages/OrderInvoiceGenerator/OrderInvoiceGeneratorLogin'
import Orders from 'pages/Orders/Orders'
import Prices from 'pages/Prices/Prices'
import ProductionDetail from 'pages/ProductionDetail/ProductionDetail'
import ProductionSupplier from 'pages/ProductionSupplier/ProductionSupplier'
import Productions from 'pages/Productions/Productions'
import Receptions from 'pages/Receptions/Receptions'
import SalesGoals from 'pages/SalesGoals/SalesGoals'
import Shipments from 'pages/Shipments/Shipments'
import SupplierBilling from 'pages/SupplierBilling/SupplierBilling'
import SupplierProductions from 'pages/SupplierProduction/SupplierProductions'
import SupplierDetail from 'pages/Suppliers/SupplierDetail'
import UserDetail from 'pages/UserDetail/UserDetail'
import UserList from 'pages/UserList/UserList'
import UserProfile from 'pages/UserProfile/UserProfile'
import Verification from 'pages/Verification/Verification'

const homepageRoutes = [
	{ path: '/inbox', component: Inbox },
	{ path: '/reports', component: DashboardsContainer },
	{ path: '/productions', component: Productions },
	{ path: '/billing', component: BillingList },
	{ path: '/supplier-productions', component: SupplierProductions },
	{ path: '/supplier-shipments', component: Shipments },
	{ path: '/supplier-account', component: Account },
	{ path: '/receptions', component: Receptions },
	{ path: '/expeditions', component: Expeditions },
	{ path: '/logistics-shipments', component: LogisticShipments },
	{ path: '/boxes', component: Boxes },
	{ path: '/verification', component: Verification },
	{ path: '/incidences', component: Incidences },
	{ path: '/discount-voucher', component: DiscountVoucher },
	{ path: '/carrier', component: Carrier },
	{ path: '/sales-goals', component: SalesGoals },
	{ path: '/prices', component: Prices },
	{ path: '/profile', component: UserProfile },
	{ path: '/control-panel', component: ControlPanel },
]

const offerRoutes = [
	{ path: AppRoutes.OFFER_LIST_ROUTE, component: OfferList },
	{ path: AppRoutes.OFFER_DETAIL_ROUTE, component: Offer },
]

const quoteRoutes = [
	{ path: AppRoutes.QUOTE_LIST_ROUTE, component: QuoteList },
	{ path: AppRoutes.QUOTE_DETAIL_ROUTE, component: Quote },
]

const productionRoutes = [
	{ path: AppRoutes.NEW_PRODUCTION_ROUTE, component: NewProduction },
	{ path: '/production/:id', component: ProductionDetail },
]

const crmRoutes = [
	{ path: AppRoutes.CRM_USER_LIST_ROUTE, component: UserList },
	{ path: AppRoutes.CRM_USER_DETAIL_ROUTE, component: UserDetail },
]
const managementRoutes = [
	{ path: '/supplier-register', component: SupplierDetail },
	{ path: '/supplier-list', component: SupplierTable },
	{ path: '/supplier/:id', component: SupplierDetail },
	{ path: '/create-manager', component: ManagerDetail },
	{ path: '/managers', component: ManagerList },
	{ path: '/holidays', component: HolidaysCalendar },
	{ path: '/orders', component: Orders },
	{ path: '/order/:id', component: OrderDetail },
	{ path: '/manager-detail/:id', component: ManagerDetail },
	{ path: '/generate-order-pdfs', component: OrderInvoiceGenerator },
]

const supplierRoutes = [
	{ path: '/productions', component: Productions },
	{ path: '/supplier-productions', component: SupplierProductions },
	{ path: '/supplier-shipments', component: Shipments },
	{ path: '/supplier-billing', component: SupplierBilling },
	{ path: '/supplier-account', component: Account },
	{ path: '/profile', component: UserProfile },

	//QUOTATIONS
	{ path: AppRoutes.QUOTE_LIST_ROUTE, component: QuoteList },
	{ path: AppRoutes.QUOTE_DETAIL_ROUTE, component: Quote },

	//PRODUCTIONS
	{ path: '/supplier-production/:id', component: ProductionSupplier },
]

const authProtectedRoutes = [
	{
		path: '/',
		exact: true,
		component: RedirectLogin,
	},

	//MY SANDBOX-AUTH
	{ path: AppRoutes.SANDBOX_AUTH_ROUTE, component: Sandbox },
	...homepageRoutes,
	...offerRoutes,
	...quoteRoutes,
	...productionRoutes,
	...crmRoutes,
	...managementRoutes,
]

const supplierProtectedRoutes = [
	...supplierRoutes,
]

const publicRoutes = [
	{ path: AppRoutes.LOGIN_ROUTE, component: Login },
	{ path: AppRoutes.FORGOT_PASSWORD_ROUTE, component: ForgetPassword },
	{ path: AppRoutes.RESET_PASSWORD_ROUTE, component: ResetPassword },
	{ path: '/generate-order-pdfs-login', component: OrderInvoiceGeneratorLogin },

	{ path: '/pages-maintenance', component: PagesMaintenance },
	{ path: '/pages-comingsoon', component: PagesComingsoon },
	{ path: '/pages-404', component: Pages404 },
	{ path: '/pages-500', component: Pages500 },

	//MY SANDBOX
	{ path: AppRoutes.SANDBOX_ROUTE, component: Sandbox },
]

export { authProtectedRoutes, publicRoutes, supplierProtectedRoutes }

