import { call, put, takeEvery } from 'redux-saga/effects'
import { get, post, put as putReq } from '../../helpers/api_helper'

// Crypto Redux States
import {
	COMPLETE_PRODUCTION,
	GET_FILTERED_PRODUCTIONS,
	GET_PRODUCTIONS_BY_TRACKING,
	GET_PRODUCTIONS_LIST_FROM_ORDER_ID,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER,
	GET_PRODUCTION_CHAT,
	GET_PRODUCTION_DETAIL,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID,
	GET_PRODUCTION_ITEMS_BY_PART_ID,
	GET_PRODUCTION_ITEMS_LABELS,
	GET_PRODUCTION_LIST,
	GET_PRODUCTION_LIST_BY_SUPPLIER,
	GET_PURCHASES_DASHBOARD,
	GET_SHIPMENTS_IMPORTS,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST,
	HANDLE_COMPLETE_PRODUCTION,
	LAUNCH_PRODUCTION,
	POST_PRODUCITON_CHAT,
	REJECT_PRODUCTION,
	UPDATE_PRODUCTION,
	UPDATE_SHIPMENT_IMPORT,
} from './actionTypes'

import {
	completeProductionFail,
	completeProductionSuccess,
	getFilteredProductionsFail,
	getFilteredProductionsSuccess,
	getProductionChatFail,
	getProductionChatSuccess,
	getProductionDetailFail,
	getProductionDetailSuccess,
	getProductionItemsByOrderIdFail,
	getProductionItemsByOrderIdSuccess,
	getProductionItemsByPartIdFail,
	getProductionItemsByPartIdSuccess,
	getProductionItemsLabelsFail,
	getProductionItemsLabelsSuccess,
	getProductionListBySupplierFail,
	getProductionListBySupplierSuccess,
	getProductionListFail,
	getProductionListSuccess,
	getProductionsByTrackingFail,
	getProductionsByTrackingSuccess,
	getProductionsListFromOrderIdFail,
	getProductionsListFromOrderIdSuccess,
	getProductionsPickedUpByCourierFail,
	getProductionsPickedUpByCourierSuccess,
	getPurchasesDashboardFail,
	getPurchasesDashboardSuccess,
	getShipmentsImportsFail,
	getShipmentsImportsSuccess,
	getSupplierShipmentPricingListFail,
	getSupplierShipmentPricingListSuccess,
	handleCompleteProductionFail,
	handleCompleteProductionSuccess,
	launchProductionFail,
	launchProductionSuccess,
	postProductionChatFail,
	postProductionChatSuccess,
	rejectProductionFail,
	rejectProductionSuccess,
	updateProductionFail,
	updateProductionSuccess,
	updateShipmentImportFail,
	updateShipmentImportSuccess
} from './actions'

import {
	COMPLETE_PRODUCTION_ENDPOINT,
	GET_FILTERED_PRODUCTIONS_ENDPOINT,
	GET_PRODUCTIONS_BY_TRACKING_ENDPOINT,
	GET_PRODUCTIONS_LIST_BY_ORDER_ID_ENDPOINT,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER_ENDPOINT,
	GET_PRODUCTION_CHAT_ENDPOINT,
	GET_PRODUCTION_DETAIL_ENDPOINT,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID_ENDPOINT,
	GET_PRODUCTION_ITEMS_BY_PART_ID_ENDPOINT,
	GET_PRODUCTION_ITEMS_LABELS_ENDPOINT,
	GET_PRODUCTION_LIST_BY_SUPPLIER_ENDPOINT,
	GET_PRODUCTION_LIST_ENDPOINT,
	GET_PURCHASES_DASHBOARD_ENDPOINT,
	GET_SHIPMENTS_IMPORTS_ENDPOINT,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST_ENDPOINT,
	HANDLE_COMPLETE_PRODUCTION_ENDPOINT,
	LAUNCH_PRODUCTION_ENDPOINT,
	POST_PRODUCITON_CHAT_ENDPOINT,
	REJECT_PRODUCTION_ENDPOINT,
	UPDATE_PRODUCTION_ENDPOINT,
	UPDATE_SHIPMENT_IMPORT_ENDPOINT
} from '../../constants/backendRoutes'

import ApiHelper from '../../helpers/api_helper'

function* getProductionDetail({ payload: { id, fragId } }) {
	try {
		const url = `${GET_PRODUCTION_DETAIL_ENDPOINT}/${id}/${fragId}`
		const response = yield call(get, url)
		yield put(getProductionDetailSuccess(response))
	} catch (error) {
		yield put(getProductionDetailFail(error))
	}
}

function* getProductionListBySupplier({ payload: { id, page, pageSize } }) {
	try {
		const url = `${GET_PRODUCTION_LIST_BY_SUPPLIER_ENDPOINT}/${id}${page && pageSize ? `?page=${page}&pageSize=${pageSize}` : ''}`
		const response = yield call(get, url)
		yield put(getProductionListBySupplierSuccess(response))
	} catch (error) {
		yield put(getProductionListBySupplierFail(error))
	}
}

function* getProductionList({ payload: { page, pageSize } }) {
	if (!page || !pageSize)
		throw new Error('PGO - ADMIN: Page and pageSize are required')
	try {
		const url = `${GET_PRODUCTION_LIST_ENDPOINT}${page && pageSize ? `?page=${page}&pageSize=${pageSize}` : ''}`
		const response = yield call(get, url)
		yield put(getProductionListSuccess(response))
	} catch (error) {
		yield put(getProductionListFail(error))
	}
}
function* getProductionItemsByPartId({ payload: { partId } }) {
	try {
		const url = `${GET_PRODUCTION_ITEMS_BY_PART_ID_ENDPOINT}/${partId}`
		const response = yield call(get, url)
		yield put(getProductionItemsByPartIdSuccess(response))
	} catch (error) {
		yield put(getProductionItemsByPartIdFail(error))
	}
}
function* getSupplierShipmentPricingList() {
	try {
		const url = GET_SUPPLIER_SHIPMENT_PRICING_LIST_ENDPOINT
		const response = yield call(get, url)
		yield put(getSupplierShipmentPricingListSuccess(response))
	} catch (error) {
		yield put(getSupplierShipmentPricingListFail(error))
	}
}
function* launchProduction({ payload: { data } }) {
	try {
		const url = LAUNCH_PRODUCTION_ENDPOINT
		const response = yield call(post, url, { data })
		yield put(launchProductionSuccess(response))
	} catch (error) {
		yield put(launchProductionFail(error))
	}
}

function* getProductionChat({ payload: { orderId, orderFragmentId } }) {
	try {
		const url = `${GET_PRODUCTION_CHAT_ENDPOINT}/${orderId}/${orderFragmentId}`
		const response = yield call(get, url)
		yield put(getProductionChatSuccess(response))
	} catch (error) {
		yield put(getProductionChatFail(error))
	}
}

function* postProductionChat({ payload: data }) {
	try {
		const url = POST_PRODUCITON_CHAT_ENDPOINT
		const response = yield call(post, url, data)
		yield put(postProductionChatSuccess(response))
	} catch (error) {
		yield put(postProductionChatFail(error))
	}
}

function* getProductionItemsLabels({ payload: { orderId, orderFragmentId } }) {
	try {
		const url = GET_PRODUCTION_ITEMS_LABELS_ENDPOINT
		const response = yield call(get, `${url}/${orderId}/${orderFragmentId}`)
		yield put(getProductionItemsLabelsSuccess(response))
	} catch (error) {
		yield put(getProductionItemsLabelsFail(error))
	}
}

function* updateProduction({ payload: { production } }) {
	try {
		const url = `${UPDATE_PRODUCTION_ENDPOINT}`
		const response = yield call(putReq, url, { data: { production } })
		yield put(updateProductionSuccess(response))
	} catch (error) {
		yield put(updateProductionFail(error))
	}
}

function* completeProduction({ payload: { production, newShipmentDate } }) {
	try {
		const url = `${COMPLETE_PRODUCTION_ENDPOINT}`
		const response = yield call(post, url, { data: { production, newShipmentDate } })
		yield put(completeProductionSuccess(response))
	} catch (error) {
		yield put(completeProductionFail(error))
	}
}

function* getProductionItemsByOrderId({ payload: { orderId } }) {
	try {
		const url = `${GET_PRODUCTION_ITEMS_BY_ORDER_ID_ENDPOINT}/${orderId}`
		const response = yield call(get, url)
		yield put(getProductionItemsByOrderIdSuccess(response))
	} catch (error) {
		yield put(getProductionItemsByOrderIdFail(error))
	}
}

function* getProductionsPickedUpByCourier() {
	try {
		const url = GET_PRODUCTIONS_PICKED_UP_BY_COURIER_ENDPOINT
		const response = yield call(get, url)
		yield put(getProductionsPickedUpByCourierSuccess(response))
	} catch (error) {
		yield put(getProductionsPickedUpByCourierFail(error))
	}
}

function* getProductionsfromOrderId({ payload: { orderId } }) {
	try {
		const url = `${GET_PRODUCTIONS_LIST_BY_ORDER_ID_ENDPOINT}/${orderId}`
		const response = yield call(get, url)
		yield put(getProductionsListFromOrderIdSuccess(response))
	} catch (error) {
		yield put(getProductionsListFromOrderIdFail(error))
	}
}

function* rejectProduction({ payload: data }) {
	try {
		const url = `${REJECT_PRODUCTION_ENDPOINT}`
		const response = yield call(post, url, data)
		yield put(rejectProductionSuccess(response))
	} catch (error) {
		yield put(rejectProductionFail(error))
	}
}

function* getFilteredProductions({ payload: { data, signal } }) {
	try {
		const url = GET_FILTERED_PRODUCTIONS_ENDPOINT
		const response = yield call(ApiHelper.post, url, data, { signal })
		yield put(getFilteredProductionsSuccess(response))
	} catch (error) {
		yield put(getFilteredProductionsFail(error))
	}
}

function* getShipmentsImports({ payload: { data, signal } }) {
	try {
		const url = GET_SHIPMENTS_IMPORTS_ENDPOINT
		const response = yield call(post, url, data, { signal })
		yield put(getShipmentsImportsSuccess(response))
	} catch (error) {
		yield put(getShipmentsImportsFail(error))
	}
}

function* updateShipmentImport({ payload: { data } }) {
	try {
		const url = UPDATE_SHIPMENT_IMPORT_ENDPOINT
		const response = yield call(post, url, data)
		yield put(updateShipmentImportSuccess(response))
	} catch (error) {
		yield put(updateShipmentImportFail(error))
	}
}

function* handleCompleteProduction({ payload: { data } }) {
	try {
		const url = HANDLE_COMPLETE_PRODUCTION_ENDPOINT
		const response = yield call(post, url, data)
		yield put(handleCompleteProductionSuccess(response))
	} catch (error) {
		yield put(handleCompleteProductionFail(error))
	}
}

function* getPurchasesDashboard({ payload: { data } }) {
	try {
		const url = GET_PURCHASES_DASHBOARD_ENDPOINT
		const response = yield call(post, url, { data })
		yield put(getPurchasesDashboardSuccess(response))
	} catch (error) {
		yield put(getPurchasesDashboardFail(error))
	}
}

function* getProductionsByTracking({ payload: { data } }) {
	try {
		const url = GET_PRODUCTIONS_BY_TRACKING_ENDPOINT
		const response = yield call(post, url, { data })
		yield put(getProductionsByTrackingSuccess(response))
	} catch (error) {
		yield put(getProductionsByTrackingFail(error))
	}


}
function* productionsSaga() {
	yield takeEvery(GET_PRODUCTION_DETAIL, getProductionDetail)
	yield takeEvery(GET_PRODUCTION_LIST_BY_SUPPLIER, getProductionListBySupplier)
	yield takeEvery(GET_PRODUCTION_ITEMS_BY_PART_ID, getProductionItemsByPartId)
	yield takeEvery(GET_PRODUCTION_LIST, getProductionList)
	yield takeEvery(GET_PRODUCTION_ITEMS_LABELS, getProductionItemsLabels)
	yield takeEvery(GET_SUPPLIER_SHIPMENT_PRICING_LIST, getSupplierShipmentPricingList)
	yield takeEvery(LAUNCH_PRODUCTION, launchProduction)
	yield takeEvery(GET_PRODUCTION_CHAT, getProductionChat)
	yield takeEvery(POST_PRODUCITON_CHAT, postProductionChat)
	yield takeEvery(UPDATE_PRODUCTION, updateProduction)
	yield takeEvery(COMPLETE_PRODUCTION, completeProduction)
	yield takeEvery(GET_PRODUCTION_ITEMS_BY_ORDER_ID, getProductionItemsByOrderId)
	yield takeEvery(GET_PRODUCTIONS_PICKED_UP_BY_COURIER, getProductionsPickedUpByCourier)
	yield takeEvery(GET_PRODUCTIONS_LIST_FROM_ORDER_ID, getProductionsfromOrderId)
	yield takeEvery(REJECT_PRODUCTION, rejectProduction)
	yield takeEvery(GET_FILTERED_PRODUCTIONS, getFilteredProductions)
	yield takeEvery(GET_SHIPMENTS_IMPORTS, getShipmentsImports)
	yield takeEvery(UPDATE_SHIPMENT_IMPORT, updateShipmentImport)
	yield takeEvery(HANDLE_COMPLETE_PRODUCTION, handleCompleteProduction)
	yield takeEvery(GET_PURCHASES_DASHBOARD, getPurchasesDashboard)
	yield takeEvery(GET_PRODUCTIONS_BY_TRACKING, getProductionsByTracking)
}

export default productionsSaga
