import { footerString, footerWebsite } from 'constants/footer'
import PropTypes from 'prop-types'
import { useState } from 'react'
export const PRINT_BUTTON_ID_PREFIX = 'printButton'
export const DEFAULT_LABEL_SIZE = {
	x: 72.6,
	y: 51,
}

export const DEFAULT_BARCODE_FORMAT = 'CODE128'

/**
 * 
 * @param id required
 * @param width Width page size  
 * @param height Height page size
 * @param orientation Orientation. "l" = landscape. "p" = portrait
 * @param isHidingContent use it to hide content on webBrowser
 * @returns 
 */

export const PrintableContent = ({
	id,
	filename,
	width,
	height,
	orientation,
	isHidingContent,
	children,
	buttonClassName,
	buttonContent,
	printPageCounter,
	printDocumentFooter,
	isPdfReadyToBeSaved,
}) => {
	const html2pdf = require('html2pdf.js')

	const options = {
		jsPDF: {
			format: [width, height],
			unit: 'mm',
			orientation,
			hotfixes: ['px_scaling'],
		},
		margin: 0,
		html2canvas: { scale: 8 },
		filename: filename,
	}

	const [loading, setLoading] = useState(false)

	const downloadPdf = () => {
		setLoading(true)
		const element = document.getElementById(id)
		const pages = element.getElementsByClassName('page')
		html2pdf().from('').set(options).toPdf().get('pdf').then(async (pdf) => {

			for (const page of pages) {
				pdf.addPage()
				pdf.addImage(
					await html2pdf().from(page).set(options).toImg().get('img').then((img) => img),
					'JPEG',
					0,
					2,
					width,
					height - 4,
				)
			}

			pdf.deletePage(1)
			if (printPageCounter)
				setPageNumberOnDocument(pdf)
			if (printDocumentFooter) {
				setDocumentFooter(footerString, footerWebsite, pdf)
			}
			setLoading(false)
		}).save()
	}

	const setPageNumberOnDocument = (pdf) => {

		const x = pdf.internal.pageSize.getWidth() - 10
		const y = pdf.internal.pageSize.getHeight() - 8
		const totalPages = pdf.internal.getNumberOfPages()
		for (let i = 1; i <= totalPages; i++) {
			const str = `${i} / ${totalPages}`
			const options = {
				align: 'right',
			}

			pdf.setPage(i)
			pdf.setFontSize(8).setFont('helvetica')
			pdf.text(str, x, y, options)
		}
	}

	const setDocumentFooter = (str, website, pdf) => {
		setFooterLabel(str, pdf)
		setFooterWebsite(website, pdf)
	}

	const setFooterLabel = (str, pdf) => {
		const yPosition = pdf.internal.pageSize.getHeight() - 20
		const totalPages = pdf.internal.getNumberOfPages()

		const x = pdf.internal.pageSize.getWidth() / 2
		const y = yPosition
		const options = {
			align: 'center',
			maxWidth: 130,

		}

		pdf.setPage(totalPages)
		pdf.setFontSize(8).setFont('helvetica')
		pdf.text(str, x, y, options)

	}
	const setFooterWebsite = (str, pdf) => {
		const yPosition = pdf.internal.pageSize.getHeight() - 20
		const totalPages = pdf.internal.getNumberOfPages()

		const x = pdf.internal.pageSize.getWidth() / 2
		const y = yPosition + 10
		const options = {
			align: 'center',
		}

		pdf.setPage(totalPages)
		pdf.setFontSize(12).setFont('helvetica', 'bold')
		pdf.text(str, x, y, options)

	}

	return (
		<>
			<button
				id={`${PRINT_BUTTON_ID_PREFIX}_${id}`}
				className={buttonClassName}
				disabled={loading}
				onClick={(e) => {
					e.preventDefault()
					downloadPdf()
				}}
			>
				{buttonContent}
			</button>
			<div className={isHidingContent ? 'd-none' : ''}>
				<div id={id}>
					{children}
				</div>
			</div>
		</>
	)
}

PrintableContent.propTypes = {
	id: PropTypes.string.required,
	filename: PropTypes.string.required,
	width: PropTypes.number,
	height: PropTypes.number,
	orientation: PropTypes.string,
	children: PropTypes.any,
	buttonClassName: PropTypes.string,
	buttonContent: PropTypes.element,
	isHidingContent: PropTypes.bool,
	isHidingButton: PropTypes.bool,
	printPageCounter: PropTypes.bool,
	printDocumentFooter: PropTypes.bool,
	isPdfReadyToBeSaved: PropTypes.bool,
}

export default PrintableContent
