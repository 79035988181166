import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getOrderDetailById, getProductionItemsByOrderId, getProductionsListFromOrderId } from 'store/actions'
import barcodeScanner from '../../../assets/images/logistic/barcodeScanner.png'
import Loader from '../../../components/Common/Loader'
import SelectedOrder from './SelectedOrder'

const OrderInfo = ({
	t,
	getProductionsListFromOrderId,
	selectedOrderId,
	setIsOpenAssignBoxModal,
	isLoadingOrders,
	getProductionItemsByOrderId,
	productionItemsByOrderId,
	isLoadingProductions,
	scannedOrderList,
	partConfigOptions,
}) => {
	const [orderInfo, setOrderInfo] = useState({})
	const productionItems = useMemo(() => productionItemsByOrderId, [productionItemsByOrderId])

	useEffect(() => {
		if (Object.keys(scannedOrderList).length > 0) {
			const selectedScannedOrder = scannedOrderList?.find((order) => order?.id == selectedOrderId?.orderId)
			if (!selectedScannedOrder) return
			setOrderInfo(selectedScannedOrder)
			if (productionItems[0]?.orderId != selectedOrderId?.orderId) {
				getProductionItemsByOrderId({ orderId: selectedOrderId?.orderId })
				getProductionsListFromOrderId(selectedOrderId?.orderId)
			}
		}
	}, [selectedOrderId, scannedOrderList])

	return (
		<>
			{!selectedOrderId ? (
				<div
					className="d-flex flex-column align-content-center text-center bg-transparent"
					style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}
				>
					<h3 className="mt-4">{t('scanPiece', { ns: 'naming' })}</h3>
					<img src={barcodeScanner}
						alt="barcodeScanner"
						style={{ height: '500px', width: '100%', objectFit: 'contain', background: 'transparent' }}
					/>
				</div>
			) : (
				<>
					{isLoadingOrders || isLoadingProductions ? (
						<div className="d-flex justify-content-center">
							<Loader className="fs-1 p-4" />
						</div>

					) : (
						<SelectedOrder
							orderInfo={orderInfo}
							productionItems={productionItems}
							setIsOpenAssignBoxModal={setIsOpenAssignBoxModal}
							partConfigOptions={partConfigOptions}
						/>
					)
					}
				</>
			)}

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		orderDetail: state.Orders.orderDetail,
		isLoadingOrders: state.Orders.isLoading,
		productionsListByOrderId: state.Productions.productionsListByOrderId,
		isLoadingProductions: state.Productions.isLoading,
		productionItemsByOrderId: state.Productions.productionItemsByOrderId,
		scannedOrderList: state.Orders.scannedOrderList,
		isLoading: state.Orders.isLoading,
		partConfigOptions: state.Parts.partConfigOptions,
	}
}

OrderInfo.propTypes = {
	t: PropTypes.func,
	selectedOrderId: PropTypes.object,
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	isLoadingOrders: PropTypes.bool,
	getProductionsListFromOrderId: PropTypes.func,
	productionsListByOrderId: PropTypes.array,
	isLoadingProductions: PropTypes.bool,
	productionItemsByOrderId: PropTypes.array,
	getProductionItemsByOrderId: PropTypes.func,
	scannedOrderList: PropTypes.array,
	setIsOpenAssignBoxModal: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

export default withGetPartConfigOptions(connect(mapStateToProps, { getOrderDetailById, getProductionItemsByOrderId, getProductionsListFromOrderId })(
	withTranslation()(OrderInfo),
))
