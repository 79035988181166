import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { setSelectedOrderId } from 'store/actions'
import Loader from '../../../components/Common/Loader'
import ReceptionOrderSummary from './ReceptionOrderSummary'

const OrderSummaryList = ({ t, title, orders, isProductions, isLoading, setSelectedOrderId }) => {
	const [ordersMapped, setOrdersMapped] = useState([])

	const mapProduction = (item) => {
		return {
			id: item.production.displayId,
			organizationName: item.organizationName,
			shipmentDate: item.production.shipmentDateEstimate,
			supplier: item.supplier.name,
			arrivalDate: item.production.arrivalDate,
			trackingNumber: item.production.shipmentTrackingNumber,
			supplierId: item.supplier.id,
		}
	}

	useEffect(() => {
		if (orders) {
			setOrdersMapped(
				orders.map((order) => {
					let orderMapped
					if (isProductions) {
						orderMapped = mapProduction(order)
					} else {
						orderMapped = order
					}
					return (
						<div key={order.id} onClick={() => { if (!isProductions) setSelectedOrderId(order.id) }}>
							<ReceptionOrderSummary order={orderMapped} isProductions={isProductions} />
						</div>
					)
				}),
			)
		}
	}, [orders])

	return (
		<div>
			<h3>{title}</h3>
			<div className="border-bottom">
				{!isLoading ? (
					<SimpleBar style={{
						maxHeight: '22.5rem',
						border: '0.5px solid lightgray',
						borderTopLeftRadius: '4px',
						borderTopRightRadius: '4px',
					}}>{ordersMapped}</SimpleBar>
				) :
					<div className="d-flex justify-content-center">
						<Loader className="fs-1 p-4" />
					</div>
				}
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		isLoading: state.Orders.isLoading
	}
}

OrderSummaryList.propTypes = {
	title: PropTypes.string,
	t: PropTypes.func,
	orders: PropTypes.array,
	isProductions: PropTypes.bool,
	isLoading: PropTypes.bool,
	setSelectedOrderId: PropTypes.func,
}
export default connect(mapStateToProps, { setSelectedOrderId })(
	withTranslation()(OrderSummaryList),
)
