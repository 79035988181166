import { getDate } from 'common/Utils/StringUtilities'
import { PartName } from 'components/Common/PartName'
import RemainingDays from 'components/Common/RemainingDays'
import { Couriers } from 'constants/couriers'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const ReceptionOrderSummary = ({ order, t, isProductions, selectedOrderId }) => {
	const {
		id,
		organizationName,
		references,
		totalPieces,
		completed,
		shipmentDate,
		box,
		supplier,
		arrivalDate,
		trackingNumber,
		supplierId,
		receivingCountry,
		disconformity,
	} = order

	const defaultPClassName = 'm-0 p-0 me-1 '
	const flexRowClassName = 'd-flex flex-row '
	const flexColumnClassName = 'd-flex flex-column '

	const getStatusText = (completed, isProductions) => {
		if (isProductions) return t('pending', { ns: 'naming' })
		return completed ? t('complete', { ns: 'naming' }) : t('parts_pending', { ns: 'naming' })
	}

	const getCompletedClassName = (completed, isProductions) => {
		if (isProductions) return 'bx bxs-circle text-warning margin-top-017rem ms-1'
		return completed ? 'bx bxs-circle text-success margin-top-017rem ms-1' : 'bx bxs-circle text-danger margin-top-017rem ms-1'
	}

	return (
		<div className="d-flex justify-content-between border p-2 pb-3 cursor-pointer" style={{ backgroundColor: selectedOrderId?.orderId == id ? '#E3F2FD' : '' }}>
			<div className={flexColumnClassName}>

				<strong> <PartName name={organizationName} /></strong>

				<div className="d-flex flex-row">
					<p className={defaultPClassName + 'me-1'}>ID: </p>
					<strong>{id}</strong>
				</div>

				{!isProductions && (
					<div className={flexRowClassName}>
						<p className={defaultPClassName}>{references}</p>
						<p className={defaultPClassName}>{t('Referencias', { ns: 'naming' })}</p>
						<p className={defaultPClassName}> {totalPieces}</p>
						<p className={defaultPClassName}>{t('parts', { ns: 'naming' })}</p>
					</div>
				)}

				{isProductions && arrivalDate && (
					<div className={flexRowClassName}>
						<p className={defaultPClassName}>{t('arrival', { ns: 'naming' }) + ':'}</p>
						<p className={defaultPClassName}>{getDate(arrivalDate)}</p>
					</div>
				)}

				<div className={flexRowClassName}>
					<p className={defaultPClassName}>{t('shipment', { ns: 'naming' }) + ':'}</p>
					<p className={defaultPClassName}>{getDate(shipmentDate)}</p>
				</div>
				<p className={defaultPClassName}>{<RemainingDays date={shipmentDate} finalDate={arrivalDate} />}</p>
				<div className="mt-2">
					{disconformity && (
						<span className="bg-red rounded p-1 px-2 text-white">
							<strong>DISCONFORMITY</strong>
						</span>
					)}
				</div>

			</div>
			<div>
				<div className={flexColumnClassName}>

					<span className={flexRowClassName + 'justify-content-end'}>
						{getStatusText(completed, isProductions)}
						<i className={getCompletedClassName(completed, isProductions)}></i>
					</span>

					{isProductions && (

						<Tracking trackingId={trackingNumber} courier={Couriers.DHL} />
					)}

					{isProductions && (

						<span className={'justify-content-end ' + flexRowClassName}>
							<strong className="text-nowrap">{t('supplier', { ns: 'naming' })}</strong>
							<p className={defaultPClassName + 'ms-1 text-blue'}>{<PartName name={supplier} maxLenght={15} />}</p>
						</span>

					)}

					{!isProductions && <strong className="text-end">{box || t('no_box', { ns: 'naming' })}</strong>}
					<div className="d-flex justify-content-end">
						{receivingCountry !== 'ES' && !isProductions && (
							<span className="bg-yellow rounded p-1 px-2 text-white text-end">
								<strong>UPS</strong>
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		selectedOrderId: state.Orders.selectedOrderId,
	}
}

ReceptionOrderSummary.propTypes = {
	order: PropTypes.object,
	t: PropTypes.func,
	isProductions: PropTypes.bool,
	selectedOrderId: PropTypes.any,
}

export default connect(mapStateToProps)(
	withTranslation()(ReceptionOrderSummary),
)
