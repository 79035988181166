import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import RealShipmentDate from 'pages/Orders/RealShipmentDate'
import ProductionStatusComponent from 'pages/SupplierProduction/ProductionStatus'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Table from '../../../components/Table/Table'
import {
	getBoxList,
	getProductionItemsLabels,
	getSuppliers,
	resetOrderBoxCode,
	resetScannedPiecesFromAllPieces,
	resetScannedPiecesFromPiece,
	setOrderBoxCode,
	updateBoxesByRef,

	updateProductionItemReceivedQuantity,
} from '../../../store/actions'
import LabelsModal from '../LabelsModal'

const SelectedOrder = ({
	i18n: { language },
	orderInfo,
	productionItems,
	getSuppliers,
	suppliers,
	getProductionItemsLabels,
	productionLabels,
	resetScannedPiecesFromPiece,
	selectedOrderId,
	resetOrderBoxCode,
	resetScannedPiecesFromAllPieces,
	boxList,
	getBoxList,
	getBoxListStatus = {},
	lastScannedPart,
	updateBoxesByRef,
	updateProductionItemReceivedQuantity,
	setIsOpenAssignBoxModal,
	partConfigOptions,
}) => {
	const { t } = useTranslation()
	const ELEMENTS_TABLE_HEADER = {
		item: t('item', { ns: 'naming' }),
		image: t('image', { ns: 'naming' }),
		id: 'Id',
		name: t('name', { ns: 'naming' }),
		specifications: t('specifications', { ns: 'naming' }),
		pieces: t('parts', { ns: 'naming' }),
		actions: '',
	}
	const PRODUCTIONS_TABLE_HEADER = {
		id: 'Id',
		provider: t('provider', { ns: 'naming' }),
		status: t('status', { ns: 'naming' }),
		sentAndRequired: t('sent/required', { ns: 'naming' }),
		courier: t('courier', { ns: 'naming' }),
		date: t('date', { ns: 'naming' }),
	}
	const [parsedOrderInfo, setParsedOrderInfo] = useState([])
	const [parsedProductionInfo, setParsedProductionInfo] = useState([])
	const [totalPieces, setTotalPieces] = useState(0)
	const [completedRows, setCompletedRows] = useState([])
	const [printablePartId, setPrintablePartId] = useState(undefined)

	const buttonRef = useRef(null)

	setTimeout(() => {
		const miElemento = document.getElementById('tablaOrderInfo')
		if (miElemento) {
			const rows = miElemento.getElementsByTagName('tr')
			if (rows) {
				const rowsArray = Array.from(rows)
				rowsArray.shift()
				rowsArray.forEach((row) => {
					const tds = row.getElementsByTagName('td')
					if (tds) {
						const tdArray = Array.from(tds)
						const tdPartId = tdArray[2]?.innerText
						if (tdPartId == lastScannedPart?.partId) {
							tdArray.forEach((td) => {
								td.style.backgroundColor = 'lightblue'
							})
							tdArray[2].scrollIntoView({ behavior: 'smooth', block: 'center' })
						} else {
							tdArray.forEach(td => {
								td.style.backgroundColor = 'white'
							})
						}
					}
				})
			}
		}
	}, 0)

	const resetAllScannedPieces = () => {
		updateProductionItemReceivedQuantity({
			data: {
				orderId: orderInfo.id,
			},
		})
		setCompletedRows([])
	}

	const resetScannedPiece = (partId) => {
		updateProductionItemReceivedQuantity({
			data: {
				orderId: orderInfo.id,
				partId: partId,
				quantity: -(orderInfo?.orderItems?.find((item) => partId == item.item.part.id)?.receivedQuantity),
			},
		})
		setCompletedRows((prev) => prev.filter((row) => row != partId))
	}

	function handleUnassignOrderBoxes() {
		const boxes = orderInfo?.boxCode?.split(',')
		if (boxes) {
			updateBoxesByRef({
				data: {
					boxes: boxes.map((box) => ({
						ref: box.trim(),
						orderId: null,
					})),
				},
			})
		}
	}

	const getSentAndRequiredValues = (fragmentId, productionItems) => {
		let required = 0
		let sent = 0
		productionItems?.forEach((item) => {
			if (item.orderFragmentId == fragmentId) {
				required += item.totalQuantity
				sent += item.shippedQuantity
			}
		})
		return sent + '/' + required
	}

	const parseOrder = (orderItems, orderDetails) => {
		const parsedElements = []
		orderItems?.forEach((element, index) => {
			const parsedElement = {
				item: (index + 1).toString() + '.',
				image: <img src={element.item.part.imageUrl} alt="PartImg" height={50} width={50} />,
				id: element.item.part.id,
				name: element.item.part.modelFiles[0]?.name,
				specifications: PartConfigTranslations.getMaterialName({ part: element.item.part, partConfigOptions, language }) + ' ' +
					PartConfigTranslations.getFinishName({ part: element.item.part, partConfigOptions, language }),
				pieces: element.receivedQuantity == element.item.quantity ?
					<div className="d-flex align-items-center">
						<span style={{ textDecoration: 'line-through' }}>{element.receivedQuantity + ' ' + t('of', { ns: 'naming' }) + ' ' + element.item.quantity + ' ' + t('pieces', { ns: 'naming' })}</span>
						<i className="mdi mdi-check-circle ms-2" style={{ color: 'green' }} />
					</div>
					: element.receivedQuantity >= element.item.quantity ?
						<div className="d-flex align-items-center">
							<span>{element.receivedQuantity + ' ' + t('of', { ns: 'naming' }) + ' ' + element.item.quantity + ' ' + t('pieces', { ns: 'naming' })}</span>
							<i className="bx bxs-error text-danger ms-2" />
						</div>
						:
						<div>{element.receivedQuantity + ' ' + t('of', { ns: 'naming' }) + ' ' + element.item.quantity + ' ' + t('pieces', { ns: 'naming' })}</div>,
				actions:
					<>
						<button
							onClick={() => { setPrintablePartId(element.item.part.id) }}
							style={{ backgroundColor: 'transparent', border: '1px solid black', borderRadius: '4px', color: 'black', width: '30px', height: '30px' }}
							className="btn btn-sm align-self-center mt-2 me-2"
						>
							<i className="mdi mdi-printer" />
						</button>
						<button
							style={{ backgroundColor: 'transparent', border: '1px solid black', borderRadius: '4px', color: 'black', width: '30px', height: '30px' }}
							className="btn btn-sm align-self-center mt-2"
							onClick={() => { resetScannedPiecesFromPiece(element.item.part.id, orderDetails.id); resetScannedPiece(element.item.part.id) }}
						>
							<i className="mdi mdi-refresh" />
						</button>
					</>,
			}
			parsedElements.push(parsedElement)
			if (element.receivedQuantity >= element.item.quantity) {
				setCompletedRows((prev) => [...prev, element.item.part.id])
			}
			else {
				setCompletedRows((prev) => prev.filter((row) => row != element.item.part.id))
			}
		})
		setParsedOrderInfo(parsedElements)
	}

	const parseProductions = (productions) => {
		const parsedProductions = []
		productions.forEach((production) => {
			const parsedProduction = {
				id: production.displayId,
				provider: suppliers?.find(supplier => supplier.id === production.supplierId)?.personalInformation?.firstName,
				status: (
					<ProductionStatusComponent
						status={production.statusId}
						disconformity={production.disconformity}
						partialShipment={production.partialShipment}
					/>
				),
				sentAndRequired: getSentAndRequiredValues(production.orderFragmentId, productionItems),
				courier: (<Tracking trackingId={production?.shipmentTrackingNumber} courier={production?.courier} estimatedArrivalDate={production?.arrivalDateEstimated} status={production?.statusId} />),
				date: (
					<RealShipmentDate
						date={production.shipmentDateEstimate}
						realDate={production.shipmentDateReal}
						delayDate={production.shipmentDateDelay}
					/>
				),
			}
			parsedProductions.push(parsedProduction)
		})
		setParsedProductionInfo(parsedProductions)
	}

	const getTotalPieces = (items) => {
		let total = 0
		items?.forEach((item) => {
			total += item.item.quantity
		})
		setTotalPieces(total)
	}

	useEffect(() => {
		if (Object.keys(suppliers).length == 0) {
			getSuppliers()
		}
		if (orderInfo) {
			parseOrder(orderInfo?.orderItems, orderInfo)
			getTotalPieces(orderInfo?.orderItems)
		}
	}, [orderInfo])

	useEffect(() => {
		if (Object.keys(suppliers).length !== 0 && Object.keys(orderInfo).length !== 0) {
			parseProductions(orderInfo?.productions)
		}
	}, [suppliers, orderInfo])

	useEffect(() => {
		if (!getBoxListStatus?.isLoading && (boxList == null || boxList.length == 0)) {
			getBoxList()
		}
	}, [])

	return (
		<>
			<div>
				<div
					className="w-100 p-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
				>
					<h5 className="pt-2">{t('activeOrder', { ns: 'naming' })} #{orderInfo?.id} | {orderInfo?.billingInfo?.companyName}</h5>
				</div>
				<div className="w-100 p-2 d-flex align-items-center" style={{ border: '0.5px solid lightgray' }}>
					<div className="col-8 d-flex d-row">
						<h4 className="pt-2">
							<i className="bx bx-box me-2" />
							{t('assignedBoxes', { ns: 'naming' })}: <strong>{orderInfo?.boxCode || t('noBoxes', { ns: 'naming' })} </strong>
						</h4>
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: '30px', height: '30px' }}
							className="ms-2 align-self-center d-flex justify-content-center align-items-center"
							onClick={() => { resetOrderBoxCode(selectedOrderId.orderId); handleUnassignOrderBoxes() }}
						>
							<i className="mdi mdi-refresh" />
						</button>
					</div>
					<div className="col-4 d-flex justify-content-end">
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: 'fit-content' }}
							className="p-2 d-flex justify-content-center align-items-center"
						>
							<p className="m-0" onClick={() => setIsOpenAssignBoxModal(true)}>
								{t('addExtraBox', { ns: 'naming' })}<i className="mdi mdi-arrow-right ms-2" />
							</p>
						</button>
					</div>
				</div>
				<div className="w-100 p-2 d-flex align-items-center" style={{ border: '0.5px solid lightgray', height: '100%' }}>
					<div className="col-8">
						<p className="pt-3">{t('orderElements', { ns: 'naming' })}</p>
					</div>
					<div className="col-4 d-flex d-row justify-content-end">
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: '30px', height: '30px' }}
							className="ms-2 align-self-center d-flex justify-content-center align-items-center"
							onClick={() => { setPrintablePartId(null) }}
						>
							<i className="bx bxs-printer" />
						</button>
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: '30px', height: '30px' }}
							className="ms-2 align-self-center d-flex justify-content-center align-items-center"
							onClick={() => { resetAllScannedPieces(); resetScannedPiecesFromAllPieces(selectedOrderId.orderId) }}
						>
							<i className="mdi mdi-refresh" />
						</button>

					</div>
				</div>
				<div className="w-100" style={{ border: '0.5px solid lightgray', height: '100%' }}>
					{Object.keys(parsedOrderInfo).length !== 0 &&
						<>
							<div id="tablaOrderInfo">
								<Table
									header={ELEMENTS_TABLE_HEADER}
									items={parsedOrderInfo}
									language={getLanguage()}
									showStatusFilter={false}
									disableInteractions={true}
									selectRow={{ hideSelectColumn: true }}
									disableStriped
									paginationProps={{
										custom: true,
										sizePerPage: 200,
									}}
									highlightedRows={completedRows}
									highlightStyle="green-logistic-bg-color"
								/>
							</div>
						</>
					}
				</div>
				<div
					className="w-100 ps-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					<p className="pt-3">{t('thisOrdersContains', { ns: 'naming' })} {orderInfo?.orderItems?.length} {t('referencesFromATotalOf', { ns: 'naming' })} {totalPieces} {t('pieces', { ns: 'naming' })}</p>
				</div>
			</div>

			<div>
				<div
					className="w-100 p-2 mt-4 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
				>
					<h5 className="pt-2">{t('productionsFromOrder', { ns: 'naming' })}</h5>
				</div>
				<div
					className="w-100"
					style={{ border: '0.5px solid lightgray', height: '100%', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					{Object.keys(parsedProductionInfo).length !== 0 && Object.keys(suppliers).length !== 0 &&
						<Table
							header={PRODUCTIONS_TABLE_HEADER}
							items={parsedProductionInfo}
							language={getLanguage()}
							showStatusFilter={false}
							disableInteractions={true}
							selectRow={{ hideSelectColumn: true }}
							paginationProps={{
								custom: true,
								sizePerPage: 200,
							}}
						/>
					}
					<LabelsModal partId={printablePartId} closeModal={() => { setPrintablePartId(undefined) }} />
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		suppliers: state.Users.suppliers,
		productionLabels: state.Productions.productionLabels,
		selectedOrderId: state.Orders.selectedOrderId,
		boxList: state.Orders.boxList,
		getBoxListStatus: state.Orders.getBoxListStatus,
		lastScannedPart: state.Orders.lastScannedPart,
		partConfigOptions: state.Parts.partConfigOptions,
	}
}

SelectedOrder.propTypes = {
	orderInfo: PropTypes.object,
	getSuppliers: PropTypes.func,
	suppliers: PropTypes.array,
	getProductionItemsLabels: PropTypes.func,
	productionLabels: PropTypes.array,
	productionItems: PropTypes.array,
	resetScannedPiecesFromPiece: PropTypes.func,
	setOrderBoxCode: PropTypes.func,
	selectedOrderId: PropTypes.any,
	resetOrderBoxCode: PropTypes.func,
	resetScannedPiecesFromAllPieces: PropTypes.func,
	boxList: PropTypes.array,
	getBoxList: PropTypes.func,
	getBoxListStatus: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
	lastScannedPart: PropTypes.number,
	updateBoxesByRef: PropTypes.func,
	updateProductionItemReceivedQuantity: PropTypes.func,
	handleOnChangeAsignBoxModal: PropTypes.func,
	selectedOrderBoxList: PropTypes.array,
	setIsOpenAssignBoxModal: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

export default connect(mapStateToProps,
	{
		getSuppliers,
		getProductionItemsLabels,
		resetScannedPiecesFromPiece,
		setOrderBoxCode,
		resetOrderBoxCode,
		resetScannedPiecesFromAllPieces,
		getBoxList,
		updateBoxesByRef,
		updateProductionItemReceivedQuantity,
	})(withTranslation()(SelectedOrder))
