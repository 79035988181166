import { downloadXLSX } from 'common/Utils/downloadUtils/downloadXlsx'
import { Table2 } from 'components/Table/Table2'
import { useEffect, useMemo, useState } from 'react'

export const useTable = (props) => {

	const {
		t,
		language,
		headers,
		headerProps,
		rows,
		rowsExport,
		exportFileName,
		showExportButton,
		totalCount,
		initialPage,
		initialPageSize,
		extraRefreshDependencies = [],
	} = props

	const [page, setPage] = useState(initialPage)
	const [pageSize, setPageSize] = useState(initialPageSize)

	useEffect(() => {
		if (totalCount) {
			const maxPage = pageSize ? Math.ceil(totalCount / pageSize) : 1
			if (page > maxPage) {
				setPage(maxPage)
				return
			}
		}
	}, [totalCount])

	const TableElement = useMemo(() => (
		<Table2
			t={t}
			headers={headers}
			headerProps={headerProps}
			rows={rows}
			rowsExport={rowsExport}
			exportFileName={exportFileName}
			showExportButton={showExportButton}
			currentPage={page}
			currentPageSize={pageSize}
			setPage={setPage}
			setPageSize={setPageSize}
			totalCount={totalCount}
		/>
	), [
		t,
		language,
		headers,
		headerProps,
		rows,
		rowsExport,
		showExportButton,
		totalCount,
		page,
		pageSize,
		exportFileName,
		...extraRefreshDependencies,
	])

	const downloadRowsAsExcel = () => downloadXLSX({
		rows: [headers, ...rowsExport],
		fileName: exportFileName,
	})

	return {
		TableElement,
		page,
		setPage,
		pageSize,
		setPageSize,
		downloadRowsAsExcel,
	}

}
