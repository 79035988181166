import PropTypes from 'prop-types'
import Select from 'react-select'
export const OptionsInput = ({
	isMulti,
	value,
	setValue,
	title,
	options,
	hasPressed,
	isRequired,
	initialValue,
	objectElement,
	automaticMappedValue,
	className,
	disabled,
	filterOption,
}) => {

	const mapOptions = options => {
		if (!options || options.length == 0) return []
		if (options[0].value && options[0].label) return options
		return options.map(option => {
			return {
				value: option,
				label: option,
			}
		})
	}

	const mappedOptions = mapOptions(options)
	const mappedValue = automaticMappedValue && isMulti ? value?.map(arrayValue => mappedOptions.find(element => element.value === arrayValue)) : mappedOptions.find(element => element.value === value)
	const mappedInitialValue = automaticMappedValue && isMulti ? initialValue?.map(arrayValue => mappedOptions.find(element => element.value === arrayValue)) : mappedOptions.find(element => element.value === initialValue)

	return (
		<div className={`m-2 ${className}`}>
			<h6>
				{isRequired &&
					<span className="text-primary me-1 mb-4">✲</span>
				}
				{title}
			</h6>
			<Select
				value={automaticMappedValue ? mappedValue : value}
				defaultValue={automaticMappedValue ? mappedInitialValue : initialValue}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				options={mappedOptions}

				filterOption={filterOption}
				className="basic-multi-select"
				classNamePrefix="select"
				isDisabled={disabled}
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						borderColor: ((isRequired && hasPressed) && (value == null || value.length === 0)) ? '#F46A6A' : baseStyles.borderColor,
					}),
				}}
				onChange={e =>
					isMulti ?
						objectElement ? setValue(objectElement, e.map(x => x.value)) : setValue(e) :
						objectElement
							? setValue(objectElement, e.value)
							: setValue(e.value)
				}
				onInputChange={(inputValue, { action }) => {
					if (action === 'input-change') {
						setValue(objectElement, inputValue)
					}
				}}
			/>
			{hasPressed && value == null && (
				<div className="invalid-feedback">Campo obligatorio</div>
			)}
		</div>
	)
}

OptionsInput.propTypes = {
	isMulti: PropTypes.bool,
	value: PropTypes.any,
	setValue: PropTypes.func,
	title: PropTypes.string,
	options: PropTypes.array,
	hasPressed: PropTypes.bool,
	isRequired: PropTypes.bool,
	initialValue: PropTypes.object,
	objectElement: PropTypes.string,
	automaticMappedValue: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	filterOption: PropTypes.func,
}
