export const GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID = 'GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID'
export const GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_SUCCESS = 'GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_SUCCESS'
export const GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_FAIL = 'GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_FAIL'
export const GET_PART_LIST_IMAGES = 'GET_PART_LIST_IMAGES'
export const GET_PART_LIST_IMAGES_SUCCESS = 'GET_PART_LIST_IMAGES_SUCCESS'
export const GET_PART_LIST_IMAGES_FAIL = 'GET_PART_LIST_IMAGES_FAIL'
export const UPLOAD_PART_IMAGE = 'UPLOAD_PART_IMAGE'
export const UPLOAD_PART_IMAGE_SUCCESS = 'UPLOAD_PART_IMAGE_SUCCESS'
export const UPLOAD_PART_IMAGE_FAIL = 'UPLOAD_PART_IMAGE_FAIL'
export const UPLOAD_QUOTE_CHAT_IMAGE = 'UPLOAD_QUOTE_CHAT_IMAGE'
export const UPLOAD_QUOTE_CHAT_IMAGE_SUCCESS = 'UPLOAD_QUOTE_CHAT_IMAGE_SUCCESS'
export const UPLOAD_QUOTE_CHAT_IMAGE_FAIL = 'UPLOAD_QUOTE_CHAT_IMAGE_FAIL'
export const GET_QUOTE_CHAT_IMAGES = 'GET_QUOTE_CHAT_IMAGES'
export const GET_QUOTE_CHAT_IMAGES_SUCCESS = 'GET_QUOTE_CHAT_IMAGES_SUCCESS'
export const GET_QUOTE_CHAT_IMAGES_FAIL = 'GET_QUOTE_CHAT_IMAGES_FAIL'
export const GET_PART_LIST_BLUEPRINTS_AND_STP = 'GET_PART_LIST_BLUEPRINTS_AND_STP'
export const GET_PART_LIST_BLUEPRINTS_AND_STP_SUCCESS = 'GET_PART_LIST_BLUEPRINTS_AND_STP_SUCCESS'
export const GET_PART_LIST_BLUEPRINTS_AND_STP_FAIL = 'GET_PART_LIST_BLUEPRINTS_AND_STP_FAIL'
export const GET_PARTS_FILES = 'GET_PARTS_FILES'
export const GET_PARTS_FILES_SUCCESS = 'GET_PARTS_FILES_SUCCESS'
export const GET_PARTS_FILES_FAIL = 'GET_PARTS_FILES_FAIL'
export const GET_PRODUCTION_CHAT_IMAGES = 'GET_PRODUCTION_CHAT_IMAGES'
export const GET_PRODUCTION_CHAT_IMAGES_SUCCESS = 'GET_PRODUCTION_CHAT_IMAGES_SUCCESS'
export const GET_PRODUCTION_CHAT_IMAGES_FAIL = 'GET_PRODUCTION_CHAT_IMAGES_FAIL'
export const UPLOAD_PRODUCTION_CHAT_IMAGE = 'UPLOAD_PRODUCTION_CHAT_IMAGE'
export const UPLOAD_PRODUCTION_CHAT_IMAGE_SUCCESS = 'UPLOAD_PRODUCTION_CHAT_IMAGE_SUCCESS'
export const UPLOAD_PRODUCTION_CHAT_IMAGE_FAIL = 'UPLOAD_PRODUCTION_CHAT_IMAGE_FAIL'
export const UPLOAD_COMPLETE_PRODUCTION_FILES = 'UPLOAD_COMPLETE_PRODUCTION_FILES'
export const UPLOAD_COMPLETE_PRODUCTION_FILES_SUCCESS = 'UPLOAD_COMPLETE_PRODUCTION_FILES_SUCCESS'
export const UPLOAD_COMPLETE_PRODUCTION_FILES_FAIL = 'UPLOAD_COMPLETE_PRODUCTION_FILES_FAIL'
export const GET_COMPLETE_PRODUCTION_FILES = 'GET_COMPLETE_PRODUCTION_FILES'
export const GET_COMPLETE_PRODUCTION_FILES_SUCCESS = 'GET_COMPLETE_PRODUCTION_FILES_SUCCESS'
export const GET_COMPLETE_PRODUCTION_FILES_FAIL = 'GET_COMPLETE_PRODUCTION_FILES_FAIL'
export const DELETE_MODIFIED_BLUEPRINTS = 'DELETE_MODIFIED_BLUEPRINTS'
export const DELETE_MODIFIED_BLUEPRINTS_SUCCESS = 'DELETE_MODIFIED_BLUEPRINTS_SUCCESS'
export const DELETE_MODIFIED_BLUEPRINTS_FAIL = 'DELETE_MODIFIED_BLUEPRINTS_FAIL'
export const UPLOAD_ORDER_SHIPMENT_FILES = 'UPLOAD_ORDER_SHIPMENT_FILES'
export const UPLOAD_ORDER_SHIPMENT_FILES_SUCCESS = 'UPLOAD_ORDER_SHIPMENT_FILES_SUCCESS'
export const UPLOAD_ORDER_SHIPMENT_FILES_FAIL = 'UPLOAD_ORDER_SHIPMENT_FILES_FAIL'
export const UPLOAD_QUALITY_VERIFICATION_IMAGES = 'UPLOAD_QUALITY_VERIFICATION_IMAGES'
export const UPLOAD_QUALITY_VERIFICATION_IMAGES_SUCCESS = 'UPLOAD_QUALITY_VERIFICATION_IMAGES_SUCCESS'
export const UPLOAD_QUALITY_VERIFICATION_IMAGES_FAIL = 'UPLOAD_QUALITY_VERIFICATION_IMAGES_FAIL'
export const DOWNLOAD_PART_LIST_IMAGES_IN_BASE64 = 'DOWNLOAD_PART_LIST_IMAGES_IN_BASE64'
export const DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_SUCCESS = 'DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_SUCCESS'
export const DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_FAIL = 'DOWNLOAD_PART_LIST_IMAGES_IN_BASE64_FAIL'
export const UPLOAD_ORDER_DELIVERY_NOTE = 'UPLOAD_ORDER_DELIVERY_NOTE'
export const UPLOAD_ORDER_DELIVERY_NOTE_SUCCESS = 'UPLOAD_ORDER_DELIVERY_NOTE_SUCCESS'
export const UPLOAD_ORDER_DELIVERY_NOTE_FAIL = 'UPLOAD_ORDER_DELIVERY_NOTE_FAIL'

export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAIL = 'UPLOAD_PROFILE_IMAGE_FAIL'


export const GET_ALL_ORDER_SHIPMENT_FILES = 'GET_ALL_ORDER_SHIPMENT_FILES'
export const GET_ALL_ORDER_SHIPMENT_FILES_SUCCESS = 'GET_ALL_ORDER_SHIPMENT_FILES_SUCCESS'
export const GET_ALL_ORDER_SHIPMENT_FILES_FAIL = 'GET_ALL_ORDER_SHIPMENT_FILES_FAIL'
export const GET_QUALITY_VERIFICATION_IMAGES = 'GET_QUALITY_VERIFICATION_IMAGES'
export const GET_QUALITY_VERIFICATION_IMAGES_SUCCESS = 'GET_QUALITY_VERIFICATION_IMAGES_SUCCESS'
export const GET_QUALITY_VERIFICATION_IMAGES_FAIL = 'GET_QUALITY_VERIFICATION_IMAGES_FAIL'
export const DELETE_COMPLETE_PRODUCTION_FILE = 'DELETE_COMPLETE_PRODUCTION_FILE'
export const DELETE_COMPLETE_PRODUCTION_FILE_SUCCESS = 'DELETE_COMPLETE_PRODUCTION_FILE_SUCCESS'
export const DELETE_COMPLETE_PRODUCTION_FILE_FAIL = 'DELETE_COMPLETE_PRODUCTION_FILE_FAIL'
export const GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID = 'GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID'
export const GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_SUCCESS = 'GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_SUCCESS'
export const GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_FAIL = 'GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_FAIL'
export const UPLOAD_RESOLUTION_IMAGE = 'UPLOAD_RESOLUTION_IMAGE'
export const UPLOAD_RESOLUTION_IMAGE_SUCCESS = 'UPLOAD_RESOLUTION_IMAGE_SUCCESS'
export const UPLOAD_RESOLUTION_IMAGE_FAIL = 'UPLOAD_RESOLUTION_IMAGE_FAIL'
