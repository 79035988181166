import {
	CREATE_NEW_HOLIDAY,
	CREATE_NEW_HOLIDAY_FAIL,
	CREATE_NEW_HOLIDAY_SUCCESS,
	CREATE_NEW_MANAGER,
	CREATE_NEW_MANAGER_FAIL,
	CREATE_NEW_MANAGER_SUCCESS,
	CREATE_NEW_SHIPPING_ADDRESS,
	CREATE_NEW_SHIPPING_ADDRESS_FAIL,
	CREATE_NEW_SHIPPING_ADDRESS_SUCCESS,
	CREATE_NEW_SUPPLIER,
	CREATE_NEW_SUPPLIER_FAIL,
	CREATE_NEW_SUPPLIER_SUCCESS,
	DELETE_HOLIDAY_BY_ID,
	DELETE_HOLIDAY_BY_ID_FAIL,
	DELETE_HOLIDAY_BY_ID_SUCCESS,
	GET_ACCOUNT_BY_EMAIL,
	GET_ACCOUNT_BY_EMAIL_FAIL,
	GET_ACCOUNT_BY_EMAIL_SUCCESS,
	GET_COUNTRIES_DASHBOARD,
	GET_COUNTRIES_DASHBOARD_FAIL,
	GET_COUNTRIES_DASHBOARD_SUCCESS,
	GET_CUSTOMERS,
	GET_CUSTOMERS_FAIL,
	GET_CUSTOMERS_SUCCESS,
	GET_DOMAIN_USERS,
	GET_DOMAIN_USERS_FAIL,
	GET_DOMAIN_USERS_SUCCESS,
	GET_HOLIDAYS,
	GET_HOLIDAYS_FAIL,
	GET_HOLIDAYS_SUCCESS,
	GET_MANAGERS,
	GET_MANAGERS_FAIL,
	GET_MANAGERS_STATISTICS_BY_IDS,
	GET_MANAGERS_STATISTICS_BY_IDS_FAIL,
	GET_MANAGERS_STATISTICS_BY_IDS_SUCCESS,
	GET_MANAGERS_SUCCESS,
	GET_MANAGER_DETAIL_BY_ID,
	GET_MANAGER_DETAIL_FAIL,
	GET_MANAGER_DETAIL_SUCCESS,
	GET_MANAGER_LIST,
	GET_MANAGER_LIST_FAIL,
	GET_MANAGER_LIST_SUCCESS,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID_FAIL,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID_SUCCESS,
	GET_ORGANIZATION_BY_ID,
	GET_ORGANIZATION_BY_ID_FAIL,
	GET_ORGANIZATION_BY_ID_SUCCESS,
	GET_SUPPLIERS,
	GET_SUPPLIERS_BY_ORDER_ID,
	GET_SUPPLIERS_BY_ORDER_ID_FAIL,
	GET_SUPPLIERS_BY_ORDER_ID_SUCCESS,
	GET_SUPPLIERS_FAIL,
	GET_SUPPLIERS_INFO,
	GET_SUPPLIERS_INFO_FAIL,
	GET_SUPPLIERS_INFO_SUCCESS,
	GET_SUPPLIERS_SUCCESS,
	GET_SUPPLIER_DETAIL,
	GET_SUPPLIER_DETAIL_FAIL,
	GET_SUPPLIER_DETAIL_SUCCESS,
	GET_SUPPLIER_INFO,
	GET_SUPPLIER_INFO_FAIL,
	GET_SUPPLIER_INFO_SUCCESS,
	GET_USERS_DASHBOARD,
	GET_USERS_DASHBOARD_FAIL,
	GET_USERS_DASHBOARD_SUCCESS,
	REMOVE_MANAGER_COMMISSION_BY_ID,
	REMOVE_MANAGER_COMMISSION_BY_ID_FAIL,
	REMOVE_MANAGER_COMMISSION_BY_ID_SUCCESS,
	UPDATE_ACTIVE_SUPPLIER,
	UPDATE_ACTIVE_SUPPLIER_FAIL,
	UPDATE_ACTIVE_SUPPLIER_SUCCESS,
	UPDATE_DASHBOARD_COUNTRY_LIST,
	UPDATE_HOLIDAY_BY_ID,
	UPDATE_HOLIDAY_BY_ID_FAIL,
	UPDATE_HOLIDAY_BY_ID_SUCCESS,
	UPDATE_MANAGER_BY_ID,
	UPDATE_MANAGER_BY_ID_FAIL,
	UPDATE_MANAGER_BY_ID_SUCCESS,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_FAIL,
	UPDATE_NOTIFICATIONS_SUCCESS,
	UPDATE_PAYMENT_POSTPONEMENT,
	UPDATE_PAYMENT_POSTPONEMENT_FAIL,
	UPDATE_PAYMENT_POSTPONEMENT_SUCCESS,
	UPDATE_SHIPPING_ADDRESS,
	UPDATE_SHIPPING_ADDRESS_FAIL,
	UPDATE_SHIPPING_ADDRESS_SUCCESS,
	UPDATE_SUPPLIER_DETAIL,
	UPDATE_SUPPLIER_DETAIL_FAIL,
	UPDATE_SUPPLIER_DETAIL_SUCCESS,
} from './actionTypes'



export const updateNotifications = (notifications) => ({
	type: UPDATE_NOTIFICATIONS,
	payload: { notifications },
})

export const updateNotificationsSuccess = ({ notifications }) => ({
	type: UPDATE_NOTIFICATIONS_SUCCESS,
	payload: { notifications },
})


export const updateNotificationsFail = (error) => ({
	type: UPDATE_NOTIFICATIONS_FAIL,
	payload: { error },
})

export const getNotificationsByAccountId = (accountId) => ({
	type: GET_NOTIFICATIONS_BY_ACCOUNT_ID,
	payload: { accountId },
})

export const getNotificationsByAccountIdSuccess = ({ notifications }) => ({
	type: GET_NOTIFICATIONS_BY_ACCOUNT_ID_SUCCESS,
	payload: { notifications },
})

export const getNotificationsByAccountIdFail = (error) => ({
	type: GET_NOTIFICATIONS_BY_ACCOUNT_ID_FAIL,
	payload: { error },
})

export const getManagersStatisticsByIds = (managersIds) => ({
	type: GET_MANAGERS_STATISTICS_BY_IDS,
	payload: { managersIds },
})

export const getManagersStatisticsByIdsSuccess = (response) => ({
	type: GET_MANAGERS_STATISTICS_BY_IDS_SUCCESS,
	payload: { response },
})

export const getManagersStatisticsByIdsFail = (error) => ({
	type: GET_MANAGERS_STATISTICS_BY_IDS_FAIL,
	payload: { error },
})


export const getHolidays = () => ({
	type: GET_HOLIDAYS,
})

export const getHolidaysSuccess = (holidays) => ({
	type: GET_HOLIDAYS_SUCCESS,
	payload: holidays,

})

export const getHolidaysFail = error => ({
	type: GET_HOLIDAYS_FAIL,
	payload: error,
})

export const createHoliday = holiday => ({
	type: CREATE_NEW_HOLIDAY,
	payload: holiday,
})

export const createHolidaySuccess = ({ holiday }) => ({
	type: CREATE_NEW_HOLIDAY_SUCCESS,
	payload: holiday,
})

export const createHolidayFail = error => ({
	type: CREATE_NEW_HOLIDAY_FAIL,
	payload: error,
})

export const updateHolidayById = holidayUpdated => ({
	type: UPDATE_HOLIDAY_BY_ID,
	payload: holidayUpdated,
})

export const updateHolidayByIdSuccess = holiday => ({
	type: UPDATE_HOLIDAY_BY_ID_SUCCESS,
	payload: holiday,
})

export const updateHolidayByIdFail = error => ({
	type: UPDATE_HOLIDAY_BY_ID_FAIL,
	payload: error,
})


export const removeManagerCommissionById = id => ({
	type: REMOVE_MANAGER_COMMISSION_BY_ID,
	payload: id,
})

export const removeManagerCommissionByIdSuccess = commision => ({
	type: REMOVE_MANAGER_COMMISSION_BY_ID_SUCCESS,
	payload: commision,
})

export const removeManagerCommissionByIdFail = error => ({
	type: REMOVE_MANAGER_COMMISSION_BY_ID_FAIL,
	payload: error,
})



export const deleteHolidayById = idHoliday => ({
	type: DELETE_HOLIDAY_BY_ID,
	payload: idHoliday,
})

export const deleteHolidayByIdSuccess = holiday => ({
	type: DELETE_HOLIDAY_BY_ID_SUCCESS,
	payload: holiday,
})

export const deleteHolidayByIdFail = error => ({
	type: DELETE_HOLIDAY_BY_ID_FAIL,
	payload: error,
})

export const updateManagerById = user => ({
	type: UPDATE_MANAGER_BY_ID,
	payload: { data: { account: user } },
})

export const updateManagerByIdSuccess = user => ({
	type: UPDATE_MANAGER_BY_ID_SUCCESS,
	payload: user,
})
export const updateManagerByIdFail = error => ({
	type: UPDATE_MANAGER_BY_ID_FAIL,
	payload: error,
})

export const getSupplierInfo = idSupplier => ({
	type: GET_SUPPLIER_INFO,
	payload: idSupplier,
})

export const getSupplierInfoSuccess = ({
	supplierInfo,
	imasWorkshopInfo,
}) => ({
	type: GET_SUPPLIER_INFO_SUCCESS,
	payload: { supplierInfo, imasWorkshopInfo },
})

export const getSupplierInfoFail = error => ({
	type: GET_SUPPLIER_INFO_FAIL,
	payload: error,
})

export const getSuppliers = () => ({
	type: GET_SUPPLIERS,
})

export const getSuppliersSuccess = ({ data: { accounts } }) => ({
	type: GET_SUPPLIERS_SUCCESS,
	payload: { accounts },
})

export const getSuppliersFail = error => ({
	type: GET_SUPPLIERS_FAIL,
	payload: error,
})
export const getSuppliersByOrderId = orderId => ({
	type: GET_SUPPLIERS_BY_ORDER_ID,
	payload: { orderId },
})

export const getSuppliersByOrderIdSuccess = suppliers => ({
	type: GET_SUPPLIERS_BY_ORDER_ID_SUCCESS,
	payload: suppliers,
})

export const getSuppliersByOrderIdFail = error => ({
	type: GET_SUPPLIERS_BY_ORDER_ID_FAIL,
	payload: error,
})

export const getManagers = () => ({
	type: GET_MANAGERS,
})

export const getManagersSuccess = ({ data }) => ({
	type: GET_MANAGERS_SUCCESS,
	payload: data.accounts,
})

export const getManagersFail = error => ({
	type: GET_MANAGERS_FAIL,
	payload: error,
})

export const getManagerDetailById = id => ({
	type: GET_MANAGER_DETAIL_BY_ID,
	payload: { id },
})

export const getManagerDetailSuccess = (manager) => {
	return {
		type: GET_MANAGER_DETAIL_SUCCESS,
		payload: manager,
	}
}

export const getManagerDetailFail = error => ({
	type: GET_MANAGER_DETAIL_FAIL,
	payload: error,
})

export const createNewManager = user => ({
	type: CREATE_NEW_MANAGER,
	payload: user,
})

export const createNewManagerSuccess = user => ({
	type: CREATE_NEW_MANAGER_SUCCESS,
	payload: user,
})

export const createNewManagerFail = error => ({
	type: CREATE_NEW_MANAGER_FAIL,
	payload: error,
})

export const createSupplier = user => ({
	type: CREATE_NEW_SUPPLIER,
	payload: user,
})

export const createSupplierSuccess = user => ({
	type: CREATE_NEW_SUPPLIER_SUCCESS,
	payload: user,
})

export const createSupplierFail = error => ({
	type: CREATE_NEW_SUPPLIER_FAIL,
	payload: error,
})

export const getSuppliersInfo = () => {
	return {
		type: GET_SUPPLIERS_INFO,
	}
}

export const getSuppliersInfoSuccess = ({ suppliers }) => ({
	type: GET_SUPPLIERS_INFO_SUCCESS,
	payload: suppliers,
})

export const getSuppliersInfoFail = error => ({
	type: GET_SUPPLIERS_INFO_FAIL,
	payload: error,
})

export const getSupplierDetail = id => ({
	type: GET_SUPPLIER_DETAIL,
	payload: { id },
})

export const getSupplierDetailSuccess = (supplier) => ({
	type: GET_SUPPLIER_DETAIL_SUCCESS,
	payload: { supplier },
})

export const getSupplierDetailFail = error => ({
	type: GET_SUPPLIER_DETAIL_FAIL,
	payload: error,
})

export const updateSupplier = user => ({
	type: UPDATE_SUPPLIER_DETAIL,
	payload: user,
})

export const updateSupplierSuccess = user => ({
	type: UPDATE_SUPPLIER_DETAIL_SUCCESS,
	payload: user,
})

export const updateSupplierFail = error => ({
	type: UPDATE_SUPPLIER_DETAIL_FAIL,
	payload: error,
})

export const getAccountByEmail = ({ data: { email } }) => ({
	type: GET_ACCOUNT_BY_EMAIL,
	payload: { email },
})

export const getAccountByEmailSuccess = ({ data: { account } }) => ({
	type: GET_ACCOUNT_BY_EMAIL_SUCCESS,
	payload: { account },
})

export const getAccountByEmailFail = error => ({
	type: GET_ACCOUNT_BY_EMAIL_FAIL,
	payload: error,
})

export const getCustomers = () => ({
	type: GET_CUSTOMERS,
})

export const getCustomersSuccess = ({ data: { accounts } }) => ({
	type: GET_CUSTOMERS_SUCCESS,
	payload: { accounts },
})
export const getCustomersFail = error => ({
	type: GET_CUSTOMERS_FAIL,
	payload: error,
})
export const createNewShippingAddress = user => ({
	type: CREATE_NEW_SHIPPING_ADDRESS,
	payload: user,
})

export const createNewShippingAddressSuccess = user => ({
	type: CREATE_NEW_SHIPPING_ADDRESS_SUCCESS,
	payload: user,
})

export const createNewShippingAddressFail = error => ({
	type: CREATE_NEW_SHIPPING_ADDRESS_FAIL,
	payload: error,
})

export const updateShippingAddress = user => ({
	type: UPDATE_SHIPPING_ADDRESS,
	payload: user,
})

export const updateShippingAddressSuccess = user => ({
	type: UPDATE_SHIPPING_ADDRESS_SUCCESS,
	payload: user,
})
export const updateShippingAddressFail = error => ({
	type: UPDATE_SHIPPING_ADDRESS_FAIL,
	payload: error,
})

export const updatePaymentPostponement = (data) => ({
	type: UPDATE_PAYMENT_POSTPONEMENT,
	payload: data,
})

export const updatePaymentPostponementSuccess = user => ({
	type: UPDATE_PAYMENT_POSTPONEMENT_SUCCESS,
	payload: user,
})
export const updatePaymentPostponementFail = error => ({
	type: UPDATE_PAYMENT_POSTPONEMENT_FAIL,
	payload: error,
})

export const getDomainUsers = (email) => ({
	type: GET_DOMAIN_USERS,
	payload: { email },
})

export const getDomainUsersSuccess = (response) => ({
	type: GET_DOMAIN_USERS_SUCCESS,
	payload: { response },
})

export const getDomainUsersFail = (error) => ({
	type: GET_DOMAIN_USERS_FAIL,
	payload: { error },
})

export const updateActiveSupplier = user => ({
	type: UPDATE_ACTIVE_SUPPLIER,
	payload: user,
})

export const updateActiveSupplierSuccess = user => ({
	type: UPDATE_ACTIVE_SUPPLIER_SUCCESS,
	payload: user,
})

export const updateActiveSupplierFail = error => ({
	type: UPDATE_ACTIVE_SUPPLIER_FAIL,
	payload: error,
})

export const updateDashboardCountryList = dashboardCountryList => ({
	type: UPDATE_DASHBOARD_COUNTRY_LIST,
	payload: { dashboardCountryList },
})

export const getManagerList = () => ({
	type: GET_MANAGER_LIST,
})

export const getManagerListSuccess = ({ managerList }) => ({
	type: GET_MANAGER_LIST_SUCCESS,
	payload: managerList,
})

export const getManagerListFail = error => ({
	type: GET_MANAGER_LIST_FAIL,
	payload: error,
})

export const getOrganizationById = ({ id }) => ({
	type: GET_ORGANIZATION_BY_ID,
	payload: { id },
})
export const getOrganizationByIdSuccess = ({ data: { organization } }) => ({
	type: GET_ORGANIZATION_BY_ID_SUCCESS,
	payload: { organization },
})
export const getOrganizationByIdFail = error => ({
	type: GET_ORGANIZATION_BY_ID_FAIL,
	payload: error,
})

export const getUsersDashboard = (data) => ({
	type: GET_USERS_DASHBOARD,
	payload: { data },
})
export const getUsersDashboardSuccess = (usersData) => ({
	type: GET_USERS_DASHBOARD_SUCCESS,
	payload: usersData,
})
export const getUsersDashboardFail = error => ({
	type: GET_USERS_DASHBOARD_FAIL,
	payload: error,
})

export const getCountriesDashboard = (data) => ({
	type: GET_COUNTRIES_DASHBOARD,
	payload: { data },
})
export const getCountriesDashboardSuccess = (countriesData) => ({
	type: GET_COUNTRIES_DASHBOARD_SUCCESS,
	payload: countriesData,
})
export const getCountriesDashboardFail = error => ({
	type: GET_COUNTRIES_DASHBOARD_FAIL,
	payload: error,
})
