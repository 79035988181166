import FileInput from 'components/Common/FileInput'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import Table from 'components/Table/Table'
import { Couriers } from 'constants/couriers'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const FinishProductionModal = ({
	t,
	isLoading,
	supplierCountry,
	isOpen,
	closeModal,
	step,
	setStep,
	itemsToRender,
	handleFinishProduction,
	newShippingCompany,
	setNewShippingCompany,
	newShipmentTracking,
	setNewShipmentTracking,
	declaredValue,
	setDeclaredValue,
	setInvoiceFile,
	setPartImagesFiles,
	newShipmentDate,
	setNewShipmentDate,
	isShippingAllParts,
}) => {

	const [invoice, setInvoice] = useState()
	const [partImages, setPartImages] = useState()

	const internationalSupplier = supplierCountry.toLowerCase() != 'es'

	const header = {
		partId: 'part-id',
		part: 'part',
		technology: 'technology',
		description: 'description',
		pendingQuantity: 'pending-quantity',
		shipping: 'shipping',
	}

	const shippingCompanies = [
		{
			value: Couriers.DHL,
			label: 'DHL',
			maxCharacters: 10,
		},
		{
			value: Couriers.NACEX,
			label: 'NACEX',
			maxCharacters: 8,
		},
		{
			value: Couriers.HAND_DELIVERY,
			label: t('hand-delivery', { ns: 'naming' }),
			maxCharacters: 0,
		},
	]

	const getShippingCompanies = () => {
		if (internationalSupplier) {
			return [shippingCompanies[0]]
		}
		return shippingCompanies
	}

	const isButtonDisabled = () => {
		if (internationalSupplier) {
			return !(newShippingCompany && newShipmentTracking?.length > 0 && declaredValue > 0 && invoice && partImages?.length > 0)
		}
		return false
	}

	return <Modal
		isOpen={isOpen}
		closeModal={() => closeModal()}
		title={t('finish-production', { ns: 'naming' })}
		className={step === 2 ? 'largeModal' : ''}
		body={
			<div className="mx-3">
				{step === 1 && <div>
					<h5 className="mt-2">{t('finish-production-title', { ns: 'naming' })}</h5>
					<p>{t('finish-production-1', { ns: 'naming' })}</p>
					<p className="mb-1">{t('finish-production-2', { ns: 'naming' })}</p>
					{internationalSupplier && <p className="my-1">{t('finish-production-3', { ns: 'naming' })}</p>}
					{internationalSupplier && <p className="my-1">{t('finish-production-4', { ns: 'naming' })}</p>}
					{internationalSupplier && <p className="mt-1">{t('finish-production-5', { ns: 'naming' })}</p>}
					<p>{t('finish-production-6', { ns: 'naming' })}</p>
				</div>}
				{step === 2 && <Table
					disableInteractions
					header={header}
					items={itemsToRender}
					selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
					language={'en'}
					rowQuantityList={[{ text: '1', value: 1 }]}
					allowAllItemsPerPage
					defaultSort={{ dataField: 'id', order: 'asc' }}
					styleForFieldList={[
						{
							field: 'description',
							styles: [{ type: 'list', class: 'd-block' }],
						},
					]}
				/>}
				{!isShippingAllParts && step === 3 && <div className='m-2'>
					<div className='mb-1'>{t('new-shipment-date-message', { ns: 'naming' })}</div>
					<Flatpickr
						className="form-control"
						placeholder={t('new-shipment-date', { ns: 'naming' })}
						options={{
							dateFormat: 'd/m/Y',
							locale: {
								firstDayOfWeek: 1,
							},
						}}
						value={newShipmentDate}
						onChange={(e, dateStr) => {
							setNewShipmentDate(e[0])
						}}
					/>
				</div>}
				{(isShippingAllParts ? step === 3 : step === 4) && <div>
					<OptionsInput
						isMulti={false}
						value={newShippingCompany}
						automaticMappedValue
						setValue={setNewShippingCompany}
						title={t('courier', { ns: 'naming' })}
						options={getShippingCompanies()}
					/>
					{newShippingCompany != Couriers.HAND_DELIVERY && <TextInput
						title={t('shipment-tracking', { ns: 'naming' })}
						maxLength={shippingCompanies.find(e => e.value == newShippingCompany)?.maxCharacters || 10}
						placeholder={t('shipment-tracking-placeholder', { ns: 'naming' })}
						value={newShipmentTracking}
						setValue={setNewShipmentTracking}
					/>}
					{internationalSupplier && <TextInput
						title={t('declared-value', { ns: 'naming' })}
						value={declaredValue}
						setValue={setDeclaredValue}
					/>}
					<FileInput
						title={internationalSupplier ? t('invoice-pdf', { ns: 'naming' }) : t('invoice-dispatch-note-pdf', { ns: 'naming' })}
						value={invoice}
						setValue={setInvoice}
						setFiles={setInvoiceFile}
						accept={'.pdf'}
					/>
					<FileInput
						title={t('part-images', { ns: 'naming' })}
						value={partImages}
						setValue={setPartImages}
						setFiles={setPartImagesFiles}
						multiple={true}
						accept={'.png, .jpg'}
					/>
				</div>}
			</div>
		}
		buttons={[
			<button
				key="finishProductionNextButton"
				type="button"
				className="btn btn-primary"
				hidden={(isShippingAllParts ? step === 3 : step === 4)}
				onClick={() => setStep(step + 1)}
			>
				<span className="p-2">{t('continue', { ns: 'naming' })}</span>
			</button>,
			<button
				key="finishProductionButton"
				type="button"
				className="btn btn-primary"
				hidden={(isShippingAllParts ? step !== 3 : step !== 4)}
				onClick={() => handleFinishProduction()}
				disabled={isButtonDisabled()}
			>
				{isLoading ? <Loader /> : <span className="p-2">{t('finish-production', { ns: 'naming' })}</span>}
			</button>,
		]}
	/>
}

FinishProductionModal.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	supplierCountry: PropTypes.string,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	step: PropTypes.number,
	setStep: PropTypes.func,
	itemsToRender: PropTypes.array,
	handleFinishProduction: PropTypes.func,
	newShippingCompany: PropTypes.object,
	setNewShippingCompany: PropTypes.func,
	newShipmentTracking: PropTypes.string,
	setNewShipmentTracking: PropTypes.func,
	declaredValue: PropTypes.number,
	setDeclaredValue: PropTypes.func,
	setInvoiceFile: PropTypes.func,
	setPartImagesFiles: PropTypes.func,
	newShipmentDate: PropTypes.any,
	setNewShipmentDate: PropTypes.func,
	isShippingAllParts: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	isLoading: state.Productions.isLoading,
})

export default connect(mapStateToProps)(withTranslation()(FinishProductionModal))
