import SimpleTable from 'components/Common/SimpleTable'
import { NOTIFICATIONTYPE_COLOR, NOTIFICATION_TYPE } from 'constants/notificationType'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap'
import { Checkbox } from 'semantic-ui-react'
import { getNotificationsByAccountId, updateNotifications } from 'store/actions'


const ARCHIVED_FILTER = "Archivado"
const IMPORTANT_FILTER = "Importante"
const PENDING_FILTER = "Pendiente"
const ALL_FILTER = "Todas"


export const Notifications = ({ t, getNotificationsByAccountId, notifications, error, isLoading, selectedManagersIds, updateNotifications }) => {


	const filterOptions = [ARCHIVED_FILTER, IMPORTANT_FILTER, PENDING_FILTER, ALL_FILTER]

	const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
	const [filter, setFilter] = useState('')

	useEffect(() => {
		if (selectedManagersIds.length > 0) {
			selectedManagersIds.forEach((id) => {
				getNotificationsByAccountId(id)
			})
		}
	}, [selectedManagersIds])

	const handleCheckBox = (id) => {
		if (selectedCheckboxes.includes(id)) {
			setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== id))

		} else {
			setSelectedCheckboxes([...selectedCheckboxes, id])

		}
	}

	const handleStarClick = (id) => {
		const notificationToUpdate = notifications.find(notification => notification.id === id)
		const updatedNotification = [{
			id: notificationToUpdate.id,
			important: !notificationToUpdate.important
		}]

		updateNotifications(updatedNotification)
	}

	const handleCompleteNotifications = () => {
		const updatedNotifications = selectedCheckboxes.map(id => {
			const notification = notifications.find(notification => notification.id === id)
			return {
				id,
				completed: !notification.completed
			}
		})

		updateNotifications(updatedNotifications)
	}

	const handleReadNotifications = (ids) => {
		const updatedNotifications = ids.map(id => {
			const notification = notifications.find(notification => notification.id === id)
			return {
				id,
				readDate: new Date().toISOString(),
				read: true
			}
		})
		updateNotifications(updatedNotifications)
	}

	const handleLinkClick = (id, url) => {
		handleReadNotifications([id])
		window.open(url, '_blank', 'noopener noreferrer')
	}

	const getTableBody = () => {
		if (!notifications || notifications.length < 1) {
			return (
				<tr>
					<td colSpan="10">{t('notifications_not_available', { ns: 'naming' })} </td>
				</tr>
			)
		}

		let filteredNotifications = notifications
		switch (filter) {
			case ARCHIVED_FILTER:
				filteredNotifications = notifications.filter(notification => notification.completed)
				break
			case IMPORTANT_FILTER:
				filteredNotifications = notifications.filter(notification => notification.important)
				break
			case PENDING_FILTER:
				filteredNotifications = notifications.filter(notification => !notification.read)
				break
			case ALL_FILTER:
				filteredNotifications = notifications
				break
			default:
				filteredNotifications = notifications
		}

		const getNotificationText = (type_id, t) => {
			switch (type_id) {
				case NOTIFICATION_TYPE.OfferPublished:
					return t('new_offer', { ns: 'naming' })
				case NOTIFICATION_TYPE.OrderPublished:
					return t('new_order', { ns: 'naming' })
				case NOTIFICATION_TYPE.QuotePublished:
					return t('quote_published', { ns: 'naming' })
				case NOTIFICATION_TYPE.ProdRejected:
					return t('product_rejected', { ns: 'naming' })
				case NOTIFICATION_TYPE.QualityIncidence:
					return t('quality_incidence', { ns: 'naming' })
				default:
					return t('unknown_notification', { ns: 'naming' })
			}
		}

		const getNotificationLink = (notification, t, handleLinkClick) => {
			const { type_id, data_id, involvedAccount } = notification
			const baseUrl = 'https://dev.admin.protoandgo.es'
			let accountName = ''

			if (involvedAccount) {
				accountName = `${involvedAccount.personalInformation.firstName} ${involvedAccount.personalInformation.lastName}`
			}
			switch (type_id) {
				case NOTIFICATION_TYPE.OfferPublished:
					return (
						<a href={`${baseUrl}/offer/${data_id}`} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(notification.id, `${baseUrl}/offer/${data_id}`)}>
							{t('offer_notification', { ns: 'naming' })} {accountName}!
						</a>
					)
				case NOTIFICATION_TYPE.OrderPublished:
					return (
						<a href={`${baseUrl}/order/${data_id}`} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(notification.id, `${baseUrl}/order/${data_id}`)}>
							{t('order_notification', { ns: 'naming' })} {accountName}!
						</a>
					)
				case NOTIFICATION_TYPE.QuotePublished:
					return (
						<a href={`${baseUrl}/quote/${data_id}`} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(notification.id, `${baseUrl}/quote/${data_id}`)}>
							{t('quote_notification', { ns: 'naming' })} {accountName}!
						</a>
					)

				case NOTIFICATION_TYPE.ProdRejected:
					return (
						<a href={`${baseUrl}/order/${data_id}`} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(notification.id, `${baseUrl}/order/${data_id}`)}>
							{t('prod_rejected_notification', { ns: 'naming' })} {accountName}!
						</a>
					)

				case NOTIFICATION_TYPE.QualityIncidence:
					return (
						<a href={`${baseUrl}/order/${data_id}`} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(notification.id, `${baseUrl}/order/${data_id}`)}>
							{t('quality_incidence_notification', { ns: 'naming' })}
						</a>
					)

				default:
					return <p>{data_id}</p>
			}
		}

		const formatCreationDate = (creationDate) => {
			const now = new Date()
			const creationDateObj = new Date(creationDate)
			if (now.toDateString() === creationDateObj.toDateString()) {
				return creationDateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
			} else {
				return creationDateObj.toLocaleDateString([], { day: '2-digit', month: 'long' })
			}
		}

		return filteredNotifications.map(notification => (
			<tr key={notification.id} style={{ backgroundColor: "red" }}>
				<td style={{ backgroundColor: notification.read ? '#ededed' : 'white' }}>
					<Checkbox
						checked={selectedCheckboxes.includes(notification.id)}
						onChange={() => handleCheckBox(notification.id)}
					/>
				</td>
				<td style={{ backgroundColor: notification.read ? '#ededed' : 'white' }}>
					{notification.completed && (
						<i className='bx bx-archive-in' style={{ fontSize: "20px", padding: "5px" }}></i>
					)}
				</td>
				<td style={{ backgroundColor: notification.read ? '#ededed' : 'white' }}>
					<button
						style={{ border: "0px", backgroundColor: "transparent" }}
						onClick={() => handleStarClick(notification.id)}
					>
						{notification.important ? (
							<i className='bx bxs-star' style={{ color: "#fcd303" }}></i>
						) : (
							<i className='bx bx-star'></i>
						)}
					</button>

				</td>
				<td style={{ backgroundColor: notification.read ? '#ededed' : 'white' }}><span className={`rounded-pill end-ellipsis text-white bg-${NOTIFICATIONTYPE_COLOR[notification.type_id]}`}>
					{getNotificationText(notification.type_id, t)}
				</span>{(notification.type_id === NOTIFICATION_TYPE.ProdRejected || notification.type_id === NOTIFICATION_TYPE.QualityIncidence) && (
					<div style={{ color: "red", marginLeft: "-21px", marginTop: "-17px" }}>
						<i className='bx bx-error fs-5'></i>
					</div>
				)}</td>
				<td style={{ backgroundColor: notification.read ? '#ededed' : 'white' }}>
					{getNotificationLink(notification, t, handleLinkClick)}
				</td>
				<td style={{ backgroundColor: notification.read ? '#ededed' : 'white' }}>{formatCreationDate(notification.creationDate)}</td>
			</tr>
		))
	}

	const header = ['Seleccionar', 'Archivado', 'Importante', 'Tipo', 'Mensaje', 'Fecha']

	return (
		<div>
			<div className='d-flex flex-wrap justify-content-start mb-3'>
				<div className='d-inline-flex position-relative align-middle me-2 mb-2 mb-sm-0'>
					<button style={{ borderRadius: "3px", border: "0px", width: "60px", height: "35px" }} className='btn btn-secondary' onClick={handleCompleteNotifications}>
						<i class='bx bx-archive-in' style={{ fontSize: "20px" }}></i>
					</button>
					<UncontrolledDropdown>
						<DropdownToggle
							style={{ borderRadius: "3px", marginLeft: "15px", border: "0px", width: "80px" }}
							className='btn btn-secondary'
							caret
						>
							{t('filter', { ns: 'naming' })}
						</DropdownToggle>
						<DropdownMenu
							size="lg"
							className="w-100 h-85">
							{
								filterOptions.map((option, index) => {
									const isActive = filter === option
									return (
										<div
											key={index} className="p-3 list-status-item-filter cursor-pointer d-flex justify-content-center"
											onClick={() => { setFilter(option) }}
										>
											<div
												className={isActive ? 'border-bottom border-warning' : 'border-bottom border-white'}>
												<div className="mx-2">
													<span className="">{option}</span>
												</div>
											</div>
										</div>
									)
								})
							}
						</DropdownMenu>
					</UncontrolledDropdown>

				</div>
			</div>
			<div style={{ maxHeight: '400px', overflowY: 'auto' }}>
				<SimpleTable getTableBody={getTableBody} header={header} striped={false} />
			</div>
		</div>
	)
}

Notifications.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	getNotificationsByAccountId: PropTypes.func,
	updateNotifications: PropTypes.func,
	notifications: PropTypes.object,
}

const mapStateToProps = (state) => ({
	notifications: state.Users.notifications,
	isLoading: state.Users.isLoading,
	error: state.Users.error,
})

const mapDispatchToProps = { getNotificationsByAccountId, updateNotifications }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Notifications))
