export const NOTIFICATION_TYPE_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8]

export const NOTIFICATION_TYPE = {
	OfferPublished: 1,
	OrderDelayed: 2,
	OrderSent: 3,
	OrderPublished: 4,
	ErrorPayment: 5,
	QuotePublished: 6,
	ProdRejected: 7,
	QualityIncidence: 8,
}

export const NOTIFICATIONTYPE_COLOR = {
	[NOTIFICATION_TYPE.OfferPublished]: 'secondary',
	[NOTIFICATION_TYPE.OrderPublished]: 'yellow',
	[NOTIFICATION_TYPE.ErrorPayment]: 'red',
	[NOTIFICATION_TYPE.QuotePublished]: 'orange',
	[NOTIFICATION_TYPE.ProdRejected]: 'cyan',
	[NOTIFICATION_TYPE.QualityIncidence]: 'blue'

}
