import { STATUS } from './status'

export const PART_STATUS = {
	NEW: STATUS.NEW,
	REQUESTED: STATUS.REQUESTED,
	QUOTED: STATUS.QUOTED,
	UPLOADED: STATUS.UPLOADED,
	REJECTED: STATUS.REJECTED,
	HIDDEN: STATUS.HIDDEN,
}
