import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import MetaTags from 'react-meta-tags'
import Table from '../../components/Table/Table'
import Tabs from '../../components/Tabs/Tabs'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { roundAtDecimals, roundCurrency } from 'common/Utils/NumberUtilities'
import Alert from 'components/Common/Alert'
import BlueprintAndStp from 'components/Common/BlueprintAndStp'
import { default as CustomModal, default as Modal } from 'components/Common/Modal/Modal'
import { PartName } from 'components/Common/PartName'
import PartThumbnail from 'components/Common/PartThumbnail'
import MultiSelect from 'components/Common/Select/MultiSelect'
import Select from 'components/Common/Select/Select'
import Switch from 'components/Common/Switch/Switch'
import TooltipMessage from 'components/Common/TooltipMessage'
import appRoutes from 'constants/appRoutes'
import { variablesPriceMultipliers } from 'constants/variablesPriceFormula'
import JSZip from 'jszip'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { Col, Input, Row } from 'reactstrap'
import { Flag } from 'semantic-ui-react'
import {
	adaptDateOnLocale,
	adaptTimerString,
	adaptWeekday,
	getStatusClassName,
} from '../../common/Utils/StringUtilities'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Loader from '../../components/Common/Loader'
import {
	createQuote,
	deleteBindingNotes,
	downloadBluePrintOffer,
	getCustomers,
	getManagers,
	getOfferDetail,
	getPartConfigOptions,
	getPartCostsBySupplierId,
	getPartListImages,
	getPartPricesBySupplierId,
	getQuoteChat,
	getQuoteChatImages,
	getSuppliers,
	getSuppliersInfo,
	markQuoteMessagesAsRead,
	postQuoteChat,
	resetCreateQuoteModal,
	saveAndConfirmQuote,
	updateBindingNotes,
	updateOfferCustomer,
	updateParts,
	updateQuoteMargin,
	uploadBluePrintOffer,
	uploadOffer,
	uploadQuoteChatImage,
} from '../../store/actions'
import CreateQuoteResponseModal from './Modals/CreateQuoteResponseModal'
import EditCustomerModal from './Modals/EditCustomerModal'
import EditMarginsModal from './Modals/EditMarginsModal'
import EditQuantitiesModal from './Modals/EditQuantitiesModal'
import EditStatusModal from './Modals/EditStatusModal'
import OrderSimulationModal from './Modals/OrderSimulation/OrderSimulationModal'
import QuotationModal from './Modals/QuotationModal'
import VcPartCostsModal from './Modals/RegressionsModal/RegressionsModalContainer'
import UploadModal from './Modals/UploadModal'
import OfferTab from './OfferTab'
import OfferTopMenu from './OfferTopMenu'

import MeasureWeightAlert from 'components/Common/MeasureWeightAlert'
import PartSizeAndSimilarity from 'components/Common/PartSizeAndSimilarity'
import { EditConfigurationModal } from './Modals/EditConfigurationModal'
import GeometricSimilarityModal from './Modals/GeometricSimilarityModal'
import SendUploadEmailModal from './Modals/SendUploadEmailModal'

import { sortByString } from 'common/Utils/ArrayUtilities'
import { AIPrediction } from 'components/Common/AIPrediction'
import { EditBindingNoteModal } from 'pages/Offer/Modals/EditBindingNoteModal'
import CloneQuotationModal from './Modals/CloneQuotation/CloneQuotationModal'
import DeleteModifiedBlueprintsModal from './Modals/DeleteModifiedBlueprintsModal'
import OfferChat from './Modals/OfferChat'

import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { PartConfigTranslations, getClassnameByTechnology } from 'common/Utils/PartConfigTranslations'
import { ChatIconIcon } from 'components/ChatIconIcon'
import { NoteTooltip } from 'components/NoteTooltip'
import { PricesSeenAlert } from 'pages/OfferList/PricesSeenAlert'
import { updatePartsStatus } from 'store/parts/actions'

//#region constants
const SUCCESS_UPLOAD_RESPONSE = 'success'
const QUOTED_STATUS = 30
export const TINTED_ID = 17
const PA12NYLON = 63
export const IMPRESION3D_ID = 2
const REQUESTED_STATUS = 20
const NEW_STATUS = 10

const VACCUM_CASTING_TECHNOLOGY_ID = 4

const STATUS_OPTIONS = {
	10: 'new',
	20: 'requested',
	30: 'quoted',
	40: 'uploaded',
	45: 'draft_order',
	50: 'acceptance_pending',
	60: 'in_progress',
	70: 'shipped',
	80: 'received',
	90: 'cancelled',
	91: 'rejected',
	92: 'dropped',
	93: 'reject_pending',
}
//#endregion constants

//#region utils
function GetStatusClass(value) {
	const NEW = 10
	const REQUESTED = 20
	const QUOTED = 30
	const UPLOADED = 40
	const REJECTED = 91
	const RECEIVED = 80
	const HIDDEN = 92

	switch (value) {
		case NEW:
			return 'rounded-pill bg-secondary text-white'
		case UPLOADED:
			return 'rounded-pill bg-green text-white'
		case REQUESTED:
			return 'rounded-pill bg-yellow text-white'
		case REJECTED:
			return 'rounded-pill bg-red text-white'
		case RECEIVED:
			return 'rounded-pill bg-blue text-white'
		case QUOTED:
			return 'rounded-pill bg-orange text-white'
		case HIDDEN:
			return 'rounded-pill bg-red text-white'

	}
}

function GetOfferIdFromURL() {
	const parts = window.location.pathname.split('/')
	return parts[parts.length - 1]
}

function GetExpandRowControl(id, selectedSupplier) {
	return {
		id,
		isShowingExtraData: false,
		isShowingCalculations: false,
		selectedSupplier,
	}
}

function getQuotePrice(part, quantity) {
	const volBb = part.boundaryBoxVolume * quantity
	const volume = part.volume
	let totalPrice = 0

	if (volBb <= 1000) {
		totalPrice = volume * variablesPriceMultipliers[0]
	}
	else if (1000 < volBb && volBb <= 8000) {
		totalPrice = volume * variablesPriceMultipliers[1]
	}
	else if (volBb > 8000) {
		totalPrice = volume * variablesPriceMultipliers[2]
	}

	if (part.configuration.finishId == TINTED_ID) {
		totalPrice *= 1.1
	}

	if (quantity == 1 && totalPrice < 3) totalPrice = 3
	if (quantity != 1 && totalPrice < 1) totalPrice = 1

	return totalPrice
}

const getQuoteTime = (part, quantity) => {
	const totalVolume = part.volume * quantity
	let time = part.material.id === PA12NYLON ? 4 : 6
	if (part.configuration.finishId === TINTED_ID) time += 1
	return time + Math.ceil((totalVolume - 3000) / 3000)
}
//#endregion utils

class Offer extends Component {
	//#region functions
	constructor(props) {
		super(props)

		this.state = {

			expandRowControls: [],
			areExpandRowControlsInitialized: false,
			selectedRows: [],
			isQuotationModalOpen: false,
			isEditMarginsModalOpen: false,
			isModalMessageOpen: false,
			modalMessage: '',
			quotationModalData: {
				manager: null,
				suppliers: [],
				isUrgent: false,
				comment: '',
				isAutomatic: false,
			},
			translatedParts: [],
			isTranslating: false,
			language: '',
			showTable: true,
			isSTLViewerModalOpen: false,
			stlViewerData: {
				modelSrc: '',
				partName: '',
				partId: null,
				size: null,
				weight: null,
			},
			isEditCustomerModalOpen: false,
			selectedCustomer: null,
			isOrderSimulationModalOpen: false,
			modalChat: false,
			costsModal: {
				isOpen: false,
				partId: null,
				supplier: null,
			},
			isEditStatusModalOpen: false,
			isEditQuantitiesModalOpen: false,
			iaQuotationsCalculated: false,
			chatImagesToUpload: [],
			blueprints: [],
			downloadedBlueprints: [],
			isSendUploadEmailModalOpen: false,
			isEditConfigurationModalOpen: false,
			isGeometricSimilarityModalOpen: false,
			similarPartsIds: null,
			similarity: null,
			geometricSimilarityModalOfferId: null,
			isDeleteModifiedBlueprintsModalOpen: false,
			isCloneQuoteModalOpen: false,
			isEditBindingNoteModalOpen: false,
			selectedChatPartInfo: null,
			openedChats: [],
			suppliersSelected: [],
			selectedPartsForEditBindingNoteModal: [],
		}

		this.findExpandRowControlsById = this.findExpandRowControlsById.bind(this)
		this.handleShowCalculations = this.handleShowCalculations.bind(this)
		this.toggleSupplierOnPart = this.toggleSupplierOnPart.bind(this)
		this.openQuotationModal = this.openQuotationModal.bind(this)
		this.closeQuotationModal = this.closeQuotationModal.bind(this)
		this.closeCreateQuotationResultModal = this.closeCreateQuotationResultModal.bind(this)
		this.closeEditMarginsModal = this.closeEditMarginsModal.bind(this)
		this.saveAndCloseMarginsModal = this.saveAndCloseMarginsModal.bind(this)

		this.openUploadModal = this.openUploadModal.bind(this)
		this.closeUploadModal = this.closeUploadModal.bind(this)
		this.saveAndCloseUploadModal = this.saveAndCloseUploadModal.bind(this)
		this.openEditCustomerModal = this.openEditCustomerModal.bind(this)
		this.closeEditCustomerModal = this.closeEditCustomerModal.bind(this)
		this.setCustomer = this.setCustomer.bind(this)
		this.updateOfferCustomer = this.updateOfferCustomer.bind(this)
		this.handleShowCalculations = this.handleShowCalculations.bind(this)
		this.handleShowExtraInformation = this.handleShowExtraInformation.bind(this)
		this.onChangeManager = this.onChangeManager.bind(this)
		this.onChangeSupplier = this.onChangeSupplier.bind(this)
		this.onChangeUrgent = this.onChangeUrgent.bind(this)
		this.onChangeComment = this.onChangeComment.bind(this)
		this.onChangeAutomatic = this.onChangeAutomatic.bind(this)
		this.submitQuote = this.submitQuote.bind(this)
		this.toggleChat = this.toggleChat.bind(this)
		this.openChatModal = this.openChatModal.bind(this)
		this.setMargin = this.setMargin.bind(this)
		this.setUnitCostByMargin = this.setUnitCostByMargin.bind(this)
		this.closeModalMessage = this.closeModalMessage.bind(this)
		this.openOrderSimulationModal = this.openOrderSimulationModal.bind(this)
		this.closeOrderSimulationModal = this.closeOrderSimulationModal.bind(this)
		this.closeCostsModal = this.closeCostsModal.bind(this)
		this.openEditStatusModal = this.openEditStatusModal.bind(this)
		this.closeEditStatusModal = this.closeEditStatusModal.bind(this)
		this.openEditQuantitiesModal = this.openEditQuantitiesModal.bind(this)
		this.closeEditQuantitiesModal = this.closeEditQuantitiesModal.bind(this)
		this.downloadBluePrint = this.downloadBluePrint.bind(this)
		this.openBluePrintModal = this.openBluePrintModal.bind(this)
		this.closeBluePrintModal = this.closeBluePrintModal.bind(this)
		this.convertToReadableSize = this.convertToReadableSize.bind(this)
		this.getSelectedTabSupplier = this.getSelectedTabSupplier.bind(this)
		this.setModalMessage = this.setModalMessage.bind(this)
		this.openSendUploadEmailModal = this.openSendUploadEmailModal.bind(this)
		this.closeSendUploadEmailModal = this.closeSendUploadEmailModal.bind(this)
		this.openEditConfigurationModal = this.openEditConfigurationModal.bind(this)
		this.closeEditConfigurationModal = this.closeEditConfigurationModal.bind(this)
		this.openGeometricSimilarityModal = this.openGeometricSimilarityModal.bind(this)
		this.closeGeometricSimilarityModal = this.closeGeometricSimilarityModal.bind(this)
		this.openDeleteModifiedBlueprintsModal = this.openDeleteModifiedBlueprintsModal.bind(this)
		this.closeDeleteModifiedBlueprintsModal = this.closeDeleteModifiedBlueprintsModal.bind(this)
		this.handleOnChangeCloneQuoteModal = this.handleOnChangeCloneQuoteModal.bind(this)
		this.openEditBindingNoteModal = this.openEditBindingNoteModal.bind(this)
		this.closeEditBindingNoteModal = this.closeEditBindingNoteModal.bind(this)
		this.handleOnChangeChatSelectedPart = this.handleOnChangeChatSelectedPart.bind(this)
		this.handleOpenChatMessage = this.handleOpenChatMessage.bind(this)
		this.setSuppliersSelected = this.setSuppliersSelected.bind(this)
	}

	setSuppliersSelected(value) {
		this.setState({
			...this.state,
			suppliersSelected: value,
		})
	}

	openDeleteModifiedBlueprintsModal() {
		this.setState({
			...this.state,
			isDeleteModifiedBlueprintsModalOpen: true,
		})
	}
	closeDeleteModifiedBlueprintsModal() {
		this.setState({
			...this.state,
			isDeleteModifiedBlueprintsModalOpen: false,
		})
	}

	handleOnChangeCloneQuoteModal(boolean) {
		this.setState({
			...this.state,
			isCloneQuoteModalOpen: boolean,
		})
	}

	handleOpenChatMessage(partId, supplierId) {
		const currentPartOpenedChats = this.state.openedChats[partId] || []
		if (!currentPartOpenedChats.includes(supplierId)) {
			this.setState({
				...this.state,
				openedChats: {
					...this.state.openedChats,
					[partId]: [...currentPartOpenedChats, supplierId],
				},
			})
		}
	}

	getSelectedTabSupplier(partId) {
		const partRowControl = this.findExpandRowControlsById(partId)
		return this.state.selectedRows.find(row => row.id.value === partId).costsAndMargins.suppliers[partRowControl.selectedSupplier]
	}

	handleUploadBlueprint() {
		for (const fileNameAndPartId of this.state.fileNameAndPartId) {
			const file = this.state.selectedFiles.find(file => file.name === fileNameAndPartId.fileName)
			if (file) {
				const data = {
					partId: fileNameAndPartId.partId,
					files: {
						modifiedBlueprint: file,
					},
				}
				this.props.uploadBluePrintOffer(data)
			}
			setTimeout(() => {
				location.reload()
			}, 5000)
		}
		this.setState({
			isBluePrintModalOpen: false,
			selectedFiles: [],
			fileNameAndPartId: [],
		})
	}

	openBluePrintModal() {
		this.setState({
			...this.state,
			isBluePrintModalOpen: true,
		})
	}

	closeBluePrintModal() {
		this.setState({
			...this.state,
			isBluePrintModalOpen: false,
		})
	}

	convertToReadableSize(sizeInBytes) {
		const kilobytes = sizeInBytes / 1024
		const megabytes = kilobytes / 1024

		if (megabytes >= 1) {
			return `${megabytes.toFixed(2)} MB`
		} else {
			return `${kilobytes.toFixed(2)} KB`
		}
	}

	closeModalMessage() {
		history.go(0)
	}

	openModalMessage() {
		this.setState({
			...this.state,
			isModalMessageOpen: true,
		})
	}

	setModalMessage(message) {
		this.setState({
			...this.state,
			modalMessage: message,
		})
	}

	toggleChat() {
		this.setState({
			modalChat: !this.state.modalChat,
		})
	}

	setCustomer(value) {
		this.setState({
			...this.state,
			selectedCustomer: value,
		})
	}

	findTranslatedPart(id) {
		for (const part of this.state.translatedParts) {
			if (part.id.value === id) return part
		}
		return null
	}

	handleAcceptedFiles(files) {
		const fileArrayUpdated = this.pushElements(files)
		this.setState({ selectedFiles: fileArrayUpdated })
		const fileNameAndPartId = []
		files.forEach(file => {
			this.props.offerDetail.offer.parts.forEach(part => {
				if (file.name == part.blueprints[0]?.name) {
					fileNameAndPartId.push({ fileName: file.name, partId: part.id })
				}
			})
		})
		this.setState({
			...this.state,
			fileNameAndPartId: fileNameAndPartId,
		})
	}

	removeFileInState(file) {
		const deletedFileArray = this.state.selectedFiles.filter(fileName => fileName === file.name)
		this.setState({ selectedFiles: deletedFileArray })
	}

	pushElements(files) {
		if (this.state.selectedFiles) {
			this.state.selectedFiles.forEach(file => {
				files.unshift(file)
			})
		}
		return files
	}

	translateParts(offer) {
		const { t, i18n: { language }, partConfigOptions } = this.props

		const translatedParts = offer.parts.map(part => {

			const openChat = () => {
				this.setState({
					...this.state,
					modalChat: true,
					selectedChatPartInfo: part,
				})
			}

			const material = PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })
			const alloy = PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })
			const hardness = PartConfigTranslations.getHardnessName({ part, partConfigOptions, language })
			const finishing = PartConfigTranslations.getFinishName({ part, partConfigOptions, language })
			const palette = PartConfigTranslations.getPaletteName({ part, partConfigOptions, language })
			const color = PartConfigTranslations.getColorName({ part, partConfigOptions, language })
			const colorFinishing = PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language })
			const stlData = {
				partId: part.id,
				partName: part.name,
				size: part.size,
				weight: part.weight,
			}

			const hasChats = part.costsAndMargins.suppliers.some(supplier => supplier.hasSupplierChats)
			const quantityUnread = part.costsAndMargins.suppliers.filter(supplier => supplier.hasSupplierUnreadChats).length
			const newPart = {
				...part,
				costsAndMargins: {
					suppliers: [...part.costsAndMargins.suppliers.sort((a, b) => sortByString(a.name, b.name))],
				},
				id: {
					value: part.id,
				},

				name: <PartName name={part.name} />,
				fileName: part.name,
				weight: <MeasureWeightAlert size={part.size} weight={part.weight} measure="g" />,

				size: <PartSizeAndSimilarity size={part.size} measure="mm" similarParts={part.similarParts} onClick={() => this.openGeometricSimilarityModal([part.id, ...part.similarParts], part.similarity, part.offerId)} />,
				volume: {
					value: part.volume,
					measure: 'cm³',
				},
				area: {
					value: part.area,
					measure: 'cm²',
				},
				boundaryBoxVolume: {
					value: part.boundaryBoxVolume,
					measure: 'cm³',
				},
				chipVolume: {
					value: part.chipVolume,
					measure: 'cm³',
				},
				technology: (
					<div className={getClassnameByTechnology({ part })}>
						{PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language })}
					</div>
				),
				material: [`${material} ${alloy}`, hardness],
				finishing: [finishing, `${palette} ${color}`, colorFinishing],
				process: part.process !== null ? t(`process_${part.process}_name`, { ns: 'process' }) : '',
				status: {
					value: {
						id: part.status,
						text: t(STATUS_OPTIONS[part.status], { ns: 'status' }),
					},
				},
				image: <PartThumbnail stlData={stlData} propsStlUrl={part.fileLinks?.stlModel} propsImageUrl={part?.files?.image} />,
				files: <BlueprintAndStp partId={part.id} propsBlueprintUrl={part.fileLinks?.originalBlueprint} propsModel3dUrl={part.fileLinks?.stepModel} propsModifiedBluePrintUrl={part.fileLinks?.modifiedBlueprint} />,
				note: <NoteTooltip note={part.comment} t={t} />,
				chat: <ChatIconIcon onClick={openChat} quantityUnread={quantityUnread} hasChats={hasChats} />,
				bindingNote: part.bindingNote?.typeIds?.length == 0 ? (
					<div
						className="fs-2 bx bx-plus"
						style={{ cursor: 'pointer' }}
					/>
				) : (
					<div
						className="fs-3 bx bxs-edit"
						style={{ cursor: 'pointer' }}
					/>
				),
			}
			return newPart
		})
		this.setState({
			...this.state,
			translatedParts,
			isTranslating: false,
			language: this.props.i18n.language,
		})
	}

	updateUnreadChats() {
		const translatedParts = this.state.translatedParts.map(part => {
			const hasChats = part.costsAndMargins.suppliers.some(supplier => supplier.hasSupplierChats)
			const quantityUnread = part.costsAndMargins.suppliers.filter(supplier => {
				return supplier.hasSupplierUnreadChats && !this.state.openedChats[part.id.value]?.includes(supplier.id)
			}).length

			const notTranslatedPart = this.props.offerDetail.offer.parts.find(x => x.id == part.id)

			const openChat = () => {
				this.setState({
					...this.state,
					modalChat: true,
					selectedChatPartInfo: notTranslatedPart,
				})
			}

			return {
				...part,
				chat: <ChatIconIcon onClick={openChat} quantityUnread={quantityUnread} hasChats={hasChats} />,
			}
		})
		this.setState({
			...this.state,
			translatedParts,
		})
	}

	updateTranslatedParts(parts) {
		const { t } = this.props

		this.setState({
			...this.state,
			translatedParts: parts.map(part => {
				const { i18n: { language }, partConfigOptions } = this.props
				const newPart = {
					...part,
					technology: PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language }),
					material: PartConfigTranslations.getMaterialName({ part, partConfigOptions, language }),
					alloy: PartConfigTranslations.getAlloyName({ part, partConfigOptions, language }),
					status: {
						value: {
							value: part.status,
							text: t(STATUS_OPTIONS[part.status], { ns: 'status' }),
						},
					},
				}

				return newPart
			}),
		})
	}

	setMargin(value, editableSupplier, quoteIndex) {
		if (!(typeof value === 'number')) return
		const marginModal = { ...this.state.marginModal }

		marginModal.quotes[quoteIndex].margin.value = value
		try {
			marginModal.quotes[quoteIndex].unitSell.value = roundAtDecimals(
				roundAtDecimals(marginModal.quotes[quoteIndex].quotation.value, 2) *
				(Number(value.toString().replace(',', '.'))),
				2,
			)
			marginModal.quotes[quoteIndex].totalSell.value =
				marginModal.quotes[quoteIndex].unitSell.value *
				marginModal.quotes[quoteIndex].quantity
		} catch (e) {
			// ignore catch
		}
		this.setState({
			...this.state,
			marginModal: marginModal,
		})
	}
	setUnitCostByMargin(value, quoteIndex) {
		const REGEXP = new RegExp(/^-?\d*\.?\,?\d*$/)
		if (!REGEXP.test(value)) return

		const { marginModal } = this.state

		marginModal.quotes[quoteIndex].unitSell.value = value
		marginModal.quotes[quoteIndex].totalSell.value =
			value * marginModal.quotes[quoteIndex].quantity
		try {
			marginModal.quotes[quoteIndex].margin.value = roundAtDecimals(
				(Number(roundAtDecimals(value.toString().replace(',', '.'), 2)) /
					marginModal.quotes[quoteIndex].quotation.value), 2)
		} catch (e) {
			// ignore
		}
		this.setState({
			...this.state,
			marginModal: marginModal,
		})
	}
	openEditMarginsModal(partId, selectedSupplier) {
		const supplier = { ...selectedSupplier }
		this.setState({
			...this.state,
			isEditMarginsModalOpen: true,
			editableSupplier: supplier,
			marginModal: {
				partId: partId,
				supplierId: supplier.id,
				quotes:
					supplier &&
					supplier.quotes &&
					supplier.quotes.map(quote => {
						return {
							quantity: quote.quantity && quote.quantity,
							quotation: {
								value: quote.quotation && quote.quotation,
								measure: '€',
							},
							margin: {
								value: quote.margin && quote.margin,
								measure: '%',
							},
							unitSell: {
								value: quote.unitSell && quote.unitSell,
								measure: '€',
							},
							totalSell: { value: null, measure: '€' },
						}
					}),
				quoteId: supplier.quoteId,
			},
		})
	}
	saveAndCloseMarginsModal(partId, supplierId, quotes) {
		quotes.forEach(quote => {
			quote.margin.value = Number(quote.margin.value)
			quote.unitSell.value = Number(quote.unitSell.value)
			quote.totalSell.value = Number(quote.totalSell.value)
		})

		const partToSave = this.state.translatedParts.find(
			part => part.id.value === partId,
		)
		const supplierToSave = partToSave.costsAndMargins.suppliers.find(
			supplier => supplier.id === supplierId,
		)
		const newQuotes = supplierToSave.quotes.map((quote, index) => {

			return {
				...quote,
				margin: quotes[index].margin.value,
				unitSell: quotes[index].unitSell.value,
				totalSell: quotes[index].totalSell.value,
			}
		})
		supplierToSave.quotes = newQuotes
		partToSave.costsAndMargins.suppliers =
			partToSave.costsAndMargins.suppliers.map(supplier => {
				if (supplier.id === supplierToSave.id) return supplierToSave
				return supplier
			})

		this.props.updateQuoteMargin(newQuotes)

		this.setState(
			{
				...this.state,
				translatedParts: this.state.translatedParts.map(part => {
					if (part.id.value === partToSave.id.value) return partToSave
					return part
				}),
				showTable: false,
			},
			() => this.closeEditMarginsModal(),
		)
	}
	closeEditMarginsModal() {
		this.setState({
			...this.state,
			isEditMarginsModalOpen: false,
			showTable: true,
			//editableSupplier: null
		})
	}
	openQuotationModal() {
		this.setState({
			...this.state,
			isQuotationModalOpen: true,
		})
	}
	closeQuotationModal() {
		this.setState({
			...this.state,
			isQuotationModalOpen: false,
		})
	}

	closeCreateQuotationResultModal() {
		history.go(0)
	}

	openChatModal() {
		this.setState({
			...this.state,
			modalChat: true,
		})
	}
	openOrderSimulationModal() {
		this.setState({
			...this.state,
			isOrderSimulationModalOpen: true,
		})
	}
	closeOrderSimulationModal() {
		this.setState({
			...this.state,
			isOrderSimulationModalOpen: false,
		})
	}

	closeSTLViewerModal() {
		this.setState({
			...this.state,
			isSTLViewerModalOpen: false,
			stlViewerData: {
				modelSrc: '',
				partName: '',
				partId: null,
				size: null,
				weight: null,
			},
		})
		history.go(0)
	}
	openEditCustomerModal() {
		this.setState({
			...this.state,
			isEditCustomerModalOpen: true,
		})
	}
	closeEditCustomerModal() {
		this.setState({
			...this.state,
			isEditCustomerModalOpen: false,
		})
	}

	openUploadModal() {
		this.setState({
			...this.state,
			isUploadModalOpen: true,
		})
	}
	closeUploadModal() {
		this.setState({
			...this.state,
			isUploadModalOpen: false,
		})
	}
	saveAndCloseUploadModal() {
		const data = this.state.selectedRows.map(selectedRow => {
			const supplierSelectedTab = this.getSelectedTabSupplier(selectedRow.id.value)
			const quoteId = selectedRow.costsAndMargins.suppliers.find(supplier => supplier.id === supplierSelectedTab.id).quoteId
			return {
				partId: selectedRow.id.value && parseInt(selectedRow.id.value),
				selectedQuoteId: quoteId,
				partPrices: supplierSelectedTab.quotes.map(quote => {
					return {
						quantity: quote.quantity,
						price: quote.unitSell,
					}
				}),
			}
		})
		this.props.uploadOffer(data)
		this.closeUploadModal()
	}

	hasAllSelectedRowsHaveAValidSupplier() {
		for (const selectedRow of this.state.selectedRows) {
			const supplierTabed = this.getSelectedTabSupplier(selectedRow.id.value)
			const quote = selectedRow.costsAndMargins.suppliers.find(supplier => supplier.id === supplierTabed.id)
			if (!quote) return false
			if (quote.isIA) return false
			if (quote?.statusId !== QUOTED_STATUS) return false
		}
		return true
	}

	componentDidMount() {
		this.setState({
			...this.state,
			language: this.props.i18n.language,
		})
		const offerId = GetOfferIdFromURL()
		this.props.getOfferDetail(offerId)
		this.props.getCustomers()
		if (this.props.managers?.length === 0) this.props.getManagers()
		if (this.props.suppliers?.length === 0) this.props.getSuppliers()
		if (this.props.partConfigOptions == null) this.props.getPartConfigOptions()
	}

	componentDidUpdate(prevProps, prevState) {
		// Esto causaba un loop infinito por eso lo he comentado...
		// if (this.props.i18n.language !== this.state.language) {
		//   history.go(0);
		// }
		if (this.props.offerDetail !== prevProps.offerDetail) {
			const offer = this.props.offerDetail.offer
			if (!this.state.areExpandRowControlsInitialized) {
				this.initializeExpandRowControls(offer)
				//this.request3dIaQuotations(); TODO review functionality
				this.quote3dIaQuotations()
			} else {
				this.updateTranslatedParts(offer.parts)
			}
		}
		if (this.props.uploadingOfferError !== prevProps.uploadingOfferError) {
			if (this.props.uploadingOfferError === SUCCESS_UPLOAD_RESPONSE) {
				this.openSendUploadEmailModal()
			}
		}
		if (this.state.modalMessage !== prevState.modalMessage && this.state.modalMessage !== '') {
			this.openModalMessage()
		}
		if (!this.props.isUpdatingOfferCustomer && this.props.isUpdatingOfferCustomer !== prevProps.isUpdatingOfferCustomer && !this.props.updateOfferCustomerError) {
			history.go(0)
		} else if (!this.props.isUpdatingOfferCustomer && this.props.isUpdatingOfferCustomer !== prevProps.isUpdatingOfferCustomer && this.props.updateOfferCustomerError) {
			alert('Error updating offer\'s customer')
		}
		if (this.props.isUpdatingMarginsResponse !== prevProps.isUpdatingMarginsResponse) {
			if (this.props.isUpdatingMarginsResponse !== 'success') {
				this.setModalMessage(this.props.t('error', { ns: 'naming' }))
			}
		}
		if (this.props.uploadingBlueprintSuccess && !prevProps.uploadingBlueprintSuccess) this.closeBluePrintModal()
		if (prevState.translatedParts !== this.state.translatedParts && !this.state.iaQuotationsCalculated) {
			this.setState({
				translatedParts: this.state.translatedParts.map((part) => {
					const quantities = this.findIa3dSupplier(part)?.quotes.map(quote => quote.quantity)
					if (quantities != null) {
						return {
							...part,
							iaQuotations: this.getIApredictionsTableItems(part, quantities),
						}
					}
					return { ...part }
				}),
				iaQuotationsCalculated: true,
			})
		}
		if (this.props.blueprints !== prevProps.blueprints) {
			this.setState({
				...this.state,
				downloadedBlueprints: [...this.state.downloadedBlueprints, this.props.blueprints[0]],
			})
		}
		if (this.state.selectedRows.length !== 0 && this.state.selectedRows.length == this.state.downloadedBlueprints.length) {
			this.downloadBlueprintsZip(this.state.downloadedBlueprints)
		}
		if (this.props.isQuoteUpdatedSuccessfully != prevProps.isQuoteUpdatedSuccessfully && this.props.isQuoteUpdatedSuccessfully) {
			this.setModalMessage(this.props.t('ia_quoted_successfully', { ns: 'naming' }))
		}
		if (this.props.errorCloud != prevProps.errorCloud && this.props.errorCloud != null) {
			this.closeDeleteModifiedBlueprintsModal()
			this.setModalMessage(this.props.errorCloud)
		}
		if (this.props.hasClonedSuccessfully != prevProps.hasClonedSuccessfully) {
			this.handleOnChangeCloneQuoteModal(false)
			this.setModalMessage(this.props.t('clonation_success', { ns: 'naming' }))
		}
		if (this.props.errorQuotes != prevProps.errorQuotes && this.props.errorQuotes != null) {
			this.setModalMessage(this.props.errorQuotes)
		}
		if (this.state.openedChats != prevState.openedChats && this.state.translatedParts.length > 0) {
			this.updateUnreadChats()
		}
	}

	assignFileToPart(partId, file) {
		const partExists = this.state.fileNameAndPartId.find(part => part.partId === partId)
		if (!partExists) {
			this.setState({
				...this.state,
				fileNameAndPartId: [...this.state.fileNameAndPartId, { partId: partId, fileName: file }],
			})
		}
		else {
			const newFileNameAndPartId = this.state.fileNameAndPartId.map(part => {
				if (part.partId === partId) {
					return { partId: partId, fileName: file }
				}
				else return part
			})
			this.setState({
				...this.state,
				fileNameAndPartId: newFileNameAndPartId,
			})
		}
	}

	async downloadBlueprintsZip(blueprints) {
		if (blueprints && blueprints.length > 0) {
			const zip = new JSZip()
			const fetchPromises = []
			try {
				for (let index = 0; index < blueprints.length; index++) {
					const blueprintactual = blueprints[index]
					for (let index2 = 0; index2 < blueprintactual.length; index2++) {
						const blueprint = blueprintactual[index2]
						if (blueprint && blueprint.src) {
							const fetchPromise = fetch(blueprint.src)
								.then(response => response.blob())
								.then(blob => zip.file(`${blueprint?.name}`, blob))
								.catch(error => {
									console.error('Error fetching blueprint:', error)
								})
							fetchPromises.push(fetchPromise)
						}
					}
				}
				await Promise.all(fetchPromises)
				const zipContent = await zip.generateAsync({ type: 'blob' })
				const zipURL = URL.createObjectURL(zipContent)
				const link = document.createElement('a')
				link.href = zipURL
				link.download = `Blueprints_${this.props.offerDetail.offer.id}.zip`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setTimeout(() => {
					URL.revokeObjectURL(zipURL)
				}, 100)
				this.setState({
					...this.state,
					downloadedBlueprints: [],
				})
			} catch (error) {
				console.error('Error:', error)
			}
		}
	}

	async downloadBluePrint() {
		const { t } = this.props
		const { selectedRows } = this.state
		if (selectedRows.length === 0) {
			alert(t('no_selected_pieces', { ns: 'naming' }))
			return
		} else {
			const partId = this.state.selectedRows.map(selectedRow => {
				return selectedRow.id.value
			})
			partId.forEach(async (partId) => {
				const data = {
					partIds: [partId],
				}
				await this.props.downloadBluePrintOffer(data)
			})
		}
	}

	getQuotesPartsIds() {
		if (this.props.offerDetail.offer.parts && this.props.offerDetail.offer.parts !== 0) {
			return this.props.offerDetail.offer.parts.map(part => part.id)
		}
	}

	initializeExpandRowControls(offer) {
		const parts = offer && offer.parts

		const expandRowControls =
			parts &&
			parts.map(part => {
				let publishedSupplier = -1
				let bestOfferSupplier = -1

				part.costsAndMargins &&
					part.costsAndMargins.suppliers.forEach((supplier, index) => {
						if (supplier.isPublished) publishedSupplier = index
						if (supplier.isBestOffer) bestOfferSupplier = index
					})
				let selectedSupplier
				if (publishedSupplier != -1) selectedSupplier = publishedSupplier
				else if (bestOfferSupplier != -1) selectedSupplier = bestOfferSupplier

				return GetExpandRowControl(part.id, selectedSupplier || 0)
			})

		offer &&
			this.setState(
				{
					expandRowControls,
					areExpandRowControlsInitialized: true,
					isTranslating: true,
				},
				() => {
					this.translateParts(offer)
				},
			)
	}

	handleShowExtraInformation(id) {
		const expandRowControls = [...this.state.expandRowControls]
		const expandRowControl = expandRowControls.find(
			expandRowControl => expandRowControl.id === id,
		)
		if (expandRowControl != null)
			expandRowControl.isShowingExtraData =
				!expandRowControl.isShowingExtraData
		else console.error('expandRowControl.isShowingExtraData not found')
		this.setState({ expandRowControls })
	}

	handleShowCalculations(id) {
		const part = this.props.offerDetail.offer.parts.find(part => part.id === id)
		const expandRowControls = [...this.state.expandRowControls]
		const expandRowControl = expandRowControls.find(expandRowControl => expandRowControl.id === id)

		if (expandRowControl)
			expandRowControl.isShowingCalculations =
				!expandRowControl.isShowingCalculations
		else console.error('expandRowControl.isShowingCalculations not found')
		this.setState({ expandRowControls })
		if (part?.costsAndMargins?.suppliers[0]?.statusId > REQUESTED_STATUS) {
			this.getPartRegressions(id, part.costsAndMargins.suppliers[0].id)
		}
	}

	findExpandRowControlsById(id) {
		return this.state.expandRowControls.find(
			expandRowControl => expandRowControl.id === id,
		)
	}

	toggleSupplierOnPart(id, selectedSupplier) {
		const expandRowControls = [...this.state.expandRowControls]
		const expandRowControl = expandRowControls.find(
			expandRowControl => expandRowControl.id === id,
		)

		expandRowControl.selectedSupplier = selectedSupplier
		expandRowControl.isShowingCalculations = false

		this.state.translatedParts.forEach(translatedPart => {
			if (translatedPart.id.value === id) {
				const suppliers = translatedPart.costsAndMargins?.suppliers.map(
					(supplier, index) => {
						supplier.isSelected = index === selectedSupplier
						return supplier
					},
				)
				translatedPart.costsAndMargins.suppliers = suppliers
			}
			return translatedPart
		})
		this.setState({ expandRowControls }, () => {
			expandRowControl.isShowingCalculations = true
			this.setState({ expandRowControls })
		})

		const selectedTabQuote = this.state.translatedParts.find((part) => part.id.value === id).costsAndMargins.suppliers[selectedSupplier]
		if (selectedTabQuote && selectedTabQuote.statusId > REQUESTED_STATUS) {
			this.getPartRegressions(id, selectedTabQuote.id)
		}
	}

	onSelectTableItem(row, isSelected) {
		let { selectedRows } = this.state
		if (isSelected) {
			selectedRows = [...selectedRows, row]
		} else {
			const newSelectedRows = []
			selectedRows.forEach(selectedRow => {
				if (selectedRow !== row) newSelectedRows.push(selectedRow)
			})
			selectedRows = newSelectedRows
		}

		this.setState({
			...this.state,
			selectedRows,
		})
	}

	getPartRegressions(partId, supplierId) {
		if (!this.props.partCosts.find((cost) => cost.partId === partId && cost.supplierId === supplierId)) {
			this.props.getPartCostsBySupplierId(partId, supplierId)
			this.props.getPartPricesBySupplierId(partId, supplierId)
		}
	}

	onSelectAllTableItems(isSelected, rowIndex) {
		if (isSelected) this.setState({ ...this.state, selectedRows: rowIndex })
		else this.setState({ ...this.state, selectedRows: [] })
	}

	onChangeManager(e) {
		this.setState({
			...this.state,
			quotationModalData: {
				...this.state.quotationModalData,
				manager: Number(e.target.value),
			},
		})
	}
	onChangeSupplier(e) {
		this.setState({
			...this.state,
			quotationModalData: {
				...this.state.quotationModalData,
				suppliers: e,
			},
		})
	}

	onChangeUrgent(e) {
		this.setState({
			...this.state,
			quotationModalData: {
				...this.state.quotationModalData,
				isUrgent: e.target.checked,
			},
		})
	}

	onChangeComment(data) {
		this.setState({
			...this.state,
			quotationModalData: {
				...this.state.quotationModalData,
				comment: data,
			},
		})
	}

	onChangeAutomatic(e) {
		this.setState({
			...this.state,
			quotationModalData: {
				...this.state.quotationModalData,
				isAutomatic: e.target.checked,
			},
		})
	}

	parseManagers(managers) {
		return managers.map(manager => {
			return {
				value: manager.id,
				label:
					manager?.personalInformation.firstName +
					' ' +
					manager?.personalInformation?.lastName +
					' (' +
					manager.loginCredentials.email +
					')',
			}
		})
	}

	parseSuppliers(suppliers) {
		return suppliers.map(supplier => {
			return {
				value: supplier.id,
				label: supplier.personalInformation.firstName,
			}
		})
	}

	submitQuote() {
		const input = {
			parts: this.state.selectedRows.map(part => ({
				id: parseInt(part.id.value),
				statusId: part.status.value.id,
				quantities: part.quantities,
			})),
			suppliers: this.state.quotationModalData.suppliers.map(supplier => {
				const supplierFound = this.props.suppliers.find(s => s.id === parseInt(supplier.value))
				return {
					id: parseInt(supplier.value),
					name: supplierFound.personalInformation.firstName,
					email: supplierFound.loginCredentials.email,
				}
			}),
			offerId: parseInt(GetOfferIdFromURL()),
			managerId: parseInt(this.state.quotationModalData.manager),
			comment: this.state.quotationModalData.comment,
			isUrgent: this.state.quotationModalData.isUrgent,
			isAutomatic: this.state.quotationModalData.isAutomatic,
		}
		this.props.createQuote({ data: { ...input } })
	}

	updateOfferCustomer() {
		this.props.updateOfferCustomer({
			offer: {
				id: parseInt(GetOfferIdFromURL()),
				accountId: parseInt(this.state.selectedCustomer),
			},
		})
	}

	getIApredictionsTableItems(part, quantites) {
		if (quantites == null) quantites = [1, 4, 10]
		return quantites.map((quantity) => {
			return {
				quantity: quantity,
				quotation: parseFloat(getQuotePrice(part, Number(quantity))).toFixed(2),
				time: getQuoteTime(part, Number(quantity)),
			}
		})
	}

	parsePartPrediction(prediction) {
		return {
			quantity: prediction.quantity,
			unitCost: `${prediction.quotation.value} ${prediction.quotation.measure}`,
			totalCost: `${prediction.quantity * prediction.quotation.value} ${prediction.quotation.measure
				}`,
		}
	}

	isIASupplier(row, expandRowControl) {
		return (
			expandRowControl &&
			typeof expandRowControl.selectedSupplier === 'number' &&
			row.costsAndMargins &&
			row.costsAndMargins.suppliers[expandRowControl.selectedSupplier]?.isIA
		)
	}
	getIAHeader() {
		return {
			quantity: 'quantity',
			unitCost: 'unitCost',
			totalCost: 'totalCost',
		}
	}

	openCostsModal(partId, suppliers) {
		const selectedSupplier = this.state.expandRowControls.find(part => part.id === partId)?.selectedSupplier || 0
		const supplier = suppliers[selectedSupplier]
		this.setState({
			...this.state,
			costsModal: {
				isOpen: true,
				partId,
				supplier,
			},
		})
	}

	closeCostsModal() {
		this.setState({
			...this.state,
			costsModal: {
				isOpen: false,
				partId: null,
				supplierId: null,
			},
		})
	}

	mapQuantities(quantites) {
		if (quantites == null || quantites.length === 0) return
		return quantites.map((quantity) => {
			return {
				quantity,
			}
		}).sort((a, b) => a.quantity - b.quantity)
	}

	mapQuantitiesAndPredictions(row) {
		const { quantities } = row
		if (quantities == null || quantities.length === 0) return
		return quantities.map((quantity) => {
			return {
				quantity,
				aiPrediction: (<AIPrediction part={row} quantity={quantity} />),
			}
		}).sort((a, b) => a.quantity - b.quantity)
	}

	findSelectedSupplier(suppliers) {
		return suppliers.find(supplier => supplier.isSelected) || null
	}

	hasToShowVcCosts(suppliers, partId) {
		const selectedSupplier = this.findSelectedSupplier(suppliers)
		if (this.props.partCosts?.length <= 0) return false

		const partCost = this.props.partCosts?.filter((partCost) => {
			return (
				partCost.partId === partId &&
				partCost.supplierId === selectedSupplier.id
			)
		})
		return partCost.length > 0
	}

	isQuoted(quote) {
		return quote?.statusId === QUOTED_STATUS
	}

	openEditStatusModal() {
		this.setState({
			...this.state,
			isEditStatusModalOpen: true,
		})
	}

	closeEditStatusModal() {
		this.setState({
			...this.state,
			isEditStatusModalOpen: false,
		})
	}

	handleOnChangeChatSelectedPart(partId) {
		const part = this.props.offerDetail?.offer?.parts?.find(part => part.id === partId)
		this.setState({
			...this.state,
			selectedChatPartInfo: part,
		})
	}

	openEditQuantitiesModal() {
		this.setState({
			...this.state,
			isEditQuantitiesModalOpen: true,
		})
	}
	closeEditQuantitiesModal() {
		this.setState({
			...this.state,
			isEditQuantitiesModalOpen: false,
		})
	}

	mapCosts(costs) {
		return costs.map((cost) => {
			const quote = this.getQuoteInTranslatedPartsById(cost.quoteId)
			if (quote == null || quote.ppm == null || quote.mpm == null || cost.modelPrice == null || cost.moldPrice == null) {
				return cost
			}
			return {
				...cost,
				quotation: this.calculateVacumCastingUnitCost(quote.ppm, quote.mpm, cost.moldPrice, cost.modelPrice, cost.quantity, cost.quotation),
			}
		})
	}

	getQuoteInTranslatedPartsById(id) {
		for (const part of this.state.translatedParts) {
			const quote = part?.costsAndMargins?.suppliers?.find(supplier => supplier.quoteId === id)
			if (quote != null) return quote
		}
		return null
	}

	calculateVacumCastingUnitCost(ppm, mpm, moldPrice, masterPrice, quantity, quotation) {
		const moldQuantity = Math.ceil(quantity / ppm)
		const unitaryMoldCost = (moldQuantity * moldPrice) / quantity
		const unitaryMasterCost = (Math.ceil(moldQuantity / mpm) * masterPrice) / quantity
		return roundCurrency(quotation + unitaryMoldCost + unitaryMasterCost)
	}
	//TODO review automatic request functionality
	/* request3dIaQuotations() {
	  const partIds = this.props.offerDetail?.offer?.parts?.map(part => {
		const partHave3dIaRequested = part.costsAndMargins.suppliers.find(supplier => supplier.id === supplier3dIaId) != null;
		if (part.technology.id === technology3dIaId && !partHave3dIaRequested) {
		  return {
			part_id: part.id,
			part_status: 30,
			supplier_id: supplier3dIaId,
			manager_id: manager3dIaId,
			comment: null,
			isUrgent: false,
		  }
		}
	  }).filter(part => part != null);
  
	  if (partIds?.length > 0) {
		this.props.createQuote({ data: partIds });
	  } else {
		this.quote3dIaQuotations()
	  }
	} */

	quote3dIaQuotations() {
		const parts = this.props.offerDetail.offer.parts.filter(part => {
			const ia3dSupplier = this.findIa3dSupplier(part)
			return ia3dSupplier != null && ia3dSupplier.statusId === REQUESTED_STATUS
		})
		if (parts.length === 0) return
		const iaQuotations = parts.map(part => {
			const quantities = this.findIa3dSupplier(part).quotes.map(quote => quote.quantity)
			return {
				partId: part.id,
				quoteId: this.findIa3dSupplier(part).quoteId,
				statusId: 30,
				quantities: this.getIApredictionsTableItems(part, quantities),
				constructionTypeId: 1,
				processId: part.process,
				technologyId: part.technology.id,
			}
		})
		this.setState({ iaQuotationsCalculated: true })
		this.props.saveAndConfirmQuote({ data: { quotes: iaQuotations } })

	}

	findIa3dSupplier(part) {
		return part.costsAndMargins.suppliers.find(supplier => supplier.id === parseInt(process.env.REACT_APP_IA_3D_SUPPLIER_ID))
	}

	openSendUploadEmailModal() {
		this.setState({
			...this.state,
			isSendUploadEmailModalOpen: true,
		})
	}

	closeSendUploadEmailModal() {
		if (this.props.uploadingOfferError === SUCCESS_UPLOAD_RESPONSE) history.go(0)
		this.setState({
			...this.state,
			isSendUploadEmailModalOpen: false,
		})
	}

	openEditConfigurationModal() {
		this.setState({
			...this.state,
			isEditConfigurationModalOpen: true,
		})
	}

	closeEditConfigurationModal() {
		this.setState({
			...this.state,
			isEditConfigurationModalOpen: false,
		})
	}

	openGeometricSimilarityModal(similarPartsIds, similarity, offerId) {
		this.setState({
			...this.state,
			similarPartsIds,
			similarity,
			isGeometricSimilarityModalOpen: true,
			geometricSimilarityModalOfferId: offerId,
		})
	}

	closeGeometricSimilarityModal() {
		this.setState({
			...this.state,
			similarPartsIds: null,
			similarity: null,
			isGeometricSimilarityModalOpen: false,
			geometricSimilarityModalOfferId: null,
		})
	}

	openEditBindingNoteModal({ partIds, rejectPartsAfterEditingBindingNote } = {}) {
		const ids = partIds || this.state.selectedRows.map(row => row.id.value)
		this.setState({
			...this.state,
			isEditBindingNoteModalOpen: true,
			selectedPartsForEditBindingNoteModal: this.props.offerDetail.offer.parts.filter(x => ids.includes(x.id)),
			isEditStatusModalOpen: false,
			editBindingModal_rejectPartsAfterEditingBindingNote: rejectPartsAfterEditingBindingNote,
		})
	}

	closeEditBindingNoteModal() {
		this.setState({
			...this.state,
			isEditBindingNoteModalOpen: false,
		})
	}
	//#endregion functions

	render() {

		const { t } = this.props
		const {
			offer,
			header,
			extraInfoTableHeader,
			costsAndMarginsHeader,
			costsAndMarginsSupplierHeader,
			account,
		} = this.props.offerDetail

		const hasAllSelectedRowsHaveAValidSupplier = this.hasAllSelectedRowsHaveAValidSupplier()
		return (
			<React.Fragment>
				<div className="page-content marginFooter">
					<MetaTags>
						<title>Proto&Go! | {t('offerDetail', { ns: 'naming' })}</title>
					</MetaTags>
					<Breadcrumbs
						title={t('management', { ns: 'naming' })}
						breadcrumbItems={[
							{
								item: t('offerList', { ns: 'naming' }),
								link: '/offer-list',
							},
							{
								item: t('offerDetail', { ns: 'naming' }),
								link: '/offer/' + GetOfferIdFromURL(),
							},
						]}
					/>
					{(this.props.isLoading || this.props.isUploadingOffer) && (
						<Loader className="font-size-80 mx-auto" />
					)}
					{!this.props.isLoading && !offer && (
						<Alert
							type="danger"
							centered
							fitContent
							message={t('unknownMessage', { ns: 'errors' })}
						/>
					)}
					{!this.props.isLoading &&
						offer &&
						this.state.translatedParts.length > 0 && (
							<span>
								<div className="mx-1 bg-white p-3 my-2 d-flex flex-row align-items-center">
									<h1 className="m-0 p-0">
										{t('offer', { ns: 'naming' })} {offer.id}
									</h1>
									<h5 className="mt-2 mx-2 px-2" style={{ display: 'flex', gap: '0.5em' }}>
										<span className={GetStatusClass(offer.status) + ' m-0'}>
											{t(`offer_status_${offer.status}`, { ns: 'status' })}
										</span>
										<PricesSeenAlert offer={offer} t={t} />
									</h5>
								</div>
								<Row className="mx-1">
									<Col className="col-7 bg-white p-2 me-2">
										<OfferTab offer={offer} />
									</Col>
									<Col className="col lead bg-white p-3">
										<span className="d-block">
											<i className="bx bxs-calendar"></i>
											{adaptWeekday(
												offer.date.value.getDay(),
												this.props.i18n.language,
											) + ' '}
											{adaptDateOnLocale(
												offer.date.value.getFullYear(),
												offer.date.value.getMonth(),
												offer.date.value.getDate(),
												this.props.i18n.language,
											) + ' '}
											{adaptTimerString(
												offer.date.value.getHours(),
												offer.date.value.getMinutes(),
												offer.date.value.getSeconds(),
											)}
										</span>
										<span className="d-block">
											<i className="bx bxs-factory"></i>
											{offer.customer.value.title}
										</span>
										<span className="d-block">
											<i className="bx bxs-user"></i>
											<a
												href={`${appRoutes.CRM_USER_DETAIL_ROUTE_NO_ID}${offer.customer.value.organizationId}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{offer.customer.value.crmLink}
											</a>
										</span>
										<span className="d-block">
											<i className="bx bxs-envelope"></i>
											<a href={'mailto:' + offer.customer.value.email}>
												{offer.customer.value.email}
											</a>
											&nbsp;
											<span onClick={() => this.openEditCustomerModal()}>
												<TooltipMessage
													className="bxs-edit"
													message={t('editOfferCustomer', { ns: 'naming' })}
													onClick={() => this.openEditCustomerModal()}
												/>
											</span>

										</span>
										<span className="d-block">
											<i className="bx bx-world"></i>
											<Flag name={account.personalInformation.country?.toLowerCase()} />
											<i className="bx bx-message-rounded"></i>
											<Flag name={account.personalInformation.language?.toLowerCase()} />
										</span>
									</Col>
								</Row>
								<Row className="bg-white mx-1 p-3 mt-2">
									<Col>
										<OfferTopMenu
											openQuotationModal={this.openQuotationModal}
											openUploadModal={this.openUploadModal}
											openOrderSimulationModal={this.openOrderSimulationModal}
											openEditStatusModal={this.openEditStatusModal}
											openBluePrintModal={this.openBluePrintModal}
											downloadBluePrint={this.downloadBluePrint}
											openEditQuantitiesModal={this.openEditQuantitiesModal}
											openSendUploadEmailModal={this.openSendUploadEmailModal}
											openEditConfigurationModal={this.openEditConfigurationModal}
											openDeleteModifiedBlueprintsModal={this.openDeleteModifiedBlueprintsModal}
											handleOnChangeCloneQuoteModal={this.handleOnChangeCloneQuoteModal}
											openEditBindingNoteModal={this.openEditBindingNoteModal}
										/>
										{this.state.showTable && this.state.iaQuotationsCalculated == true && (
											<Table
												disableStriped
												header={header}
												paginationProps={{
													custom: true,
													sizePerPage: 200,
												}}
												disableInteractions
												items={this.state.translatedParts}
												selectRow={{
													mode: 'checkbox',
													hideSelectColumn: false,
													onSelectAll: (isSelected, rows, e) => {
														this.onSelectAllTableItems(isSelected, rows, e)
													},
													onSelect: (row, isSelected, rowIndex, e) => {
														this.onSelectTableItem(row, isSelected, rowIndex, e)
													},
												}}
												disableSorting
												language="es"
												rowQuantityList={[{ text: '32', value: 5 }]}
												allowAllItemsPerPage
												defaultSort={{
													dataField: 'id',
													order: 'desc',
												}}
												rowActions={[
													{
														label: 'id',
														action: id => this.handleShowExtraInformation(id.value),
														findExpandRowControlsById: id => this.findExpandRowControlsById(id.value),
													},
													{
														label: 'status',
														action: id => this.handleShowCalculations(id.value),
													},
													{
														label: 'bindingNote',
														action: (id) => {
															this.openEditBindingNoteModal({ partIds: [id.value] })
														},
													},
												]}

												styleForFieldList={[
													{
														field: 'status',
														styles: [
															{
																type: 'grayBg',
																values: ['10'],
																class: 'cursor-pointer rounded-pill bg-secondary text-white',
															},
															{
																type: 'yellowBg',
																values: ['20'],
																class: 'cursor-pointer rounded-pill bg-yellow text-white',
															},
															{
																type: 'orangeBg',
																values: ['30'],
																class: 'cursor-pointer rounded-pill bg-orange text-white',
															},
															{
																type: 'greenBg',
																values: ['40'],
																class: 'cursor-pointer rounded-pill bg-green text-white',
															},

															{
																type: 'redBg',
																values: ['91', '92'],
																class: 'cursor-pointer rounded-pill bg-red text-white',
															},
														],
													},
													{
														field: 'id',
														styles: [
															{
																type: 'rowExpander',
															},
														],
													},
													{
														field: 'pieces',
														styles: [
															{
																type: 'grayBg',
																class: 'rounded-pill bg-secondary text-white',
															},
														],
													},
													{
														field: 'weight',
														styles: [
															{
																type: 'measure',
																class: '',
															},
														],
													},
													{
														field: 'modelFiles',
														styles: [
															{
																type: '3DFiles',
																class: '',
															},
														],
													},
													{
														field: 'blueprints',
														styles: [
															{
																type: 'blueprints',
																class: '',
															},
														],
													},
													{
														field: 'material',
														styles: [
															{
																type: 'list',
																class: 'd-block',
															},
														],
													},
													{
														field: 'finishing',
														styles: [
															{
																type: 'list',
																class: 'd-block',
															},
														],
													},
													{
														field: 'comment',
														styles: [
															{
																'type': 'tooltip',
																'class': 'bx-paperclip fs-2',
															},
														],
													},
												]}
												expandRow={{
													renderer: (row, i) => {
														const expandRowControl = row.id.findExpandRowControlsById(row.id)
														return (
															<div>
																{expandRowControl &&
																	expandRowControl.isShowingExtraData && (
																		<Table
																			className=""
																			disableInteractions
																			disableSorting
																			items={[
																				{
																					volume: row.volume,
																					area: row.area,
																					faces: row.faces,
																					boundaryBoxVolume: row.boundaryBoxVolume,
																					chipVolume: row.chipVolume,
																					process: row.process,
																					tolerances: row.tolerances,
																					similarity: row.similarity,
																				},
																			]}
																			selectRow={{
																				mode: 'checkbox',
																				hideSelectColumn: true,
																			}}
																			header={extraInfoTableHeader}
																			styleForFieldList={[
																				{
																					field: 'volume',
																					styles: [
																						{
																							type: 'measure',
																							class: '',
																						},
																					],
																				},
																				{
																					field: 'area',
																					styles: [
																						{
																							type: 'measure',
																							class: '',
																						},
																					],
																				},
																				{
																					field: 'boundaryBoxVolume',
																					styles: [
																						{
																							type: 'measure',
																							class: '',
																						},
																					],
																				},
																				{
																					field: 'chipVolume',
																					styles: [
																						{
																							type: 'measure',
																							class: '',
																						},
																					],
																				},
																			]}
																		/>
																	)}
																{expandRowControl &&
																	expandRowControl.isShowingCalculations && (
																		<Row>
																			<Col className="col-8">
																				<div className="informative-header d-flex flex-row justify-content-between">
																					<span>
																						<strong>
																							{t('costsAndMarginsCalculations', {
																								ns: 'naming',
																							})}
																						</strong>
																					</span>
																					{row.costsAndMargins &&
																						(
																							<div>
																								{this.isQuoted(row.costsAndMargins.suppliers[expandRowControl.selectedSupplier]) ?
																									(<div className="d-flex">
																										<span className=" mx-2 rounded-pill bg-yellow text-white cursor-pointer" onClick={() => this.openCostsModal(row.id.value, row.costsAndMargins.suppliers)}>
																											<i className="bx bx-line-chart"></i> {t('view_regressions', { ns: 'naming' })}
																										</span>
																										<span
																											className="float-right rounded-pill bg-pink text-white cursor-pointer"
																											onClick={() =>
																												this.openEditMarginsModal(
																													row.id,
																													row.costsAndMargins &&
																													row.costsAndMargins.suppliers[
																													expandRowControl
																														.selectedSupplier
																													],
																												)
																											}
																										>
																											<i className="bx bxs-edit"></i>{' '}
																											{t('editMargins', { ns: 'naming' })}
																										</span>
																									</div>) :
																									(
																										<div>{row.costsAndMargins?.suppliers[expandRowControl.selectedSupplier]?.statusId &&
																											<span className={getStatusClassName(row.costsAndMargins?.suppliers[expandRowControl.selectedSupplier]?.statusId)}>{t('quote_status_' + row.costsAndMargins?.suppliers[expandRowControl.selectedSupplier]?.statusId, { ns: 'status' })}</span>
																										}
																										</div>
																									)

																								}
																							</div>)}
																				</div>
																				{(
																					<Table
																						selectRow={{
																							mode: 'checkbox',
																							hideSelectColumn: true,
																						}}
																						header={
																							this.isIASupplier(row, expandRowControl) &&
																								(row.configuration.technologyTypeId != IMPRESION3D_ID)
																								? null
																								: this.isIASupplier(row, expandRowControl) &&
																									(row.configuration.technologyTypeId == IMPRESION3D_ID)
																									? this.getIAHeader()
																									: costsAndMarginsHeader
																						}
																						disableInteractions
																						disableSorting
																						items={row.status.value.id === NEW_STATUS ? this.mapQuantitiesAndPredictions(row) :
																							this.isIASupplier(row, expandRowControl) &&
																								row.configuration.technologyTypeId != IMPRESION3D_ID
																								? null
																								: this.isIASupplier(row, expandRowControl) &&
																									row.configuration.technologyTypeId == IMPRESION3D_ID
																									? [row.iaQuotations]
																									: row.costsAndMargins.suppliers[expandRowControl.selectedSupplier]?.quotes.map(quote => {

																										return {
																											...quote,
																											quotation: roundAtDecimals(quote.quotation, 2),
																											unitSell: roundAtDecimals(quote.quotation * quote.margin, 2),
																											totalSell: roundAtDecimals(quote.quotation * quote.margin * quote.quantity, 2),
																											shipmentPrice: roundAtDecimals(quote.shipmentPrice, 2),
																											aiPrediction: <AIPrediction part={row} quantity={quote.quantity} />,
																										}
																									})
																						}
																						styleForFieldList={[
																							{
																								field: 'unitCost',
																								styles: [
																									{
																										type: 'measure',
																										class:
																											'costs-and-quantities-information',
																									},
																								],
																							},
																							{
																								field: 'totalCost',
																								styles: [
																									{
																										type: 'measure',
																										class:
																											'costs-and-quantities-information',
																									},
																								],
																							},
																							{
																								field: 'margin',
																								styles: [
																									{
																										type: 'measure',
																										class:
																											'costs-and-quantities-information',
																										nullValue: 'N/A',
																									},
																								],
																							},
																							{
																								field: 'unitSell',
																								styles: [
																									{
																										type: 'measure',
																										class:
																											'costs-and-quantities-information',
																										nullValue: 'N/A',
																									},
																								],
																							},
																							{
																								field: 'totalSell',
																								styles: [
																									{
																										type: 'measure',
																										class:
																											'costs-and-quantities-information',
																										nullValue: 'N/A',
																									},
																								],
																							},
																							{
																								field: 'shipment',
																								styles: [
																									{
																										type: 'measure',
																										class:
																											'costs-and-quantities-information',
																									},
																								],
																							},
																						]}
																					/>
																				)}
																			</Col>
																			<Col className="col-4">
																				<Tabs
																					className="scroll"
																					id={row.id.value}
																					defaultSelected={
																						expandRowControl &&
																						expandRowControl.selectedSupplier
																					}
																					onToggle={this.toggleSupplierOnPart}
																					sections={
																						row.costsAndMargins &&
																						row.costsAndMargins.suppliers.map(
																							(supplier, index) => {
																								const isSelected = supplier.id === row.selectedQuote?.supplierId && row.status?.value?.id > QUOTED_STATUS
																								const isCutedAndGlued = supplier.constructionTypeId === 2
																								return {
																									titleClassName: supplier.isIA,
																									title: supplier.name,
																									titleIcons: (
																										<div>
																											{supplier.isBestOffer && (
																												<i className="bx bxs-trophy text-yellow font-size-16"></i>
																											)}
																											{isSelected && (
																												<i className="bx bx-anchor text-blue font-size-16"></i>
																											)}
																											{(supplier.isIA || supplier.id === parseInt(process.env.REACT_APP_IA_3D_SUPPLIER_ID)) && (
																												<i className="bx bx-chip font-size-16 text-primary mx-1 mt-1"></i>
																											)}
																											{isCutedAndGlued && (
																												<i className="bx bx-cut font-size-16 text-primary mx-1 mt-1"></i>
																											)}
																										</div>
																									),
																									content:
																										(row.costsAndMargins &&
																											row.costsAndMargins.suppliers[
																												index
																											].quotes &&
																											row.costsAndMargins.suppliers[
																												index
																											].quotes.length > 0)
																											|| row.costsAndMargins.suppliers[index].isIA == true
																											&& (row.configuration.technologyTypeId == IMPRESION3D_ID)
																											? (
																												<Table
																													selectRow={{
																														mode: 'checkbox',
																														hideSelectColumn: true,
																													}}
																													header={
																														!supplier.isIA
																															? costsAndMarginsSupplierHeader
																															: {
																																quotation:
																																	costsAndMarginsSupplierHeader.quotation,
																															}
																													}
																													disableInteractions
																													disableSorting
																													items={!supplier.isIA
																														? (row.costsAndMargins && this.mapCosts(row.costsAndMargins.suppliers[index].quotes))
																														: [
																															{},
																														]
																													}
																													styleForFieldList={[
																														{
																															field: 'quotation',
																															styles: [
																																{
																																	type: 'measure',
																																	class:
																																		'company-quantities-information',
																																},
																															],
																														},
																														!supplier.isIA && {
																															field:
																																'manufacturingTime',
																															styles: [
																																{
																																	type: 'measure',
																																	class:
																																		'company-quantities-information',
																																},
																															],
																														},
																													]}
																												/>
																											) : (
																												<div className="width-by-content mx-auto h5 mt-3">
																													{supplier.isIA
																														? t(
																															'predictionNotAvailable',
																															{ ns: 'errors' },
																														)
																														: t(
																															'quotationNotAvailable',
																															{ ns: 'errors' },
																														)}
																												</div>
																											),
																								}
																							})}
																				/>
																			</Col>
																		</Row>
																	)}
															</div>
														)
													},
												}}
											/>
										)}
									</Col>
								</Row>
							</span>
						)}
					<Modal
						isOpen={this.state.isQuotationModalOpen}
						closeModal={this.closeQuotationModal}
						title="Solicitar Cotización"
						body={
							this.state.selectedRows.length > 0 ? (
								<div className="modal-body">
									<Select
										placeholder="Selecciona un agente para el remitente y firma del correo"
										options={this.parseManagers(this.props.managers)}
										className="col-md-12 mt-3"
										onChange={e => this.onChangeManager(e)}
									/>
									<MultiSelect
										placeholder="Selecciona un Proveedor"
										options={this.parseSuppliers(this.props.suppliers)}
										className="col-md-12 mt-3"
										onChange={e => this.onChangeSupplier(e)}
									/>
									<Switch
										id="urgent"
										label="Urgente"
										value={this.state.quotationModalData.isUrgent}
										onChange={e => this.onChangeUrgent(e)}
										size="lg"
										className="mt-3 mb-3"
									/>
									<CKEditor
										editor={ClassicEditor}
										data="<p></p>"
										onChange={(event, editor) => {
											const data = editor.getData()
											this.onChangeComment(data)
										}}
									/>
								</div>
							) : (
								<div className="modal-body">
									Por favor, seleccione al menos una pieza para solicitar la
									cotización.
								</div>
							)
						}
						buttons={
							this.state.selectedRows.length <= 0 ? [] : [
								<button
									disabled={
										!this.state.quotationModalData.manager ||
										this.state.quotationModalData.suppliers.length === 0
									}
									type="button"
									key="btn2"
									className="btn btn-primary"
									onClick={() => this.submitQuote()}
								>
									Enviar
								</button>,
							]
						}
					/>
					<Modal
						isOpen={this.state.isBluePrintModalOpen}
						closeModal={this.closeBluePrintModal}
						title={t('uploadBlueprint', { ns: 'naming' })}
						size="lg"
						body={
							<>
								<Dropzone onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
									{({ getRootProps, getInputProps }) => (
										<div className="dz-message needsclick" {...getRootProps()}>
											<input multiple {...getInputProps()} />
											<div className="d-flex flex-row  p-2 align-items-center" style={{ border: '1px solid lightgray', borderRadius: '5px' }} >
												<i
													className={
														this.state.selectedFiles?.length
															? 'display-4 text-success bx bx-check me-3'
															: 'display-4 text-primary bx bxs-package me-3'
													}
												/>
												{this.state.selectedFiles?.length
													? (<h4 className="mt-1">{this.state.selectedFiles?.length} {t('files', { ns: 'naming' })}</h4>)
													: (<h4 className="mt-1">{t('clickOrDrop', { ns: 'naming' })}</h4>)
												}
											</div>
										</div>
									)}
								</Dropzone>
								<div className="d-flex justify-content-between">
									<div className="row mt-2 mb-5 mx-4" style={{ width: '100%' }}>
										{this.props.offerDetail?.offer?.parts?.map((part, index) => (
											<div
												key={`offers_page_part_${part.id}`}
												style={{ marginBottom: '60px' }}
											>
												<div
													className="mt-2"
													style={{ border: '1.5px solid lightgray', borderRadius: '5px', height: '100%' }}
												>
													<div
														style={{ flex: 1 }}
														className="d-flex justify-content-center align-items-center bg-gray p-3"
													>
														<img src={part.imageUrl} alt={part.name} width="80" height="80" />
													</div>
													<div className="d-flex flex-column p-3">
														<h5>{part.id}</h5>
														<p style={{ wordBreak: 'break-all', color: 'gray' }}>{part.name}.stp</p>
														{part?.blueprints[0]?.name && (
															<p style={{ wordBreak: 'break-all', color: 'gray' }}>
																<i className="mdi mdi-file-pdf align-middle me-2" style={{ color: 'red' }} />{part?.blueprints[0]?.name}
															</p>
														)}
														{part?.blueprints[1]?.name && (
															<p style={{ wordBreak: 'break-all', color: 'gray' }}>
																<i className="mdi mdi-file-pdf-outline align-middle me-2" style={{ color: 'red' }} />{part?.blueprints[1]?.name}
															</p>
														)}
													</div>
												</div>
												{this.state.selectedFiles?.length > 0 && (
													<div className="mt-2 mb-2">
														<Input
															key={`selector_${index}`}
															type="select"
															name="select"
															id="exampleSelect"
															value={this.state.fileNameAndPartId?.find(f => f.partId === part.id)?.fileName || ''}
															onChange={(e) => this.assignFileToPart(part.id, e.target.value)}
														>
															<option value="">{t('selectFile', { ns: 'naming' })}</option>
															{this.state.selectedFiles?.map((f, i) => (
																<option key={`select_file_option_${i}_${f.name}`} value={f.name}>{f.name}</option>
															))}
														</Input>
													</div>
												)}
											</div>
										))}
									</div>
								</div>
							</>
						}
						buttons={[
							<button
								type="button"
								key="btn3"
								className="btn btn-secondary"
								onClick={() => this.handleUploadBlueprint()}
							>
								{t('submit', { ns: 'naming' })}
							</button>,
						]
						}
					></Modal>

					<QuotationModal
						isQuotationModalOpen={this.state.isQuotationModalOpen}
						closeQuotationModal={this.closeQuotationModal}
						selectedRows={this.state.selectedRows}
						managers={this.props.managers}
						suppliers={this.props.suppliers}
						quotationModalData={this.state.quotationModalData}
						parseManagers={this.parseManagers}
						parseSuppliers={this.parseSuppliers}
						onChangeManager={this.onChangeManager}
						onChangeSupplier={this.onChangeSupplier}
						onChangeUrgent={this.onChangeUrgent}
						onChangeComment={this.onChangeComment}
						onChangeAutomatic={this.onChangeAutomatic}
						submitQuote={this.submitQuote}
						isLoading={this.props.isLoadingQuotes}
						t={this.props.t}
					/>
					<CreateQuoteResponseModal
						isQuoteCreatedSuccessfully={this.props.isQuoteCreatedSuccessfully}
						closeCreateQuotationResultModal={this.closeCreateQuotationResultModal}
					/>
					<EditMarginsModal
						isEditMarginsModalOpen={this.state.isEditMarginsModalOpen}
						closeEditMarginsModal={this.closeEditMarginsModal}
						marginModal={this.state.marginModal}
						setMargin={this.setMargin}
						setUnitCostByMargin={this.setUnitCostByMargin}
						saveAndCloseMarginsModal={this.saveAndCloseMarginsModal}
					/>

					<UploadModal
						isUploadModalOpen={this.state.isUploadModalOpen}
						closeUploadModal={this.closeUploadModal}
						saveAndCloseUploadModal={this.saveAndCloseUploadModal}
						selectedRows={this.state.selectedRows}
						hasAllSelectedRowsHaveAValidSupplier={hasAllSelectedRowsHaveAValidSupplier}
						getSelectedTabSupplier={this.getSelectedTabSupplier}
					/>
					<EditCustomerModal
						isEditCustomerModalOpen={this.state.isEditCustomerModalOpen}
						closeEditCustomerModal={this.closeEditCustomerModal}
						customerModal={this.state.customerModal}
						customers={this.props.customers}
						setCustomer={this.setCustomer}
						selectedCustomer={this.state.selectedCustomer}
						offerId={GetOfferIdFromURL()}
						updateOfferCustomer={this.updateOfferCustomer}
					/>
					<CustomModal
						isOpen={this.state.modalChat}
						closeModal={() => this.toggleChat()}
						closeButtonColor="secondary"
						className={'hugeModal'}
						title="Chat"
						size="lg"
						body={
							<OfferChat
								parts={this.props.offerDetail?.offer?.parts}
								selectedPart={this.state.selectedChatPartInfo}
								handleOnChangeSelectedPart={this.handleOnChangeChatSelectedPart}
								handleOpenChatMessage={this.handleOpenChatMessage}
								openedChats={this.state.openedChats}
								partConfigOptions={this.props.partConfigOptions}
							/>
						}
					/>
					<ModalMessage
						isModalOpen={this.state.isModalMessageOpen}
						closeModal={this.closeModalMessage}
						message={this.state.modalMessage}
					/>
					<OrderSimulationModal
						isOrderSimulationModalOpen={this.state.isOrderSimulationModalOpen}
						closeOrderSimulationModal={this.closeOrderSimulationModal}
						setModalMessage={this.setModalMessage}
						parts={this.state.selectedRows}
						suppliers={this.props.suppliers}
						parseSuppliers={this.parseSuppliers}
						offerDate={this.props.offerDetail?.offer?.date?.value}
					/>
					<VcPartCostsModal
						isOpen={this.state.costsModal.isOpen}
						partId={this.state.costsModal.partId}
						supplier={this.state.costsModal.supplier}
						closeModal={this.closeCostsModal}
					/>
					<EditStatusModal
						isEditStatusModalOpen={this.state.isEditStatusModalOpen}
						closeEditStatusModal={this.closeEditStatusModal}
						selectedRows={this.state.selectedRows}
						saveNewStatus={this.saveNewStatus}
						readonlyParts={this.props?.offerDetail?.offer?.parts || []}
						openEditBindingNoteModal={this.openEditBindingNoteModal}
						suppliersSelected={this.state.suppliersSelected}
						setSuppliersSelected={this.setSuppliersSelected}
					/>
					<EditQuantitiesModal
						isEditQuantitiesModalOpen={this.state.isEditQuantitiesModalOpen}
						closeEditQuantitiesModal={this.closeEditQuantitiesModal}
						selectedRows={this.state.selectedRows}
						setModalMessage={this.setModalMessage}
					/>
					<DeleteModifiedBlueprintsModal
						parts={this.state.selectedRows}
						isOpenModal={this.state.isDeleteModifiedBlueprintsModalOpen}
						closeModal={this.closeDeleteModifiedBlueprintsModal}
					/>
					<SendUploadEmailModal
						isSendUploadEmailModalOpen={this.state.isSendUploadEmailModalOpen}
						closeSendUploadEmailModal={this.closeSendUploadEmailModal}
						customer={this.props.offerDetail.account}
						organization={this.props.offerDetail.organization}
						offerId={this.props.offerDetail.offer?.id}
						setModalMessage={this.setModalMessage}
					/>
					<EditConfigurationModal
						t={t}
						isOpen={this.state.isEditConfigurationModalOpen}
						closeModal={this.closeEditConfigurationModal}
						selectedRows={this.state.selectedRows}
						updateParts={this.props.updateParts}
						updatePartsLoading={this.props.updatePartsLoading}
						updatePartsError={this.props.updatePartsError}
						updatePartsSuccess={this.props.updatePartsSuccess}
						partConfigOptions={this.props.partConfigOptions}
						offer={this.props.offerDetail?.offer}
					/>
					<GeometricSimilarityModal
						isGeometricSimilarityModalOpen={this.state.isGeometricSimilarityModalOpen}
						closeGeometricSimilarityModal={this.closeGeometricSimilarityModal}
						similarPartsIds={this.state.similarPartsIds}
						similarity={this.state.similarity}
						partConfigOptions={this.props.partConfigOptions}
						offerId={this.state.geometricSimilarityModalOfferId}
					/>
					<EditBindingNoteModal
						t={t}
						isOpen={this.state.isEditBindingNoteModalOpen}
						closeModal={this.closeEditBindingNoteModal}
						selectedParts={this.state.selectedPartsForEditBindingNoteModal}
						updateBindingNotes={this.props.updateBindingNotes}
						updateBindingNotesStatus={this.props.updateBindingNotesStatus}
						deleteBindingNotes={this.props.deleteBindingNotes}
						deleteBindingNotesStatus={this.props.deleteBindingNotesStatus}
						rejectPartsAfterEditingBindingNote={this.state.editBindingModal_rejectPartsAfterEditingBindingNote}
						updatePartsStatus={this.props.updatePartsStatus}
						successUpdatePartsStatusWithinBindingNoteModal={this.props.successUpdatePartsStatusWithinBindingNoteModal}
						suppliersSelected={this.state.suppliersSelected}
					/>

					<CloneQuotationModal
						isModalOpen={this.state.isCloneQuoteModalOpen}
						handleOnChangeCloneQuoteModal={this.handleOnChangeCloneQuoteModal}
						translatedParts={this.state.translatedParts.filter(part => part.configuration.technologyTypeId !== VACCUM_CASTING_TECHNOLOGY_ID)}
						partConfigOptions={this.props.partConfigOptions}
					/>
				</div>
			</React.Fragment>
		)
	}

}

const mapStateToProps = state => {
	return {
		offerDetail: state.Offers.offerDetail,
		isLoading: state.Offers.isLoading,
		offerError: state.Offers.error,
		isUploadingOffer: state.Offers.isUploadingOffer,
		uploadingOfferError: state.Offers.uploadingOfferError,
		suppliers: state.Users.suppliers,
		managers: state.Users.managers,
		userError: state.Users.error,
		isQuoteCreatedSuccessfully: state.Quotes.isQuoteCreatedSuccessfully,
		customers: state.Users.customers,
		isUpdatingOfferCustomer: state.Offers.isUpdatingOfferCustomer,
		updateOfferCustomerError: state.Offers.updateOfferCustomerError,
		isUpdatingMarginsResponse: state.Offers.isUpdatingMarginsResponse,
		partCosts: state.Quotes.partCosts,
		partPrices: state.Quotes.partPrices,
		uploadingBlueprintSuccess: state.Offers.uploadingBlueprintSuccess,
		quoteChat: state.Quotes.quoteChat,
		images: state.CloudStorage.images,
		chatImages: state.CloudStorage.chatImages,
		accountId: state.Login.id,
		blueprints: state.Offers.blueprints,
		isQuoteUpdatedSuccessfully: state.Quotes.isQuoteUpdatedSuccessfully,
		partConfigOptionsLoading: state.Parts.partConfigOptionsLoading,
		partConfigOptionsError: state.Parts.partConfigOptionsError,
		partConfigOptions: state.Parts.partConfigOptions,
		updatePartsLoading: state.Parts.updatePartsLoading,
		updatePartsError: state.Parts.updatePartsError,
		updatePartsSuccess: state.Parts.updatePartsSuccess,
		successUpdatePartsStatusWithinBindingNoteModal: state.Parts.successUpdatePartsStatusWithinBindingNoteModal,
		errorCloud: state.CloudStorage.error,
		hasClonedSuccessfully: state.Quotes.hasClonedSuccessfully,
		errorQuotes: state.Quotes.error,
		updateBindingNotesStatus: state.Parts.updateBindingNotesStatus,
		deleteBindingNotesStatus: state.Parts.deleteBindingNotesStatus,
		successUpdatePartsStatus: state.Parts.successUpdatePartsStatus,
		isLoadingQuotes: state.Quotes.isLoading,
	}
}

Offer.propTypes = {
	offerDetail: PropTypes.object,
	isLoading: PropTypes.bool,
	getOfferDetail: PropTypes.func,
	getCustomers: PropTypes.func,
	updateQuoteMargin: PropTypes.func,
	uploadOffer: PropTypes.func,
	isUploadingOffer: PropTypes.bool,
	uploadingOfferError: PropTypes.bool,
	createQuote: PropTypes.func,
	offerError: PropTypes.any,
	managers: PropTypes.array,
	suppliers: PropTypes.array,
	userError: PropTypes.any,
	isQuoteCreatedSuccessfully: PropTypes.bool,
	resetCreateQuoteModal: PropTypes.func,
	t: PropTypes.func,
	i18n: PropTypes.object,
	customers: PropTypes.array,
	updateOfferCustomer: PropTypes.func,
	isUpdatingOfferCustomer: PropTypes.bool,
	updateOfferCustomerError: PropTypes.bool,
	getSuppliersInfo: PropTypes.func,
	getManagers: PropTypes.func,
	closeQuotationModal: PropTypes.func,
	getSuppliers: PropTypes.func,
	isUpdatingMarginsResponse: PropTypes.string,
	getPartCostsBySupplierId: PropTypes.func,
	getPartPricesBySupplierId: PropTypes.func,
	partCosts: PropTypes.array,
	partPrices: PropTypes.array,
	uploadBluePrintOffer: PropTypes.func,
	uploadingBlueprintSuccess: PropTypes.bool,
	getQuoteChat: PropTypes.func,
	quoteChat: PropTypes.array,
	postQuoteChat: PropTypes.func,
	getPartListImages: PropTypes.func,
	chatImages: PropTypes.array,
	getQuoteChatImages: PropTypes.func,
	accountId: PropTypes.number,
	images: PropTypes.array,
	uploadQuoteChatImage: PropTypes.func,
	downloadBluePrintOffer: PropTypes.func,
	blueprints: PropTypes.array,
	saveAndConfirmQuote: PropTypes.func,
	isQuoteUpdatedSuccessfully: PropTypes.bool,
	getPartConfigOptions: PropTypes.func,
	partConfigOptionsLoading: PropTypes.bool,
	partConfigOptionsError: PropTypes.any,
	partConfigOptions: PropTypes.any,
	updateParts: PropTypes.func,
	updatePartsLoading: PropTypes.bool,
	updatePartsError: PropTypes.any,
	updatePartsSuccess: PropTypes.bool,
	successUpdatePartsStatusWithinBindingNoteModal: PropTypes.bool,
	markQuoteMessagesAsRead: PropTypes.func,
	errorCloud: PropTypes.string,
	hasClonedSuccessfully: PropTypes.bool,
	errorQuotes: PropTypes.string,
	updateBindingNotes: PropTypes.func,
	updateBindingNotesStatus: PropTypes.any,
	deleteBindingNotes: PropTypes.func,
	deleteBindingNotesStatus: PropTypes.any,
	updatePartsStatus: PropTypes.func,
	successUpdatePartsStatus: PropTypes.bool,
	isLoadingQuotes: PropTypes.bool,
}

export default withGetPartConfigOptions(connect(mapStateToProps, {
	getOfferDetail,
	createQuote,
	resetCreateQuoteModal,
	updateQuoteMargin,
	uploadOffer,
	getCustomers,
	updateOfferCustomer,
	getSuppliersInfo,
	getManagers,
	getSuppliers,
	getPartCostsBySupplierId,
	getPartPricesBySupplierId,
	uploadBluePrintOffer,
	getQuoteChat,
	postQuoteChat,
	getPartListImages,
	getQuoteChatImages,
	uploadQuoteChatImage,
	downloadBluePrintOffer,
	saveAndConfirmQuote,
	updateParts,
	getPartConfigOptions,
	markQuoteMessagesAsRead,
	updateBindingNotes,
	deleteBindingNotes,
	updatePartsStatus,
})(withTranslation()(Offer)))
