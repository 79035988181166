import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { getManagerDetailById, getManagerList } from 'store/actions'
import ManagerInfo from './Components/ManagerInfo'
import MonthlyInfo from './Components/MonthlyInfo'
import Notifications from './Components/Notifications/Notifications'
import StaticsInfo from './Components/StaticsInfo'



export const ControlPanel = ({ t, isLoadingUsers, isLoadingOffers, isLoadingOrders, getManagerList, getManagerDetailById }) => {

	const [selectedManagersIds, setSelectedManagers] = useState([])

	const handleManagerSelection = (selectedIds) => {
		setSelectedManagers(selectedIds)
	}

	const getUserName = () => {
		if (localStorage.getItem('authUser')) {
			const obj = JSON.parse(localStorage.getItem('authUser'))
			return obj
		}
	}

	useEffect(() => {
		const userData = getUserName()
		if (userData.roles.includes("boss")) {
			getManagerList()
		} else if (userData.roles.includes("manager")) {
			getManagerDetailById(userData.id)
		}
		//TODO ADD ERROR NO MANAGER
	}, [])

	const isLoading = (isLoadingUsers || isLoadingOffers || isLoadingOrders) && false

	return (
		<div className='page-content'>
			<MetaTags>
				<title>Proto&Go! | {t('control-panel', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('control-panel', { ns: 'naming' })}
			/>
			<div className="container-fluid">
				{
					isLoading ? <Loader /> :
						<div className="row">
							<div className="col-4 me-2">
								<div className=" p-4 my-4 bg-white">
									<ManagerInfo onManagerSelection={handleManagerSelection} />
								</div>
								<div className=" p-4 bg-white">
									<MonthlyInfo selectedManagersIds={selectedManagersIds} />
								</div>
							</div>
							<div className="col">
								<StaticsInfo selectedManagersIds={selectedManagersIds} />
								<div className=" p-4 my-2 bg-white" style={{ height: "75%" }}>
									<Notifications selectedManagersIds={selectedManagersIds} />
								</div>

							</div>
						</div>
				}
			</div>
		</div>
	)
}

ControlPanel.propTypes = {
	t: PropTypes.func,
	isLoadingUsers: PropTypes.bool,
	isLoadingOrders: PropTypes.bool,
	isLoadingOffers: PropTypes.bool,
	getManagerDetailById: PropTypes.func,
	getManagerList: PropTypes.func,
}

const mapStateToProps = (state) => ({

	isLoadingUsers: state.Users.isLoading,
	isLoadingOrders: state.Orders.isLoading,
	isLoadingOffers: state.Offers.isLoading,

})

const mapDispatchToProps = {

	getManagerList,
	getManagerDetailById

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ControlPanel))
