export const UPDATE_PARTS_STATUS = 'UPDATE_PARTS_STATUS'
export const UPDATE_PARTS_STATUS_SUCCESS = 'UPDATE_PARTS_STATUS_SUCCESS'
export const UPDATE_PARTS_STATUS_FAIL = 'UPDATE_PARTS_STATUS_FAIL'
export const CREATE_PARTS_QUANTITIES = 'CREATE_PARTS_QUANTITIES'
export const CREATE_PARTS_QUANTITIES_SUCCESS = 'CREATE_PARTS_QUANTITIES_SUCCESS'
export const CREATE_PARTS_QUANTITIES_FAIL = 'CREATE_PARTS_QUANTITIES_FAIL'
export const GET_PART_CONFIG_OPTIONS = 'GET_PART_CONFIG_OPTIONS'
export const GET_PART_CONFIG_OPTIONS_SUCCESS = 'GET_PART_CONFIG_OPTIONS_SUCCESS'
export const GET_PART_CONFIG_OPTIONS_FAIL = 'GET_PART_CONFIG_OPTIONS_FAIL'
export const UPDATE_PARTS = 'UPDATE_PARTS'
export const UPDATE_PARTS_SUCCESS = 'UPDATE_PARTS_SUCCESS'
export const UPDATE_PARTS_FAIL = 'UPDATE_PARTS_FAIL'
export const GET_FILTERED_PARTS = 'GET_FILTERED_PARTS'
export const GET_FILTERED_PARTS_SUCCESS = 'GET_FILTERED_PARTS_SUCCESS'
export const GET_FILTERED_PARTS_FAIL = 'GET_FILTERED_PARTS_FAIL'
export const GET_SIMILAR_PARTS_DETAIL = 'GET_SIMILAR_PARTS_DETAIL'
export const GET_SIMILAR_PARTS_DETAIL_SUCCESS = 'GET_SIMILAR_PARTS_DETAIL_SUCCESS'
export const GET_SIMILAR_PARTS_DETAIL_FAIL = 'GET_SIMILAR_PARTS_DETAIL_FAIL'
export const UPDATE_BINDING_NOTES = 'UPDATE_BINDING_NOTES'
export const UPDATE_BINDING_NOTES_SUCCESS = 'UPDATE_BINDING_NOTES_SUCCESS'
export const UPDATE_BINDING_NOTES_FAIL = 'UPDATE_BINDING_NOTES_FAIL'
export const DELETE_BINDING_NOTES = 'DELETE_BINDING_NOTES'
export const DELETE_BINDING_NOTES_SUCCESS = 'DELETE_BINDING_NOTES_SUCCESS'
export const DELETE_BINDING_NOTES_FAIL = 'DELETE_BINDING_NOTES_FAIL'
export const GET_TECHNOLOGIES_DASHBOARD = 'GET_TECHNOLOGIES_DASHBOARD'
export const GET_TECHNOLOGIES_DASHBOARD_SUCCESS = 'GET_TECHNOLOGIES_DASHBOARD_SUCCESS'
export const GET_TECHNOLOGIES_DASHBOARD_FAIL = 'GET_TECHNOLOGIES_DASHBOARD_FAIL'
