import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { rejectProduction } from 'store/actions'

export const RejectProductionModal = ({ t, orderId, orderFragmentId, supplierId, rejectProduction, isLoading, isProductionRejected }) => {

	const [rejectReason, setRejectReason] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)
	const [hasPressed, setHasPressed] = useState(false)

	useEffect(() => {
		if (isLoading === false) {
			setIsOpen(false)
		}
	}, [isLoading])

	useEffect(() => {
		if (message !== '') {
			setIsModalMessageOpen(true)
		}
	}, [message])

	useEffect(() => {
		switch (isProductionRejected) {
			case true:
				setMessage(t('update_success', { ns: 'naming' }))
				break
			case false:
				setMessage(t('error_message', { ns: 'naming' }))
				break
			default:
				break
		}
	}, [isProductionRejected])

	const saveData = (e) => {
		if (rejectReason === '') {
			setHasPressed(true)
			return
		}
		const data = { data: { orderFragmentId, orderId, supplierId, rejectReason } }
		rejectProduction(data)
	}

	return (
		<div>
			<button type="button" className="btn btn-danger mx-1" onClick={(e) => setIsOpen(true)}>
				<div>
					<span className="d-flex flex-row">
						<i className="bx bx-x fs-4"></i>
						<p className="mx-1 my-0">{t('deny', { ns: 'naming' })}</p>
					</span>
				</div>
			</button>
			<Modal
				title={t('reject_production', { ns: 'naming' })}
				isOpen={isOpen}
				closeModal={(e) => setIsOpen(false)}
				body={
					<div className='p-4'>
						<div className='d-flex flex-column'>
							<p>{t('reject_reason', { ns: 'naming' })}</p>
							<textarea value={rejectReason} onChange={(e) => setRejectReason(e.target.value)}></textarea>
							{hasPressed && rejectReason === '' && <small className='text-red'>{t('required_field', { ns: 'naming' })}</small>}
						</div>
					</div>
				}
				buttons={[<button key={'s'} className='btn btn-primary' onClick={(e) => saveData()}>{isLoading ? <Loader /> : t('reject', { ns: 'naming' })}</button>]}
			></Modal>
			<ModalMessage isModalOpen={isModalMessageOpen} message={message} closeModal={(e) => location.reload()} />
		</div>
	)
}

RejectProductionModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	orderId: PropTypes.number,
	orderFragmentId: PropTypes.number,
	supplierId: PropTypes.number,
	rejectProduction: PropTypes.func,
	isLoading: PropTypes.bool,
	isProductionRejected: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	isLoading: state.Productions.isLoading,
	isProductionRejected: state.Productions.isProductionRejected,
})

export default connect(mapStateToProps, { rejectProduction })(withTranslation()(RejectProductionModal))
