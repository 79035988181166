import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import { useObjectState } from 'common/Hooks/UseObjectState'
import { mappedSemaphoreFilterOptions } from 'common/Utils/ArrayUtilities'
import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import { allFiltersAreEmpty } from 'common/Utils/ObjectUtils'
import { getProductionStatusKeyValuePair } from 'common/Utils/StatusUtils'
import { mapSuppliers } from 'common/Utils/SuppliersUtils'
import DatePickr from 'components/Common/DatePickr'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import SearchInput from 'components/Common/SearchInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getFilteredProductions, getProductionList } from 'store/actions'

const INITIAL_STATE = {
	availableStatus: [],
	availableSuppliers: [],
	productionDate: {
		startDate: null,
		endDate: null,
	},
	supplierShipmentDate: {
		startDate: null,
		endDate: null,
	},
	arrivalDate: {
		startDate: null,
		endDate: null,
	},
	shipmentDate: {
		startDate: null,
		endDate: null,
	},
}

export const ProductionsFilterModal = ({
	t, getFilteredProductions, pageSize, currentPage, suppliers, setCurrentPage,
	handleOnChangeSemaphoreFilter, semaphoreFilter, resetSemaphoreFilters, isSupplier, selectedSupplier,
	showSupplierProductions, productionList,
}) => {
	const [keyword, setKeyword] = useState('')
	const [isModalOpen, setIsModalOpen] = useState(false)
	const { objectData, handleOnChange, handleOnChangeDateRange, resetDateRange, resetState } = useObjectState(INITIAL_STATE)

	const pagination = { page: currentPage, pageSize }

	const inputBody = {
		data: {
			keyword,
			pagination,
			filters: objectData,
			supplierId: showSupplierProductions ?
				isSupplier ? parseInt(getUserValuesFromLocalStorage().id) : parseInt(selectedSupplier)
				: null,
		},
	}

	const noFilteredInputBody = {
		data: {
			pagination,
			supplierId: showSupplierProductions ?
				isSupplier ? parseInt(getUserValuesFromLocalStorage().id) : parseInt(selectedSupplier)
				: null,
		},
	}

	const handledApiCallFiltredProductions = useHandleCancelableApi(getFilteredProductions, inputBody, 500)
	const handleApiCallNoFilteredProductions = useHandleCancelableApi(getFilteredProductions, noFilteredInputBody, 0)

	const handleCloseModal = () => {
		setIsModalOpen(false)
	}

	useEffect(() => {
		if (currentPage !== 1) {
			setCurrentPage(1)
		} else {
			getProductions()
		}
	}, [keyword, selectedSupplier])

	useEffect(() => {
		getProductions()
	}, [currentPage, pageSize])

	const getProductions = () => {

		if (allFiltersAreEmpty(keyword, objectData)) {
			handledApiCallFiltredProductions.cancelApiCall()
			handleApiCallNoFilteredProductions.handleApiCall()
		} else {
			handleApiCallNoFilteredProductions.cancelApiCall()
			handledApiCallFiltredProductions.handleApiCall()
		}
	}

	const handelApplyFilters = () => {
		if (currentPage !== 1) {
			setCurrentPage(1)
		} else {
			getProductions()
		}
		setIsModalOpen(false)
	}

	const handleResetFilters = () => {
		resetState()
		setKeyword('')
		resetSemaphoreFilters()
		setIsModalOpen(false)
		handledApiCallFiltredProductions.cancelApiCall()
		handleApiCallNoFilteredProductions.handleApiCall()
	}

	return (
		<div className='d-flex'>
			<div className="position-relative me-3">
				<SearchInput onChange={setKeyword} value={keyword} />
			</div>
			<div>
				<button className='btn btn-secondary' onClick={() => { setIsModalOpen(true) }}>Filtrar</button>
				<Modal
					title={t('filterProductions', { ns: 'naming' })}
					buttons={[
						<button onClick={() => { handleResetFilters() }} key='reset_production_filters' className='btn btn-primary'>{t('reset', { ns: 'naming' })}</button>,
						<button onClick={() => { handelApplyFilters() }} key='apply_production_filters' className='btn btn-primary'>{t('apply', { ns: 'naming' })}</button>,
					]}
					body={
						<div className='p-4'>
							<OptionsInput
								isMulti
								value={objectData.availableStatus}
								setValue={handleOnChange}
								objectElement='availableStatus'
								title={t('status', { ns: 'naming' })}
								options={getProductionStatusKeyValuePair(t)}
								automaticMappedValue
							/>
							{!showSupplierProductions &&
								<OptionsInput
									isMulti
									value={objectData.availableSuppliers}
									setValue={handleOnChange}
									objectElement='availableSuppliers'
									title={t('suppliers', { ns: 'naming' })}
									options={mapSuppliers(suppliers)}
									automaticMappedValue
								/>
							}
							<DatePickr
								mode={'range'}
								format={'Y-m-d'}
								value={[objectData.productionDate?.startDate, objectData.productionDate?.endDate]}
								setValue={handleOnChangeDateRange}
								title={t('production_date', { ns: 'naming' })}
								objectElement={'productionDate'}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={resetDateRange}
							/>

							<DatePickr
								mode={'range'}
								format={'Y-m-d'}
								value={[objectData.supplierShipmentDate?.startDate, objectData.supplierShipmentDate?.endDate]}
								setValue={handleOnChangeDateRange}
								title={t('supplier_shipment_date', { ns: 'naming' })}
								objectElement={'supplierShipmentDate'}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={resetDateRange}
							/>

							{!showSupplierProductions &&
								<DatePickr
									mode={'range'}
									format={'Y-m-d'}
									value={[objectData.arrivalDate?.startDate, objectData.arrivalDate?.endDate]}
									setValue={handleOnChangeDateRange}
									title={t('arrivalDate', { ns: 'naming' })}
									objectElement={'arrivalDate'}
									placeholder={t('start_end_date', { ns: 'naming' })}
									cleanData={resetDateRange}
								/>}

							{!showSupplierProductions &&
								<DatePickr
									mode={'range'}
									format={'Y-m-d'}
									value={[objectData.shipmentDate?.startDate, objectData.shipmentDate?.endDate]}
									setValue={handleOnChangeDateRange}
									title={t('customer_shipment_date', { ns: 'naming' })}
									objectElement={'shipmentDate'}
									placeholder={t('start_end_date', { ns: 'naming' })}
									cleanData={resetDateRange}
								/>}

							<OptionsInput
								isMulti
								value={semaphoreFilter.supplierShipmentDateSemaphore}
								setValue={handleOnChangeSemaphoreFilter}
								objectElement='supplierShipmentDateSemaphore'
								title={t('production_semaphore_filter', { ns: 'naming' })}
								options={mappedSemaphoreFilterOptions(t)}
								automaticMappedValue
							/>
							{!showSupplierProductions &&
								<OptionsInput
									isMulti
									value={semaphoreFilter.arrivalShipmentDateSemaphore}
									setValue={handleOnChangeSemaphoreFilter}
									objectElement='arrivalShipmentDateSemaphore'
									title={t('shipping_customer_semaphore_filter', { ns: 'naming' })}
									options={mappedSemaphoreFilterOptions(t)}
									automaticMappedValue
								/>
							}
						</div>
					}
					isOpen={isModalOpen}
					closeModal={handleCloseModal}
				/>
			</div>
		</div>
	)
}

ProductionsFilterModal.propTypes = {
	t: PropTypes.func,
	getFilteredProductions: PropTypes.func,
	getProductionList: PropTypes.func,
	currentPage: PropTypes.number,
	pageSize: PropTypes.number,
	suppliers: PropTypes.array,
	setCurrentPage: PropTypes.func,
	handleOnChangeSemaphoreFilter: PropTypes.func,
	semaphoreFilter: PropTypes.object,
	resetSemaphoreFilters: PropTypes.func,
	isSupplier: PropTypes.bool,
	selectedSupplier: PropTypes.string,
	showSupplierProductions: PropTypes.bool,
	productionList: PropTypes.array,
}

const mapStateToProps = (state) => ({
	suppliers: state.Users.suppliers,
	productionList: state.Productions.productionList,

})

const mapDispatchToProps = {
	getFilteredProductions,
	getProductionList,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductionsFilterModal))
