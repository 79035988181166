import { getFirstDayOfCurrentMonth, getLastDayCurrentMonth } from 'common/Utils/DateUtils'
import { formatDateToMMDDYYYY } from 'common/Utils/StringUtilities'

export const MONTHS = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december',
]

export const INITIAL_DATE_RANGE = {
	startDate: formatDateToMMDDYYYY(getFirstDayOfCurrentMonth()),
	endDate: formatDateToMMDDYYYY(getLastDayCurrentMonth()),
}

export const INITIAL_FILTERS = {
	countries: [],
	managerIds: [],
	isOnlyInternal: false,
}

export const BILLING_CHART = 1
export const INTRASTAT = 2
export const OFFERS_CHART = 3
export const USERS_CHART = 4
export const TECHNOLOGIES_CHART = 5
export const COUNTRIES_CHART = 6
export const PURCHASES_CHART = 7
