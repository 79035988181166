import {
	API_ERROR,
	LOGIN_SUCCESS,
	LOGIN_USER,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	VERIFY_USER_TOKEN,
	VERIFY_USER_TOKEN_FAIL,
	VERIFY_USER_TOKEN_SUCCESS,
} from './actionTypes'

import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import {
	USER_ROLE_BOSS,
	USER_ROLE_CUSTOMER,
	USER_ROLE_MANAGER,
	USER_ROLE_SALES,
	USER_ROLE_SECUAZ,
	USER_ROLE_SUPPLIER,
} from '../../../constants/userRoles'

const authUser = getUserValuesFromLocalStorage()

const initialState = {
	error: '',
	loading: false,
	roles: (authUser && authUser.roles) || [],
	token: (authUser && authUser.token) || '',
	email: (authUser && authUser.email) || '',
	id: (authUser && authUser.id) || '',
}

const setRoles = role => {
	switch (role) {
		case 'BOSS':
		case 1:
			return [
				USER_ROLE_BOSS,
				USER_ROLE_MANAGER,
				USER_ROLE_SALES,
			]
		case 'MANAGER':
		case 2:
			return [USER_ROLE_MANAGER]
		case 'SALES':
		case 3:
			return [USER_ROLE_SALES]
		case 'SUPPLIER':
		case 4:
			return [USER_ROLE_SUPPLIER]
		case 'CUSTOMER':
		case 5:
			return [USER_ROLE_CUSTOMER]
		case 'SECUAZ':
		case 123:
			return [USER_ROLE_SECUAZ]

	}
}
const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				isLoading: true,
				roles: [],
				token: '',
				email: '',
			}
			break
		case LOGIN_SUCCESS: {
			const { email, user_role, id } = action.payload && action.payload.data.data
			const headers = action.payload.headers
			const userData = {
				id: id,
				email: email,
				rememberMe: false,
				roles: setRoles(user_role),
				token: headers && headers.authorization,
			}

			localStorage.setItem('authUser', JSON.stringify(userData))
			state = {
				...state,
				...userData,
				isLoading: false,
			}
			break
		}
		case LOGOUT_USER:
			localStorage.removeItem('authUser')
			state = {
				...state,
				roles: [],
				token: '',
				email: '',
				id: '',
			}
			break
		case LOGOUT_USER_SUCCESS:
			state = { ...state }

			break
		case API_ERROR:
			state = { ...state, error: action.payload, isLoading: false }
			break
		case VERIFY_USER_TOKEN:
			state = {
				...state,
			}
			break
		case VERIFY_USER_TOKEN_SUCCESS:
			state = {
				...state,
			}
			break
		case VERIFY_USER_TOKEN_FAIL:
			localStorage.removeItem('authUser')
			state = {
				...state,
				roles: [],
				token: '',
				email: '',
				id: '',
			}
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default login
