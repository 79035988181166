// AUTHENTICATION
export const LOGIN_ROUTE = '/login'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const RESET_PASSWORD_ROUTE = '/password-recovery'

//OFFERS
export const OFFER_LIST_ROUTE = '/offer-list'
export const OFFER_DETAIL_ROUTE_NO_ID = '/offer/'
export const OFFER_DETAIL_ROUTE = OFFER_DETAIL_ROUTE_NO_ID + ':offerId'

//QUOTES
export const QUOTE_LIST_ROUTE = '/quote-list'
export const QUOTE_DETAIL_ROUTE_NO_ID = '/quote/'
export const QUOTE_DETAIL_ROUTE = QUOTE_DETAIL_ROUTE_NO_ID + ':quoteId'

//CRM
export const CRM_USER_LIST_ROUTE = '/crm-user-list/'
export const CRM_USER_DETAIL_ROUTE_NO_ID = '/crm-user-detail/'
export const CRM_USER_DETAIL_ROUTE = CRM_USER_DETAIL_ROUTE_NO_ID + ':userId'

//SUPPLIER
export const SUPPLIER_LIST_ROUTE = '/supplier-list'
export const SUPPLIER_DETAIL_ROUTE_NO_ID = '/supplier/'
export const SUPPLIER_DETAIL_ROUTE = SUPPLIER_DETAIL_ROUTE_NO_ID + ':userId'

//ORDERS
export const ORDER_DETAIL_ROUTE_NO_ID = '/order/'

//PRODUCTIONS
export const NEW_PRODUCTION_ROUTE = '/new-production/:orderId'
export const PRODUCTION_DETAIL_NO_ID = '/production/'
export const SUPPLIER_PRODUCTION_DETAIL_NO_ID = '/supplier-production/'

// SANDBOX
export const SANDBOX_ROUTE = '/sandbox'
export const SANDBOX_AUTH_ROUTE = '/sandbox-auth'

export const MANAGER_DETAIL_ROUTE_NO_ID = '/manager-detail/'

export default {
	LOGIN_ROUTE,
	FORGOT_PASSWORD_ROUTE,
	RESET_PASSWORD_ROUTE,
	OFFER_LIST_ROUTE,
	OFFER_DETAIL_ROUTE_NO_ID,
	OFFER_DETAIL_ROUTE,
	QUOTE_LIST_ROUTE,
	QUOTE_DETAIL_ROUTE_NO_ID,
	QUOTE_DETAIL_ROUTE,
	SANDBOX_ROUTE,
	SANDBOX_AUTH_ROUTE,
	CRM_USER_LIST_ROUTE,
	CRM_USER_DETAIL_ROUTE,
	CRM_USER_DETAIL_ROUTE_NO_ID,
	SUPPLIER_LIST_ROUTE,
	SUPPLIER_DETAIL_ROUTE,
	SUPPLIER_DETAIL_ROUTE_NO_ID,
	ORDER_DETAIL_ROUTE_NO_ID,
	NEW_PRODUCTION_ROUTE,
	PRODUCTION_DETAIL_NO_ID,
	SUPPLIER_PRODUCTION_DETAIL_NO_ID,
	MANAGER_DETAIL_ROUTE_NO_ID,
}
