import { getDateWithHour } from 'common/Utils/StringUtilities'
import BlueprintAndStp from 'components/Common/BlueprintAndStp'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import PartThumbnail from 'components/Common/PartThumbnail'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { completeProduction, getAccountByEmail, getProductionDetail, getSupplierInfo, updateProduction, uploadCompleteProductionFiles } from 'store/actions'
import SelectedQuantity from '../Productions/SelectedQuantity'
import ProductionButtons from './ProductionButtons'
import ProductionNote from './ProductionNote'
import ProductionPartsTable from './ProductionPartsTable'
import ProductionTable from './ProductionTable'

import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { addVisualizingSupplierIdFromLocalStorage, getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import { roundCurrency } from 'common/Utils/NumberUtilities'
import { PartConfigTranslations, getClassnameByTechnology } from 'common/Utils/PartConfigTranslations'
import { getFullPartDescription } from 'common/Utils/PartUtils'
import Modal from 'components/Common/Modal/Modal'
import appRoutes from 'constants/appRoutes'
import { MetaTags } from 'react-meta-tags'
import FinishProductionModal from './FinishProductionModal'

const SupplierProduction = ({
	t,
	i18n: { language },
	isLoading,
	error,
	isLoadingFiles,
	errorUploadingFiles,
	getProductionDetail,
	productionDetail,
	completeProduction,
	completeProductionSuccess,
	uploadCompleteProductionFiles,
	uploadCompleteProductionFilesSuccess,
	getAccountByEmail,
	supplierAccount,
	getSupplierInfo,
	supplierInfo,
	partConfigOptions,
}) => {

	const [status, setStatus] = useState()
	const [shipmentTracking, setShipmentTracking] = useState()
	const [newShipmentTracking, setNewShipmentTracking] = useState()
	const [declaredValue, setDeclaredValue] = useState()
	const [invoiceFile, setInvoiceFile] = useState()
	const [partImagesFiles, setPartImagesFiles] = useState()
	const [shippingCompany, setShippingCompany] = useState()
	const [newShippingCompany, setNewShippingCompany] = useState()
	const [newShipmentDate, setNewShipmentDate] = useState()
	const [isOpenFinishProductionModal, setOpenFinishProductionModal] = useState(false)
	const [isOpenSuccessModal, setOpenSuccessModal] = useState(false)
	const [finishProductionStep, setFinishProductionStep] = useState(1)
	const [productionPartList, setProductionPartList] = useState(null)
	const [isOpenLabelsModal, setOpenLabelsModal] = useState(false)
	const [itemsToRender, setItemsToRender] = useState([])
	const [itemsToRenderModal, setItemsToRenderModal] = useState([])
	const [isOpenErrorModal, setOpenErrorModal] = useState(false)
	const [isShippingAllParts, setIsShippingAllParts] = useState(true)
	const [currency, setCurrency] = useState('€')

	useEffect(() => {
		const productionId = getProductionIdFromURL()
		const splitProductionId = productionId.split('-')
		getProductionDetail(splitProductionId[0], splitProductionId[1])
		getAccountByEmail({ data: { email: getUserValuesFromLocalStorage().email } })
		getSupplierInfo(getUserValuesFromLocalStorage().id)
	}, [])

	useEffect(() => {
		if (productionDetail.production) {
			setShipmentTracking(productionDetail.production.shipmentTrackingNumber)
			setShippingCompany(productionDetail.production.courier)
			setStatus(productionDetail.production.statusId)
			addVisualizingSupplierIdFromLocalStorage(productionDetail.production.supplierId)
		}
	}, [productionDetail])

	useEffect(() => {
		if (productionDetail && productionDetail.production && productionDetail?.production?.productionItemList?.length !== 0) {
			getPartList()
			setProductionPartList(
				productionDetail.production.productionItemList.map(productionItem => {
					return {
						partId: productionItem.part.id,
						selectedQuantity: productionItem.totalQuantity,
					}
				}),
			)
		}
	}, [productionDetail?.production?.productionItemList])

	useEffect(() => {
		if (productionPartList && productionPartList.length !== 0) {
			getModalPartList()
		}
	}, [productionPartList])

	useEffect(() => {
		const filesSent = invoiceFile != null || partImagesFiles != null
		if (filesSent) {
			if (completeProductionSuccess && uploadCompleteProductionFilesSuccess) {
				setOpenFinishProductionModal(false)
				setOpenSuccessModal(true)
			} else if (error || errorUploadingFiles) {
				setOpenFinishProductionModal(false)
				setOpenErrorModal(true)
			}
		} else {
			if (completeProductionSuccess) {
				setOpenFinishProductionModal(false)
				setOpenSuccessModal(true)
			} else if (error) {
				setOpenFinishProductionModal(false)
				setOpenErrorModal(true)
			}
		}
	}, [completeProductionSuccess, uploadCompleteProductionFilesSuccess, error, errorUploadingFiles])

	useEffect(() => {
		if (productionPartList == null) return
		if (productionDetail?.production?.productionItemList) {
			setIsShippingAllParts(true)
			for (const productionItem of productionDetail.production.productionItemList) {
				const productionPart = productionPartList.find(e => e.partId === productionItem.part.id)
				if (productionItem.totalQuantity != productionPart.selectedQuantity) {
					setIsShippingAllParts(false)
				}
			}
		}
	}, [productionPartList])

	useEffect(() => {
		if (supplierInfo?.personalSettings != null) {
			if (supplierInfo.personalSettings.currency.toLowerCase() == 'dollar') setCurrency('$')
		}
	}, [supplierInfo])

	const [languageChangedOnce, setLanguageChangedOnce] = useState(false)
	useEffect(() => {
		if (languageChangedOnce) history.go(0)
		else setLanguageChangedOnce(true)
	}, [language])

	const closeFinishProductionModal = () => {
		setOpenFinishProductionModal(false)
		setNewShipmentTracking(null)
		setNewShippingCompany(null)
		setFinishProductionStep(1)
	}

	const getPartList = () => {
		const partList = []
		if (productionDetail?.production?.productionItemList && productionDetail?.production?.productionItemList?.length != 0) {
			for (const productionItem of productionDetail?.production?.productionItemList) {
				const { part } = productionItem
				const stlData = {
					partId: part.id,
					partName: part.name,
					size: part.size,
					weight: part.weight,
				}
				partList.push({
					partId: { value: part.id },
					part: <PartThumbnail stlData={stlData} propsStlUrl={part.fileLinks?.stlModel} propsImageUrl={part.files?.image} />,
					technology: (
						<div className={getClassnameByTechnology({ part })}>
							{PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language })}
						</div>
					),
					files: <BlueprintAndStp partId={part.id} propsBlueprintUrl={part.fileLinks?.modifiedBlueprint ? part.fileLinks?.modifiedBlueprint : part.fileLinks?.originalBlueprint} propsModel3dUrl={part.files?.model3d} />,
					description: {
						value: {
							name: part.name,
							title: getFullPartDescription(part, partConfigOptions, language),
						},
					},
					price: { value: `${roundCurrency(productionItem.unitPurchasePrice)}${currency}` },
					quantity: { value: productionItem.totalQuantity },
					total: { value: `${roundCurrency(productionItem.totalPurchasePrice)}${currency}` },
					// chat: (
					// 	<ChatIcon
					// 		quantityUnread={productionDetail?.production?.chatMessages?.filter(x =>
					// 			x.accountId != productionDetail?.production?.supplierId &&
					// 			x.readDate == null,
					// 		)?.length}
					// 		production={productionDetail?.production}
					// 	/>
					// ), --> TODO ProductionChat no funciona todavía
					quoteId: <a
						href={`${appRoutes.QUOTE_DETAIL_ROUTE_NO_ID}${productionItem.part.offerId}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{productionItem.part.offerId}
					</a>,
				})
			}
			setItemsToRender(partList)
		}
	}

	const updateShippingQuantity = (partId, newQuantity) => {
		const duplicatedProductionPartList = [...productionPartList]
		const productionPartToUpdate = duplicatedProductionPartList.find(e => e.partId === partId)
		if (productionPartToUpdate) {
			productionPartToUpdate.selectedQuantity = newQuantity
		}
		setProductionPartList(duplicatedProductionPartList)
	}

	const getModalPartList = () => {
		const partList = []
		for (const productionItem of productionDetail?.production?.productionItemList) {
			const { part } = productionItem
			const stlData = {
				partId: part.id,
				partName: part.name,
				size: part.size,
				weight: part.weight,
			}

			partList.push({
				partId: { value: part.id },
				part: <PartThumbnail stlData={stlData} propsStlUrl={part.fileLinks?.stlModel} propsImageUrl={part.files?.image} />,
				technology: (
					<div className={getClassnameByTechnology({ part })}>
						{PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language })}
					</div>
				),
				description: {
					value: {
						name: part.name,
						title: getFullPartDescription(part, partConfigOptions, language),
					},
				},
				pendingQuantity: { value: productionItem.totalQuantity },
				shipping: {
					value: {
						shippingQuantity: (
							<SelectedQuantity
								value={productionPartList?.find(e => e.partId === part.id)?.selectedQuantity}
								setValue={(newQuantity) => updateShippingQuantity(part.id, newQuantity)}
								min={0}
								max={productionItem.totalQuantity}
							/>
						),
					},
				},
			})
		}
		setItemsToRenderModal(partList)
	}

	const handleFinishProduction = () => {
		const production = {
			...productionDetail.production,
			shipmentTrackingNumber: newShipmentTracking,
			courier: newShippingCompany,
			declaredValue: parseFloat(declaredValue),
			productionItemList: productionDetail.production.productionItemList.map((productionItem) => {
				return {
					...productionItem,
					shippedQuantity: productionPartList.find((part) => part.partId === productionItem.part.id)?.selectedQuantity,
				}
			}),
		}
		const data = {
			orderId: productionDetail.production.orderId,
			orderFragmentId: productionDetail.production.orderFragmentId,
		}
		const files = {
			invoice: invoiceFile ? invoiceFile[0] : null,
			photos: partImagesFiles || null,
		}
		if (files.invoice || files.photos) uploadCompleteProductionFiles({ data, files })
		completeProduction({ production, newShipmentDate })
	}

	if (productionDetail.production == null) {
		return (
			<div className="page-content">
				<MetaTags>
					<title>Proto&Go! | {t('production-detail', { ns: 'naming' })}</title>
				</MetaTags>
				<Breadcrumbs
					title={t('suppliers', { ns: 'naming' })}
					breadcrumbItems={[
						{
							item: t('productions', { ns: 'naming' }),
							link: '/supplier-productions',
						},
						{
							item: t('supplier-production', { ns: 'naming' }),
						},
					]}
				/>
				<Loader className="font-size-80 mx-auto" />
			</div>
		)
	}

	return (
		<div className="page-content">
			<Breadcrumbs
				title={t('suppliers', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('productions', { ns: 'naming' }),
						link: '/supplier-productions',
					},
					{
						item: t('supplier-production', { ns: 'naming' }),
					},
				]}
			/>
			{status && (
				<div className="bg-white my-3 py-2">
					<ProductionTable
						id={productionDetail.production.displayId}
						status={status}
						disconformity={productionDetail.production.disconformity}
						partialShipment={productionDetail.production.partialShipment}
						productionDate={getDateWithHour(productionDetail.production.orderDate)}
						shipmentDate={productionDetail.production.shipmentDateEstimate}
						delayShipmentDate={productionDetail.production.shipmentDateDelay}
						realShipmentDate={productionDetail.production.shipmentDateReal}
						// chat={productionDetail.production.chatMessages.length} --> TODO ProductionChat no funciona todavía
						weight={productionDetail.production.weight}
						shipmentTracking={shipmentTracking}
						setShipmentTracking={setShipmentTracking}
						shippingCompany={shippingCompany}
						setShippingCompany={setShippingCompany}
						totalPrice={`${roundCurrency(productionDetail.production.totalPrice)}${currency}`}
						discount={productionDetail.production.discount && `${productionDetail.production.discount}%` || ''}
						discountedPrice={`${roundCurrency(productionDetail.production.totalPriceWithDiscount)}${currency}`}
						updateProduction={updateProduction}
						production={productionDetail.production}
						supplierCountry={supplierAccount.personalInformation.country}
					/>
				</div>
			)}
			<div className="my-4 py-2">
				<ProductionButtons
					productionId={productionDetail.production.displayId}
					status={status}
					setStatus={setStatus}
					setOpenFinishProductionModal={setOpenFinishProductionModal}
					setOpenLabelsModal={setOpenLabelsModal}
					productionItemList={productionDetail.production.productionItemList}
					orderId={productionDetail.production.orderId}
					orderFragmentId={productionDetail.production.orderFragmentId}
					supplierId={productionDetail.production.supplierId}
				/>

			</div>
			{productionDetail.production.comment && (
				<div className="bg-white my-4 py-2">
					<ProductionNote note={productionDetail.production.comment} />
				</div>
			)}

			<div className="bg-white my-4 py-2">
				<ProductionPartsTable partsList={itemsToRender} />
			</div>

			<FinishProductionModal
				supplierCountry={supplierAccount.personalInformation.country}
				isOpen={isOpenFinishProductionModal}
				closeModal={closeFinishProductionModal}
				isLoading={isLoading || isLoadingFiles}
				step={finishProductionStep}
				setStep={setFinishProductionStep}
				itemsToRender={itemsToRenderModal}
				handleFinishProduction={handleFinishProduction}
				newShippingCompany={newShippingCompany}
				setNewShippingCompany={setNewShippingCompany}
				newShipmentTracking={newShipmentTracking}
				setNewShipmentTracking={setNewShipmentTracking}
				declaredValue={declaredValue}
				setDeclaredValue={setDeclaredValue}
				setInvoiceFile={setInvoiceFile}
				setPartImagesFiles={setPartImagesFiles}
				newShipmentDate={newShipmentDate}
				setNewShipmentDate={setNewShipmentDate}
				isShippingAllParts={isShippingAllParts}
			/>
			<Modal
				isOpen={isOpenErrorModal}
				closeModal={() => setOpenErrorModal(false)}
				title={t('complete-production-error', { ns: 'errors' })}
			/>
			<Modal
				isOpen={isOpenSuccessModal}
				closeModal={() => setOpenSuccessModal(false)}
				title={t('complete-production-success', { ns: 'naming' })}
			/>
		</div>
	)
}
const getProductionIdFromURL = () => {
	const id = window.location.pathname.split('/')
	return id[id.length - 1]
}
const mapStateToProps = state => {
	return {
		productionDetail: state.Productions.productionDetail,
		supplierAccount: state.Users.account,
		completeProductionSuccess: state.Productions.completeProductionSuccess,
		uploadCompleteProductionFilesSuccess: state.CloudStorage.uploadCompleteProductionFilesSuccess,
		supplierInfo: state.Users.supplierShippingTiming?.supplierInfo,
		isLoading: state.Productions.isLoading,
		error: state.Productions.error,
		isLoadingFiles: state.CloudStorage.isLoading,
		errorUploadingFiles: state.CloudStorage.error,
	}
}
SupplierProduction.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.any,
	isLoadingFiles: PropTypes.bool,
	errorUploadingFiles: PropTypes.any,
	getProductionDetail: PropTypes.func,
	productionDetail: PropTypes.object,
	completeProduction: PropTypes.func,
	completeProductionSuccess: PropTypes.bool,
	uploadCompleteProductionFiles: PropTypes.func,
	uploadCompleteProductionFilesSuccess: PropTypes.bool,
	getAccountByEmail: PropTypes.func,
	supplierAccount: PropTypes.object,
	getSupplierInfo: PropTypes.func,
	supplierInfo: PropTypes.object,
}
export default withGetPartConfigOptions(connect(mapStateToProps, { getProductionDetail, completeProduction, uploadCompleteProductionFiles, getAccountByEmail, getSupplierInfo })(
	withTranslation()(SupplierProduction),
))
