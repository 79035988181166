import { sortByDate } from 'common/Utils/ArrayUtilities'
import { mapManagers } from 'common/Utils/ManagersUtils'
import { hasMandatoryFields } from 'common/Utils/ObjectUtils'
import { getDate, getIdFromUrl, getStatusClassName } from 'common/Utils/StringUtilities'
import Modal from 'components/Common/Modal/Modal'
import OptionsButtons from 'components/Common/OptionsButtons'
import { OptionsInput } from 'components/Common/OptionsInput'
import Switch from 'components/Common/Switch/Switch'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { createNewCrmLog, getDomainOffers, getDomainOrders } from 'store/actions'

export const ModalCreateCrmLog = ({
	domainOffers,
	domainOrders,
	getDomainOrders,
	getDomainOffers,
	accountId,
	simpleButton,
	defaultOfferId,
	afterCreateNewCrmLog,
	createNewCrmLog_Status,
	t,
	managers,
	createNewCrmLog,
	isLoading,
}) => {

	const mapOffers = () => {

		return sortByDate(domainOffers, 'date').map(offer => {
			return {
				value: offer.id,
				label: <div className='d-flex flex-row justify-content-start'>
					<span className='mx-2'>{offer.id} </span>
					<span className='mx-2'>{getDate(offer.date)}</span>
					<span className={'mx-2 ' + getStatusClassName(offer.status)}>{t(`offer_status_${offer.status}`, { ns: 'status' })}</span>
				</div>,
			}
		})
	}

	const mapOrders = () => {
		return sortByDate(domainOrders.map((obj) => obj.order), 'creationDate').map(order => {
			return {
				value: order.id,
				label: <div className='d-flex flex-row justify-content-start'>
					<span className='mx-2'>{order.id} </span>
					<span className='mx-2'>{getDate(order.creationDate)}</span>
					<span className={'mx-2 ' + getStatusClassName(order.statusId)}>{t(`order_status_${order.statusId}`, { ns: 'status' })}</span>
				</div>,
			}
		})
	}
	const getOptions = () => {
		return crmLogData.related !== 'general' ? crmLogData.related === 'quote' ? mapOffers() : mapOrders() : []
	}

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [crmLogData, setCrmLogData] = useState({
		interaction: '',
		related: defaultOfferId == null ? 'general' : 'quote',
		isReclamation: false,
		relatedId: defaultOfferId == null ? null : defaultOfferId,
		interactionType: 'customer_service',
		subject: '',
		message: '',
		manager: null,
		notifyAt: null,
	})
	const [hasPressed, setHasPressed] = useState(false)

	const relatedIdOptions = getOptions()
	const managerOptions = useMemo(() => managers && mapManagers(managers), [managers])

	const mandatoryFields = ['interaction', 'subject', 'message', 'manager', crmLogData.related !== 'general' && 'relatedId']

	const handleOnChange = (key, value) => {
		setCrmLogData({ ...crmLogData, [key]: value })
	}

	const hadleOnChangeRelated = (value) => {
		setCrmLogData({ ...crmLogData, related: value })
	}

	const handleOnChangeInteractionType = (value) => {
		setCrmLogData({ ...crmLogData, interactionType: value })
	}

	useEffect(() => {
		if (!isLoading) {
			if (domainOrders.length === 0) {
				getDomainOrders(accountId || getIdFromUrl())
			}
			if (domainOffers.length === 0) {
				getDomainOffers(accountId || getIdFromUrl())
			}
		}
	}, [isLoading])

	useEffect(() => {
		if (isOpenModal === false) {
			setCrmLogData({
				interaction: '',
				related: 'general',
				isReclamation: false,
				relatedId: null,
				interactionType: 'customer_service',
				subject: '',
				message: '',
				manager: null,
				notifyAt: null,
			})
			setHasPressed(false)
		}
	}, [isOpenModal])

	useEffect(() => {

		handleOnChange('relatedId', crmLogData.related == 'quote' ? defaultOfferId : null)
	}, [crmLogData.related])

	const interctionOptions = [{
		label: t('call', { ns: 'naming' }),
		value: 'call',
	}, {
		label: t('email', { ns: 'naming' }),
		value: 'email',
	}, {
		label: t('chat', { ns: 'naming' }),
		value: 'chat',
	}, {
		label: t('visit', { ns: 'naming' }),
		value: 'visit',
	}, {
		label: t('internal_note', { ns: 'naming' }),
		value: 'internal_note',
	}]

	const relatedWithOptions = [{
		text: t('general', { ns: 'naming' }),
		value: 'general',
		color: 'warning',
	},
	{
		text: t('order', { ns: 'naming' }),
		value: 'order',
		color: 'warning',
	}, {
		text: t('offer', { ns: 'naming' }),
		value: 'quote',
		color: 'warning',
	}]

	const interaction_typeOptions = [{
		text: t('customer_service', { ns: 'naming' }),
		value: 'customer_service',
		color: 'warning',
	}, {
		text: t('comercial_tracking', { ns: 'naming' }),
		value: 'comercial',
		color: 'warning',
	}]

	const handleSave = () => {
		setHasPressed(true)
		if (hasMandatoryFields(mandatoryFields, crmLogData)) {
			const crmInteraction = {
				data: {
					crmInteraction: {
						accountId: getIdFromUrl(),
						type: 'agent',
						subject: crmLogData.subject,
						managerFullName: getManagerFullName(crmLogData.manager),
						...crmLogData,
						isReclamation: crmLogData.interaction == 'internal_note' ? false : crmLogData.isReclamation,
						interactionType: crmLogData.interaction == 'internal_note' ? '' : crmLogData.interactionType,
					},
				},
			}
			createNewCrmLog(crmInteraction)
			setIsOpenModal(false)
		}
	}

	const getManagerFullName = (managerId) => {
		const manager = managers.find(manager => manager.id === managerId)
		return `${manager.personalInformation.firstName} ${manager.personalInformation.lastName}	`
	}

	const messageClassName = hasPressed && crmLogData.message.length === 0 ? 'form-control is-invalid' : 'form-control'

	const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)

	useEffect(() => {
		if (createNewCrmLog_Status.success) {
			if (afterCreateNewCrmLog != null) {
				afterCreateNewCrmLog({
					...createNewCrmLog_Status.response.crmInteraction,
					date: new Date(createNewCrmLog_Status.response.crmInteraction.date),
				})
			}
		} else if (createNewCrmLog_Status.error) {
			setErrorModalIsOpen(true)
		}
	}, [createNewCrmLog_Status.loading])

	return (
		<div>
			{simpleButton ? (
				<button
					className="btn btn-outline-secondary"
					onClick={() => setIsOpenModal(true)}
				>
					<i className="bx bxs-message-alt-add mx-2"></i>
					<span className="py-2">{t('new', { ns: 'naming' })}</span>
				</button>
			) : (
				<div className="d-flex justify-content-between p-2 pt-3 pb-1 mx-2 border-bottom border-light">
					<h6>
						<i className="bx bx-message-rounded-dots"></i> {t('crm_logs', { ns: 'naming' })}
					</h6>
					<h6
						className="text-primary cursor-pointer"
						onClick={e => setIsOpenModal(true)}
					>
						+
					</h6>
				</div>
			)}
			<Modal
				isOpen={isOpenModal}
				title={t('add_crm_log', { ns: 'naming' })}
				closeModal={() => setIsOpenModal(false)}
				body={
					<div className='p-4'>
						<OptionsInput
							value={interctionOptions.find(option => option.value === crmLogData.interaction)}
							setValue={handleOnChange}
							title={t('interaction_type', { ns: 'naming' })}
							options={interctionOptions}
							objectElement="interaction"
							hasPressed={hasPressed}
							isRequired
						/>
						<div className='my-2 mb-3'>
							<h6 className='p-2'>{t('related_with', { ns: 'naming' })}</h6>
							<div>
								<OptionsButtons
									options={relatedWithOptions}
									handleOnChange={hadleOnChangeRelated}
									valueSelected={crmLogData.related}
								/>
							</div>
						</div>
						{
							relatedIdOptions.length > 0 &&
							<OptionsInput
								isRequired
								hasPressed={hasPressed}
								setValue={handleOnChange}
								title={t('related_id', { ns: 'naming' })}
								options={relatedIdOptions}
								objectElement="relatedId"
								value={relatedIdOptions.find(opt => opt.value == crmLogData.relatedId)}
							/>

						}
						{crmLogData.interaction != 'internal_note' && (
							<div>
								<div className='my-2'>
									<Switch
										id="urgent"
										label={t('is_a_reclamation', { ns: 'naming' })}
										onChange={e => handleOnChange('isReclamation', e.target.checked)}
										size="md"
										className="mt-4 mx-2"
										value={crmLogData.isReclamation}
									/>
								</div>
								<div className='pb-2'>
									<h6 className='p-2'>{t('consult_type', { ns: 'naming' })}</h6>
									<OptionsButtons
										options={interaction_typeOptions}
										handleOnChange={handleOnChangeInteractionType}
										valueSelected={crmLogData.interactionType}
									/>
								</div>
							</div>
						)}
						<TextInput
							isRequired
							hasPressed={hasPressed}
							value={crmLogData.subject}
							setValue={handleOnChange}
							title={t('subject', { ns: 'naming' })}
							objectElement="subject"
						/>
						<div className="m-2">
							<label htmlFor='message'><span className="text-primary me-1">✲</span>{t('observations', { ns: 'naming' })}</label>
							<textarea name='message' id='message' cols={20} className={messageClassName} onChange={e => handleOnChange('message', e.target.value)}>
							</textarea>
						</div>
						{
							managers.length > 0 &&
							<OptionsInput
								isRequired
								hasPressed={hasPressed}
								title={t('manager', { ns: 'naming' })}
								value={managerOptions.find(manager => manager.value === crmLogData.manager)}
								options={managerOptions}
								setValue={handleOnChange}
								objectElement="manager"
							/>
						}
						{/* TODO UNCOMENT WHEN inbox page IS DONE  <div className='m-2'>
                            <label className='m-0' htmlFor='notifyAt'>
                                Notificar
                            </label>
                            <Flatpickr
                                value={crmLogData.notifyAt}
                                onChange={e => handleOnChange('notifyAt', e[0])}
                                id={"notifyAt"}
                                className="form-control d-block"
                                options={{
                                    dateFormat: "Y-m-d",
                                    mode: "single",
                                }}
                            />
                        </div> */}

					</div>
				}
				buttons={[<button key={'save'} className='btn btn-primary' onClick={e => handleSave()}>{t('save', { ns: 'naming' })}</button>]}
			/>
			<Modal
				isOpen={errorModalIsOpen}
				closeModal={() => setErrorModalIsOpen(false)}
				title={t('error_message', { ns: 'naming' })}
			/>
		</div>
	)
}

ModalCreateCrmLog.propTypes = {
	domainOrders: PropTypes.array,
	domainOffers: PropTypes.array,
	getDomainOrders: PropTypes.func,
	getDomainOffers: PropTypes.func,
	t: PropTypes.func,
	managers: PropTypes.array,
	createNewCrmLog: PropTypes.func,
	isLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	domainOffers: state.Offers.domainOffers,
	domainOrders: state.Orders.domainOrders,
	managers: state.Users.managers,
	isLoading: state.Users.isCreatingCrmLog,
	createNewCrmLog_Status: state.Crm.createNewCrmLog_Status,
})

const mapDispatchToProps = {
	getDomainOrders,
	getDomainOffers,
	createNewCrmLog,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCreateCrmLog))
