export const GET_SUPPLIERS = 'GET_SUPPLIERS'
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS'
export const GET_SUPPLIERS_FAIL = 'GET_SUPPLIERS_FAIL'
export const GET_SUPPLIERS_BY_ORDER_ID = 'GET_SUPPLIERS_BY_ORDER_ID'
export const GET_SUPPLIERS_BY_ORDER_ID_SUCCESS = 'GET_SUPPLIERS_BY_ORDER_ID_SUCCESS'
export const GET_SUPPLIERS_BY_ORDER_ID_FAIL = 'GET_SUPPLIERS_BY_ORDER_ID_FAIL'
export const GET_MANAGERS = 'GET_MANAGERS'
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS'
export const GET_MANAGERS_FAIL = 'GET_MANAGERS_FAIL'
export const CREATE_NEW_SUPPLIER = 'CREATE_NEW_SUPPLIER'
export const CREATE_NEW_SUPPLIER_SUCCESS = 'CREATE_NEW_SUPPLIER_SUCCESS'
export const CREATE_NEW_SUPPLIER_FAIL = 'CREATE_NEW_SUPPLIER_FAIL'
export const GET_SUPPLIERS_INFO = 'GET_SUPPLIERS_INFO'
export const GET_SUPPLIERS_INFO_SUCCESS = 'GET_SUPPLIERS_INFO_SUCCESS'
export const GET_SUPPLIERS_INFO_FAIL = 'GET_SUPPLIERS_INFO_FAIL'
export const GET_SUPPLIER_DETAIL = 'GET_SUPPLIER_DETAIL'
export const GET_SUPPLIER_DETAIL_SUCCESS = 'GET_SUPPLIER_DETAIL_SUCCESS'
export const GET_SUPPLIER_DETAIL_FAIL = 'GET_SUPPLIER_DETAIL_FAIL'
export const UPDATE_SUPPLIER_DETAIL = 'UPDATE_SUPPLIER_DETAIL'
export const UPDATE_SUPPLIER_DETAIL_SUCCESS = 'UPDATE_SUPPLIER_DETAIL_SUCCESS'
export const UPDATE_SUPPLIER_DETAIL_FAIL = 'UPDATE_SUPPLIER_DETAIL_FAIL'
export const GET_ACCOUNT_BY_EMAIL = 'GET_ACCOUNT_BY_EMAIL'
export const GET_ACCOUNT_BY_EMAIL_SUCCESS = 'GET_ACCOUNT_BY_EMAIL_SUCCESS'
export const GET_ACCOUNT_BY_EMAIL_FAIL = 'GET_ACCOUNT_BY_EMAIL_FAIL'
export const GET_SUPPLIER_INFO = 'GET_SUPPLIER_INFO'
export const GET_SUPPLIER_INFO_SUCCESS = 'GET_SUPPLIER_INFO_SUCCESS'
export const GET_SUPPLIER_INFO_FAIL = 'GET_SUPPLIER_INFO_FAIL'
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_FAIL = 'GET_CUSTOMERS_FAIL'
export const CREATE_NEW_SHIPPING_ADDRESS = 'CREATE_NEW_SHIPPING_ADDRESS'
export const CREATE_NEW_SHIPPING_ADDRESS_SUCCESS = 'CREATE_NEW_SHIPPING_ADDRESS_SUCCESS'
export const CREATE_NEW_SHIPPING_ADDRESS_FAIL = 'CREATE_NEW_SHIPPING_ADDRESS_FAIL'
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS'
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS'
export const UPDATE_SHIPPING_ADDRESS_FAIL = 'UPDATE_SHIPPING_ADDRESS_FAIL'
export const UPDATE_PAYMENT_POSTPONEMENT = 'UPDATE_PAYMENT_POSTPONEMENT'
export const UPDATE_PAYMENT_POSTPONEMENT_SUCCESS = 'UPDATE_PAYMENT_POSTPONEMENT_SUCCESS'
export const UPDATE_PAYMENT_POSTPONEMENT_FAIL = 'UPDATE_PAYMENT_POSTPONEMENT_FAIL'
export const GET_DOMAIN_USERS = 'GET_DOMAIN_USERS'
export const GET_DOMAIN_USERS_SUCCESS = 'GET_DOMAIN_USERS_SUCCESS'
export const GET_DOMAIN_USERS_FAIL = 'GET_DOMAIN_USERS_FAIL'
export const UPDATE_ACTIVE_SUPPLIER = 'UPDATE_ACTIVE_SUPPLIER'
export const UPDATE_ACTIVE_SUPPLIER_SUCCESS = 'UPDATE_ACTIVE_SUPPLIER_SUCCESS'
export const UPDATE_ACTIVE_SUPPLIER_FAIL = 'UPDATE_ACTIVE_SUPPLIER_FAIL'
export const UPDATE_DASHBOARD_COUNTRY_LIST = 'UPDATE_DASHBOARD_COUNTRY_LIST'
export const GET_MANAGER_LIST = 'GET_MANAGER_LIST'
export const GET_MANAGER_LIST_SUCCESS = 'GET_MANAGER_LIST_SUCCESS'
export const GET_MANAGER_LIST_FAIL = 'GET_MANAGER_LIST_FAIL'
export const GET_MANAGER_DETAIL_BY_ID = 'GET_MANAGER_DETAIL_BY_ID'
export const GET_MANAGER_DETAIL_SUCCESS = 'GET_MANAGER_DETAIL_SUCCESS'
export const GET_MANAGER_DETAIL_FAIL = 'GET_MANAGER_DETAIL_FAIL'
export const UPDATE_MANAGER_BY_ID = 'UPDATE_MANAGER_BY_ID'
export const UPDATE_MANAGER_BY_ID_SUCCESS = 'UPDATE_MANAGER_BY_ID_SUCCESS'
export const UPDATE_MANAGER_BY_ID_FAIL = 'UPDATE_MANAGER_BY_ID_FAIL'
export const CREATE_NEW_MANAGER = 'CREATE_NEW_MANAGER'
export const CREATE_NEW_MANAGER_SUCCESS = 'CREATE_NEW_MANAGER_SUCCESS'
export const CREATE_NEW_MANAGER_FAIL = 'CREATE_NEW_MANAGER_FAIL'
export const GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID'
export const GET_ORGANIZATION_BY_ID_SUCCESS = 'GET_ORGANIZATION_BY_ID_SUCCESS'
export const GET_ORGANIZATION_BY_ID_FAIL = 'GET_ORGANIZATION_BY_ID_FAIL'

export const CREATE_NEW_HOLIDAY = 'CREATE_NEW_HOLIDAY'
export const CREATE_NEW_HOLIDAY_SUCCESS = 'CREATE_NEW_HOLIDAY_SUCCESS'
export const CREATE_NEW_HOLIDAY_FAIL = 'CREATE_NEW_HOLIDAY_FAIL'

export const GET_HOLIDAYS = 'GET_HOLIDAYS'
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS'
export const GET_HOLIDAYS_FAIL = 'GET_HOLIDAYS_FAIL'

export const UPDATE_HOLIDAY_BY_ID = 'UPDATE_HOLIDAY_BY_ID'
export const UPDATE_HOLIDAY_BY_ID_SUCCESS = 'UPDATE_HOLIDAY_BY_ID_SUCCESS'
export const UPDATE_HOLIDAY_BY_ID_FAIL = 'UPDATE_HOLIDAY_BY_ID_FAIL'

export const DELETE_HOLIDAY_BY_ID = 'DELETE_HOLIDAY_BY_ID'
export const DELETE_HOLIDAY_BY_ID_SUCCESS = 'DELETE_HOLIDAY_BY_ID_SUCCESS'
export const DELETE_HOLIDAY_BY_ID_FAIL = 'DELETE_HOLIDAY_BY_ID_FAIL'

export const GET_USERS_DASHBOARD = 'GET_USERS_DASHBOARD'
export const GET_USERS_DASHBOARD_SUCCESS = 'GET_USERS_DASHBOARD_SUCCESS'
export const GET_USERS_DASHBOARD_FAIL = 'GET_USERS_DASHBOARD_FAIL'



export const REMOVE_MANAGER_COMMISSION_BY_ID = 'REMOVE_MANAGER_COMMISSION_BY_ID'
export const REMOVE_MANAGER_COMMISSION_BY_ID_SUCCESS = 'REMOVE_MANAGER_COMMISSION_BY_ID_SUCCESS'
export const REMOVE_MANAGER_COMMISSION_BY_ID_FAIL = 'REMOVE_MANAGER_COMMISSION_BY_ID_FAIL'
export const GET_COUNTRIES_DASHBOARD = 'GET_COUNTRIES_DASHBOARD'
export const GET_COUNTRIES_DASHBOARD_SUCCESS = 'GET_COUNTRIES_DASHBOARD_SUCCESS'
export const GET_COUNTRIES_DASHBOARD_FAIL = 'GET_COUNTRIES_DASHBOARD_FAIL'

export const GET_MANAGERS_STATISTICS_BY_IDS = 'GET_MANAGERS_STATISTICS_BY_IDS'
export const GET_MANAGERS_STATISTICS_BY_IDS_SUCCESS = 'GET_MANAGERS_STATISTICS_BY_IDS_SUCCESS'
export const GET_MANAGERS_STATISTICS_BY_IDS_FAIL = 'GET_MANAGERS_STATISTICS_BY_IDS_FAIL'

export const GET_NOTIFICATIONS_BY_ACCOUNT_ID = 'GET_NOTIFICATIONS_BY_ACCOUNT_ID'
export const GET_NOTIFICATIONS_BY_ACCOUNT_ID_SUCCESS = 'GET_NOTIFICATIONS_BY_ACCOUNT_ID_SUCCESS'
export const GET_NOTIFICATIONS_BY_ACCOUNT_ID_FAIL = 'GET_NOTIFICATIONS_BY_ACCOUNT_ID_FAIL'

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS'
export const UPDATE_NOTIFICATIONS_FAIL = 'UPDATE_NOTIFICATIONS_FAIL'
