import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import ExpeditionOrders from './ExpeditionOrders/ExpeditionOrders'
import ExpeditionSearchAndClientinfo from './ExpeditionsSearchAndClientInfo/ExpeditionSearchAndClientInfo'
import ExpeditionOrderInfo from './OrderInfo/ExpeditionOrderInfo'

const Expeditions = ({ t, selectedExpeditionOrderId }) => {
	const [selectedOrder, setSelectedOrder] = useState(null)
	const [manualScanned, setManualScanned] = useState(null)

	useEffect(() => {
		if (selectedExpeditionOrderId) {
			setSelectedOrder(selectedExpeditionOrderId)
		}
	}, [selectedExpeditionOrderId])

	return (
		<div className="page-content marginFooter">
			<MetaTags>
				<title>Proto&Go! | {t('expeditions', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('logistics', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('expeditions', { ns: 'naming' }),
						link: '/expeditions',
					},
				]}
			/>
			<div className="d-flex flex-row align-items-start">
				<div className="col-3 d-flex flex-column">
					<ExpeditionOrders />
					<ExpeditionSearchAndClientinfo />
				</div>
				<div className="col-9 p-4 mb-3 mx-2 bg-white">
					<ExpeditionOrderInfo
						selectedExpeditionOrderId={selectedOrder}
						setManualScanned={setManualScanned}
						manualScanned={manualScanned}
					/>
				</div>
			</div>

		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		selectedExpeditionOrderId: state.Orders.selectedExpeditionOrderId,
	}
}

Expeditions.propTypes = {
	t: PropTypes.func,
	selectedExpeditionOrderId: PropTypes.any,
}

export default connect(mapStateToProps)(
	withTranslation()(Expeditions),
)
