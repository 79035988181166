import {
	CREATE_NEW_HOLIDAY,
	CREATE_NEW_HOLIDAY_FAIL,
	CREATE_NEW_HOLIDAY_SUCCESS,
	CREATE_NEW_MANAGER,
	CREATE_NEW_MANAGER_FAIL,
	CREATE_NEW_MANAGER_SUCCESS,
	CREATE_NEW_SHIPPING_ADDRESS,
	CREATE_NEW_SHIPPING_ADDRESS_FAIL,
	CREATE_NEW_SHIPPING_ADDRESS_SUCCESS,
	CREATE_NEW_SUPPLIER,
	CREATE_NEW_SUPPLIER_FAIL,
	CREATE_NEW_SUPPLIER_SUCCESS,
	DELETE_HOLIDAY_BY_ID,
	DELETE_HOLIDAY_BY_ID_FAIL,
	DELETE_HOLIDAY_BY_ID_SUCCESS,
	GET_ACCOUNT_BY_EMAIL,
	GET_ACCOUNT_BY_EMAIL_FAIL,
	GET_ACCOUNT_BY_EMAIL_SUCCESS,
	GET_COUNTRIES_DASHBOARD,
	GET_COUNTRIES_DASHBOARD_FAIL,
	GET_COUNTRIES_DASHBOARD_SUCCESS,
	GET_CUSTOMERS,
	GET_CUSTOMERS_FAIL,
	GET_CUSTOMERS_SUCCESS,
	GET_DOMAIN_USERS,
	GET_DOMAIN_USERS_FAIL,
	GET_DOMAIN_USERS_SUCCESS,
	GET_HOLIDAYS,
	GET_HOLIDAYS_FAIL,
	GET_HOLIDAYS_SUCCESS,
	GET_MANAGERS,
	GET_MANAGERS_FAIL,
	GET_MANAGERS_STATISTICS_BY_IDS,
	GET_MANAGERS_STATISTICS_BY_IDS_FAIL,
	GET_MANAGERS_STATISTICS_BY_IDS_SUCCESS,
	GET_MANAGERS_SUCCESS,
	GET_MANAGER_DETAIL_BY_ID,
	GET_MANAGER_DETAIL_FAIL,
	GET_MANAGER_DETAIL_SUCCESS,
	GET_MANAGER_LIST,
	GET_MANAGER_LIST_FAIL,
	GET_MANAGER_LIST_SUCCESS,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID_FAIL,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID_SUCCESS,
	GET_ORGANIZATION_BY_ID,
	GET_ORGANIZATION_BY_ID_FAIL,
	GET_ORGANIZATION_BY_ID_SUCCESS,

	GET_SUPPLIERS,
	GET_SUPPLIERS_BY_ORDER_ID,
	GET_SUPPLIERS_BY_ORDER_ID_FAIL,
	GET_SUPPLIERS_BY_ORDER_ID_SUCCESS,
	GET_SUPPLIERS_FAIL,
	GET_SUPPLIERS_INFO,
	GET_SUPPLIERS_INFO_FAIL,
	GET_SUPPLIERS_INFO_SUCCESS,
	GET_SUPPLIERS_SUCCESS,
	GET_SUPPLIER_DETAIL,
	GET_SUPPLIER_DETAIL_FAIL,
	GET_SUPPLIER_DETAIL_SUCCESS,
	GET_SUPPLIER_INFO,
	GET_SUPPLIER_INFO_FAIL,
	GET_SUPPLIER_INFO_SUCCESS,
	GET_USERS_DASHBOARD,
	GET_USERS_DASHBOARD_FAIL,
	GET_USERS_DASHBOARD_SUCCESS,
	REMOVE_MANAGER_COMMISSION_BY_ID,
	REMOVE_MANAGER_COMMISSION_BY_ID_FAIL,
	REMOVE_MANAGER_COMMISSION_BY_ID_SUCCESS,
	UPDATE_ACTIVE_SUPPLIER,
	UPDATE_ACTIVE_SUPPLIER_FAIL,
	UPDATE_ACTIVE_SUPPLIER_SUCCESS,
	UPDATE_DASHBOARD_COUNTRY_LIST,
	UPDATE_HOLIDAY_BY_ID,
	UPDATE_HOLIDAY_BY_ID_FAIL,
	UPDATE_HOLIDAY_BY_ID_SUCCESS,
	UPDATE_MANAGER_BY_ID,
	UPDATE_MANAGER_BY_ID_FAIL,
	UPDATE_MANAGER_BY_ID_SUCCESS,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_FAIL,
	UPDATE_NOTIFICATIONS_SUCCESS,
	UPDATE_PAYMENT_POSTPONEMENT,
	UPDATE_PAYMENT_POSTPONEMENT_FAIL,
	UPDATE_PAYMENT_POSTPONEMENT_SUCCESS,
	UPDATE_SHIPPING_ADDRESS,
	UPDATE_SHIPPING_ADDRESS_FAIL,
	UPDATE_SHIPPING_ADDRESS_SUCCESS,
	UPDATE_SUPPLIER_DETAIL,
	UPDATE_SUPPLIER_DETAIL_FAIL,
	UPDATE_SUPPLIER_DETAIL_SUCCESS
} from './actionTypes'

const INIT_STATE = {
	suppliers: [],
	notifications: null,
	newProductionSuppliers: [],
	managers: [],
	error: null,
	managersStatisticts: null,
	isCreatingManager: false,
	isLoading: false,
	isSupplierCreated: null,
	isPaymentPostponementUpdated: null,
	domainUsers: [],
	supplierDetail: {},
	isSupplierSuccessfullyUpdated: false,
	isLoadingUpdateActiveSupplier: [],
	updateActiveSupplierResponse: null,
	supplierDetailList: [],
	dashboardCountryList: [],
	managerList: [],
	managerDetail: null,
	errorUpdateManager: null,
	errorCreateManager: null,
	organization: null,
	holidaysList: [],
	errorUpdateHoliday: null,
	errorCreateHoliday: null,
	isCreatingHoliday: false,
	dashboardUsers: [],
	dashboardStats: null,
	previousDashboardStats: null,
	isLoadingDashboard: false,
	newManagerId: null,
	countriesDashboardStats: null,
	countriesDashboardPreviousStats: null,

}

const users = (state = INIT_STATE, action) => {

	switch (action.type) {

		case UPDATE_NOTIFICATIONS:
			return {
				...state,
				error: null,
				isLoading: true,
			}

		case UPDATE_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				notifications: state.notifications.map(notification => {
					const updatedNotification = action.payload.notifications.find(item => item.id === notification.id)
					if (updatedNotification != null) {

						return {
							...notification,
							...updatedNotification
						}
					}
					return notification
				}),
				error: null,
				isLoading: false,
			}

		case UPDATE_NOTIFICATIONS_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoading: false,
			}

		case GET_NOTIFICATIONS_BY_ACCOUNT_ID:
			return {
				...state,
				error: null,
				isLoading: true,
			}

		case GET_NOTIFICATIONS_BY_ACCOUNT_ID_SUCCESS:
			return {
				...state,
				notifications: action.payload.notifications,
				error: null,
				isLoading: false,
			}

		case GET_NOTIFICATIONS_BY_ACCOUNT_ID_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}
		case GET_MANAGERS_STATISTICS_BY_IDS:
			return {
				...state,
				error: null,
				isLoading: true,
			}

		case GET_MANAGERS_STATISTICS_BY_IDS_SUCCESS:
			return {
				...state,
				managersStatisticts: action.payload.response,
				error: null,
				isLoading: false,
			}

		case GET_MANAGERS_STATISTICS_BY_IDS_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case GET_HOLIDAYS:
			return {
				...state,
				isLoading: true,
				error: null,
			}

		case GET_HOLIDAYS_SUCCESS:

			return {
				...state,
				holidaysList: action.payload.holidays,
				error: null,
				isLoading: false,
			}

		case GET_HOLIDAYS_FAIL:
			return {
				...state,
				holidaysList: [],
				error: action.payload,
				isLoading: false,
			}

		case CREATE_NEW_HOLIDAY:
			return {
				...state,
				errorCreateHoliday: null,
				isCreatingHoliday: true,
				isLoading: true,
			}

		case CREATE_NEW_HOLIDAY_SUCCESS:

			return {
				...state,
				errorCreateHoliday: false,
				isCreatingHoliday: true,
				isLoading: false,
				holidaysList: [...state.holidaysList, action.payload],

			}

		case CREATE_NEW_HOLIDAY_FAIL:
			return {
				...state,
				errorCreateHoliday: action.payload.message,
				isCreatingHoliday: false,
				isLoading: false,
			}

		case UPDATE_HOLIDAY_BY_ID:
			return {
				...state,
				errorUpdateHoliday: null,
				isLoading: true,
			}

		case UPDATE_HOLIDAY_BY_ID_SUCCESS:
			return {
				...state,
				errorUpdateHoliday: false,
				isLoading: false,
			}

		case UPDATE_HOLIDAY_BY_ID_FAIL:
			return {
				...state,
				errorUpdateHoliday: action.payload.message,
				isLoading: false,
			}

		case REMOVE_MANAGER_COMMISSION_BY_ID:
			return {
				...state,
				error: null,
				isLoading: true,
			}

		case REMOVE_MANAGER_COMMISSION_BY_ID_SUCCESS:
			return {
				...state,
				error: false,
				isLoading: false,
			}

		case REMOVE_MANAGER_COMMISSION_BY_ID_FAIL:
			return {
				...state,
				error: true,
				isLoading: false,
			}


		case DELETE_HOLIDAY_BY_ID:
			return {
				...state,
				errorDeleteHoliday: null,
				isLoading: true,
			}

		case DELETE_HOLIDAY_BY_ID_SUCCESS:
			return {
				...state,
				errorDeleteHoliday: false,
				isLoading: false,
			}

		case DELETE_HOLIDAY_BY_ID_FAIL:
			return {
				...state,
				errorDeleteHoliday: action.payload.message,
				isLoading: false,
			}

		case CREATE_NEW_MANAGER:
			return {
				...state,
				errorCreateManager: null,
				isLoading: true,
				isCreatingManager: true,
			}
		case CREATE_NEW_MANAGER_SUCCESS:
			return {
				...state,
				errorCreateManager: false,
				isLoading: false,
				isCreatingManager: true,
				newManagerId: action.payload.account.id
			}
		case CREATE_NEW_MANAGER_FAIL:
			return {
				...state,
				errorCreateManager: action.payload.message,
				isLoading: false,
				isCreatingManager: false,
			}

		case UPDATE_MANAGER_BY_ID:
			return {
				...state,
				errorUpdateManager: null,
				isLoading: true,
				isManagerSuccessfullyUpdated: null,
			}
		case UPDATE_MANAGER_BY_ID_SUCCESS:

			return {
				...state,
				errorUpdateManager: null,
				isLoading: false,
				isManagerSuccessfullyUpdated: true,
			}

		case UPDATE_MANAGER_BY_ID_FAIL:
			return {
				...state,
				errorUpdateManager: action.payload.message,
				isLoading: false,
				isManagerSuccessfullyUpdated: false,
			}

		case GET_SUPPLIERS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_SUPPLIERS_SUCCESS:
			return {
				...state,
				suppliers: action.payload.accounts,
				error: null,
				isLoading: false,
			}

		case GET_SUPPLIERS_FAIL:
			return {
				...state,
				suppliers: [],
				error: action.payload,
				isLoading: false,
			}
		case GET_SUPPLIERS_BY_ORDER_ID:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_SUPPLIERS_BY_ORDER_ID_SUCCESS:
			return {
				...state,
				newProductionSuppliers: action.payload.suppliers,
				error: null,
				isLoading: false,
			}

		case GET_SUPPLIERS_BY_ORDER_ID_FAIL:
			return {
				...state,
				newProductionSuppliers: [],
				error: action.payload,
				isLoading: false,
			}

		case GET_MANAGERS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_MANAGERS_SUCCESS:
			return {
				...state,
				managers: action.payload,
				error: null,
				isLoading: false,
			}

		case GET_MANAGERS_FAIL:
			return {
				...state,
				managers: [],
				error: action.payload,
				isLoading: false,
			}

		case GET_MANAGER_DETAIL_BY_ID:

			return {
				...state,
				isLoading: true,
				error: null,
				managerDetail: null,
			}
		case GET_MANAGER_DETAIL_SUCCESS:

			return {
				...state,
				managerDetail: action.payload.manager,
				error: null,
				isLoading: false,
			}

		case GET_MANAGER_DETAIL_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoading: false,
				managerDetail: null,
			}

		case CREATE_NEW_SUPPLIER:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case CREATE_NEW_SUPPLIER_SUCCESS:
			return {
				...state,
				error: false,
				isLoading: false,
				isSupplierCreated: true,
			}
		case CREATE_NEW_SUPPLIER_FAIL:
			return {
				...state,
				error: action.payload.message,
			}
		case GET_SUPPLIERS_INFO:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_SUPPLIERS_INFO_SUCCESS:
			return {
				...state,
				supplierDetailList: action.payload,
				error: null,
				isLoading: false,
			}

		case GET_SUPPLIERS_INFO_FAIL:
			return {
				...state,
				supplierDetailList: [],
				error: action.payload.message,
				isLoading: false,
			}
		case GET_SUPPLIER_DETAIL:
			return {
				...state,
				isLoadingDetail: true,
				error: null,
				supplierDetail: {},
			}
		case GET_SUPPLIER_DETAIL_SUCCESS:
			return {
				...state,
				supplierDetail: action.payload.supplier,
				error: null,
				isLoadingDetail: false,
			}

		case GET_SUPPLIER_DETAIL_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoadingDetail: false,
				supplierDetail: null,
			}
		case UPDATE_SUPPLIER_DETAIL:
			return {
				...state,
				error: null,
				isLoading: true,
				isSupplierSuccessfullyUpdated: null,
			}
		case UPDATE_SUPPLIER_DETAIL_SUCCESS:
			return {
				...state,
				error: null,
				isLoading: false,
				isSupplierSuccessfullyUpdated: true,
			}
		case UPDATE_SUPPLIER_DETAIL_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoading: false,
				isSupplierSuccessfullyUpdated: false,
			}

		case GET_ACCOUNT_BY_EMAIL:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_ACCOUNT_BY_EMAIL_SUCCESS:
			return {
				...state,
				account: action.payload.account,
				error: null,
				isLoading: false,
			}

		case GET_ACCOUNT_BY_EMAIL_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case GET_SUPPLIER_INFO:
			return {
				...state,
				supplierShippingTiming: undefined,
				error: null,
			}
		case GET_SUPPLIER_INFO_SUCCESS:
			return {
				...state,
				supplierShippingTiming: action.payload,
				error: false,
			}
		case GET_SUPPLIER_INFO_FAIL:
			return {
				...state,
				error: action.payload,
			}
		case GET_CUSTOMERS:
			return {
				...state,
				error: null,
			}
		case GET_CUSTOMERS_SUCCESS:
			return {
				...state,
				customers: action.payload.accounts,
				error: false,
			}
		case GET_CUSTOMERS_FAIL:
			return {
				...state,
				error: action.payload,
			}
		case CREATE_NEW_SHIPPING_ADDRESS:
			return {
				...state,
				error: null,
			}
		case CREATE_NEW_SHIPPING_ADDRESS_SUCCESS:
			return {
				...state,
				error: false,
			}
		case CREATE_NEW_SHIPPING_ADDRESS_FAIL:
			return {
				...state,
				error: true,
			}
		case UPDATE_SHIPPING_ADDRESS:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case UPDATE_SHIPPING_ADDRESS_SUCCESS:
			return {
				...state,
				error: false,
				isLoading: false,

			}
		case UPDATE_SHIPPING_ADDRESS_FAIL:
			return {
				...state,
				error: true,
				isLoading: false,
			}
		case UPDATE_PAYMENT_POSTPONEMENT:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case UPDATE_PAYMENT_POSTPONEMENT_SUCCESS:
			return {
				...state,
				isLoading: false,
				isPaymentPostponementUpdated: true,
			}
		case UPDATE_PAYMENT_POSTPONEMENT_FAIL:
			return {
				...state,
				error: true,
				isPaymentPostponementUpdated: false,
			}

		case GET_DOMAIN_USERS:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case GET_DOMAIN_USERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				domainUsers: action.payload,
			}
		case GET_DOMAIN_USERS_FAIL:
			return {
				...state,
				error: action.payload.error.message,
				isLoading: false,
			}

		case GET_MANAGER_LIST:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case GET_MANAGER_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				managerList: action.payload,
				error: null,
			}
		case GET_MANAGER_LIST_FAIL:
			return {
				...state,
				error: action.payload.error.message,
				isLoading: false,
			}

		case UPDATE_ACTIVE_SUPPLIER:
			return {
				...state,
				errorUpdate: null,
				isLoadingUpdateActiveSupplier: [...state.isLoadingUpdateActiveSupplier, action.payload.data.account.id],
			}
		case UPDATE_ACTIVE_SUPPLIER_SUCCESS:
			return {
				...state,
				isLoadingUpdateActiveSupplier: state.isLoadingUpdateActiveSupplier.filter(id => id !== action.payload.data.account.id),
				supplierDetailList: state.supplierDetailList.map(supplier => {
					if (supplier.account.id === action.payload.data.account.id) {
						return {
							...supplier,
							account: action.payload.data.account,
						}
					}
					return supplier
				}),
			}
		case UPDATE_ACTIVE_SUPPLIER_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoadingUpdateActiveSupplier: [],
			}
		case UPDATE_DASHBOARD_COUNTRY_LIST:
			return {
				...state,
				countryList: action.payload.dashboardCountryList,
			}

		case GET_ORGANIZATION_BY_ID:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_ORGANIZATION_BY_ID_SUCCESS:
			return {
				...state,
				organization: action.payload.organization,
				error: null,
				isLoading: false,
			}
		case GET_ORGANIZATION_BY_ID_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case GET_USERS_DASHBOARD:
			return {
				...state,
				isLoadingDashboard: true,
				error: null,
			}
		case GET_USERS_DASHBOARD_SUCCESS:
			return {
				...state,
				dashboardUsers: action.payload.dashboardUsers,
				dashboardStats: action.payload.dashboardStats,
				previousDashboardStats: action.payload.previousDashboardStats,
				countryList: action.payload.countryList,
				error: null,
				isLoadingDashboard: false,
			}
		case GET_USERS_DASHBOARD_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoadingDashboard: false,
			}
		case GET_COUNTRIES_DASHBOARD:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case GET_COUNTRIES_DASHBOARD_SUCCESS:
			return {
				...state,
				dashboardCountryList: action.payload.items,
				countriesDashboardStats: action.payload.stats,
				countriesDashboardPreviousStats: action.payload.previousStats,
				error: null,
				isLoading: false,
			}
		case GET_COUNTRIES_DASHBOARD_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoading: false,
			}

		default:
			return state
	}
}

export default users
