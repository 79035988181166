import classNames from 'classnames'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Card, CardBody, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import BillingDashboardContainer from './BillingDashboard/BillingDashboardContainer'
import DashboardFiltersModal from './CommonComponents/DashboardFiltersModal'
import DateFilter from './CommonComponents/DateFilter'
import CountriesDashboardContainer from './CountriesDashboard/CountriesDashboardContainer'
import Intrastat from './Intrastat/Intrastat'
import OffersDashboardContainer from './OffersDashboard/OffersDashboardContainer'
import PurchasesDashboardContainer from './PurchasesDashboard/PurchasesDashboardContainer'
import TechnologiesDashboardContainer from './TechnologiesDashboard/TechnologiesDashboardContainer'
import UsersDashboardContainer from './UsersDashboard/UsersDashboardContainer'
import { BILLING_CHART, COUNTRIES_CHART, INITIAL_DATE_RANGE, INITIAL_FILTERS, INTRASTAT, OFFERS_CHART, PURCHASES_CHART, TECHNOLOGIES_CHART, USERS_CHART } from './constants'
export const DashboardsContainer = ({ t }) => {

	const [activeTab, setActiveTab] = useState(BILLING_CHART)
	const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE)
	const [filters, setFilters] = useState(INITIAL_FILTERS)

	return (
		<div className='page-content marginFooter'><div>
			<MetaTags>
				<title>Proto&Go! | {t('reports', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('homepage', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('reports', { ns: 'naming' }),
						link: '/reports',
					},
				]}
			/>
		</div>
			<div className='p-0 ms-2'>
				<Card>
					<div className='d-flex align-items-center '>
						<DateFilter dateRange={dateRange} setDateRange={setDateRange} />
						<DashboardFiltersModal setFilters={setFilters} filters={filters} activeTab={activeTab} />
					</div>
					<CardBody>
						<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
							<NavItem>
								<NavLink
									className={classNames({
										active: activeTab === BILLING_CHART,
									})}
									onClick={() => {
										setActiveTab(BILLING_CHART)
									}}
								>
									{t('billing_dashboard', { ns: 'naming' })}
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									className={classNames({
										active: activeTab === OFFERS_CHART,
									})}
									onClick={() => {
										setActiveTab(OFFERS_CHART)
									}}
								>
									{t('offers', { ns: 'naming' })}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classNames({
										active: activeTab === USERS_CHART,
									})}
									onClick={() => {
										setActiveTab(USERS_CHART)
									}}
								>
									{t('users', { ns: 'naming' })}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classNames({
										active: activeTab === TECHNOLOGIES_CHART,
									})}
									onClick={() => {
										setActiveTab(TECHNOLOGIES_CHART)
									}}
								>
									{t('technologies', { ns: 'naming' })}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classNames({
										active: activeTab === COUNTRIES_CHART,
									})}
									onClick={() => {
										setActiveTab(COUNTRIES_CHART)
									}}
								>
									{t('countries', { ns: 'naming' })}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classNames({
										active: activeTab === PURCHASES_CHART,
									})}
									onClick={() => {
										setActiveTab(PURCHASES_CHART)
									}}
								>
									{t('purchases', { ns: 'naming' })}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classNames({
										active: activeTab === INTRASTAT,
									})}
									onClick={() => {
										setActiveTab(INTRASTAT)
									}}
								>
									{t('intrastat', { ns: 'naming' })}
								</NavLink>
							</NavItem>
						</ul>

						<TabContent
							activeTab={activeTab}
						>
							<TabPane tabId={BILLING_CHART} id="billingDashBoard">
								{activeTab === BILLING_CHART && <BillingDashboardContainer dateRange={activeTab === BILLING_CHART ? dateRange : undefined} filters={filters} />}
							</TabPane>

						</TabContent>

						<TabContent
							activeTab={activeTab}
						>
							<TabPane tabId={INTRASTAT} id="IntrastatDashBoard">
								{activeTab === INTRASTAT && <Intrastat dateRange={activeTab === INTRASTAT ? dateRange : undefined} />}
							</TabPane>

						</TabContent>

						<TabContent
							activeTab={activeTab}
						>
							<TabPane tabId={OFFERS_CHART} id="OffersDashBoard">
								{activeTab === OFFERS_CHART && <OffersDashboardContainer dateRange={activeTab === OFFERS_CHART ? dateRange : undefined} filters={filters} />}
							</TabPane>

						</TabContent>
						<TabContent
							activeTab={activeTab}
						>
							<TabPane tabId={USERS_CHART} id="UsersDashBoard">
								{activeTab === USERS_CHART && <UsersDashboardContainer dateRange={activeTab === USERS_CHART ? dateRange : undefined} filters={filters} />}
							</TabPane>

						</TabContent>
						<TabContent
							activeTab={activeTab}
						>
							<TabPane tabId={TECHNOLOGIES_CHART} id="TechnologiesDashBoard">
								{activeTab === TECHNOLOGIES_CHART && <TechnologiesDashboardContainer dateRange={activeTab === TECHNOLOGIES_CHART ? dateRange : undefined} filters={filters} />}
							</TabPane>

						</TabContent>

						<TabContent
							activeTab={activeTab}
						>
							<TabPane tabId={COUNTRIES_CHART} id="billingDashBoard">
								{activeTab === COUNTRIES_CHART && <CountriesDashboardContainer dateRange={activeTab === COUNTRIES_CHART ? dateRange : undefined} filters={filters} />}
							</TabPane>

						</TabContent>
						<TabContent
							activeTab={activeTab}
						>
							<TabPane tabId={PURCHASES_CHART} id="PurchasesDashBoard">
								{activeTab === PURCHASES_CHART && <PurchasesDashboardContainer dateRange={activeTab === PURCHASES_CHART ? dateRange : undefined} filters={filters} />}
							</TabPane>

						</TabContent>
					</CardBody>
				</Card>

			</div>
		</div>
	)
}

DashboardsContainer.propTypes = {
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardsContainer))
