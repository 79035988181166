import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import OrderInfo from './ReceptionOrderInfo/OrderInfo'
import ReceptionOrders from './ReceptionOrders/ReceptionOrders'

const Receptions = ({ t, selectedOrderId }) => {
	const [selectedOrder, setSelectedOrder] = useState(null)
	const [manualScanned, setManualScanned] = useState(null)
	const [isOpenAsignBoxModal, setIsOpenAssignBoxModal] = useState(false)

	useEffect(() => {
		if (selectedOrderId) {
			setSelectedOrder(selectedOrderId)
		}
	}, [selectedOrderId])

	return (
		<div className="page-content marginFooter">
			<MetaTags>
				<title>Proto&Go! | {t('receptions', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('logistics', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('receptions', { ns: 'naming' }),
						link: '/receptions',
					},
				]}
			/>
			<div className="d-flex flex-row align-items-start">
				<div className="col-3 d-flex flex-column">
					<ReceptionOrders
						isOpenAsignBoxModal={isOpenAsignBoxModal}
						setIsOpenAssignBoxModal={setIsOpenAssignBoxModal}
						manualScanned={manualScanned}
						setManualScanned={setManualScanned}
					/>
				</div>
				<div className="col-9 p-4 mb-3 mx-2 bg-white">
					<OrderInfo
						selectedOrderId={selectedOrder}
						setIsOpenAssignBoxModal={setIsOpenAssignBoxModal}
					/>
				</div>

			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		selectedOrderId: state.Orders.selectedOrderId,
	}
}

Receptions.propTypes = {
	t: PropTypes.func,
	selectedOrderId: PropTypes.any,

}

export default connect(mapStateToProps)(
	withTranslation()(Receptions),
)
