import { useCouriers } from 'common/Hooks/UseTranslatedCouriers'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { getDateWithHour } from 'common/Utils/StringUtilities'
import { IconTooltip } from 'components/Common/IconTooltip'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { PartName } from 'components/Common/PartName'
import Select from 'components/Common/Select/Select'
import SimpleTable from 'components/Common/SimpleTable'
import TextInput from 'components/Common/TextInput'
import TooltipMessage from 'components/Common/TooltipMessage'
import { Couriers } from 'constants/couriers'
import { OrderStatus } from 'constants/orderStatus'
import { packagesSize } from 'constants/packagesSize'
import { UPS_EXPRESS, UPS_EXPRESS_SAVER } from 'constants/upsValues'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Alert, FormGroup, Input } from 'reactstrap'
import { DEL_CLI, E_NACEX, NACEX_10, NACEX_12, NUM_CLI, PACKAGING } from '../../../constants/nacexValues'
import {
	addExpeditionToOrder,
	createNewNacexExpedition,
	createNewUpsExpedition,
	createShipmentExport,
	getAllOrderShipmentFiles,
	getExpeditionNacexLabels,
	getExpeditionUpsLabels,
	getLastThreeDaysShipments,
	getSuppliers,
	handleCompleteProduction,
	handleSentOrder,
	manageScannedQuantityOnDelivery,
	resetExpeditionOrderBoxCode,
	resetNacexInfo,
	resetScannedPiecesFromAllExpeditionPieces,
	resetScannedPiecesFromExpeditionPiece,
	scanOrderExpeditionPart,
	setCompletedExpeditionOrder,
	setExpeditionBoxLabelGenerated,
	setExpeditionCommentReaded,
	setExpeditionDeliveryNotePrinted,
	setExpeditionOrderBoxCode,
	updateBoxesByRef,
	updateOrder,
	updateOrderExpeditionInfo,
	updateOrderExpeditionShipment,
	updateOrderItem,
	uploadOrderShipmentFiles,
} from '../../../store/actions'
import BoxLabelPrinter from './BoxLabelPrinter'
import ModalPrintDeliveryNote from './ModalPrintDeliveryNote'

const SelectedExpeditionOrder = ({
	orderInfo,
	generatedPdf,
	uploadOrderShipmentFiles,
	generatedLabel,
	getSuppliers,
	suppliers,
	resetScannedPiecesFromExpeditionPiece,
	resetScannedPiecesFromAllExpeditionPieces,
	setExpeditionOrderBoxCode,
	createNewNacexExpedition,
	nacexExpeditionInfo,
	setCompletedExpeditionOrder,
	addExpeditionToOrder,
	manageScannedQuantityOnDelivery,
	boxList,
	updateBoxesByRef,
	updateOrder,
	scanOrderExpeditionPart,
	setExpeditionCommentReaded,
	setExpeditionBoxLabelGenerated,
	setExpeditionDeliveryNotePrinted,
	updateOrderExpeditionInfo,
	lastScannedPart,
	createShipmentExport,
	updateOrderItem,
	getLastThreeDaysShipments,
	lastThreeDaysShipments,
	resetExpeditionOrderBoxCode,
	resetNacexInfo,
	createNewUpsExpedition,
	upsExpeditionInfo,
	selectedExpeditionOrderId,
	expeditionOrderList,
	getExpeditionNacexLabels,
	getExpeditionUpsLabels,
	shipmentExport,
	uploadedFiles,
	handleCompleteProduction,
	updateOrderExpeditionShipment,
	getAllOrderShipmentFiles,
	handleSentOrder,
	scanOrderExpeditionPart_Status,
}) => {
	const { t } = useTranslation()
	const [openModal, setOpenModal] = useState(false)
	const [openModalComment, setOpenModalComment] = useState(false)
	const [isOpenDeliveryNoteModal, setIsOpenDeliveryNoteModal] = useState(false)
	const [isOpenDeliveryModal, setIsOpenDeliveryModal] = useState(false)
	const [deliveryGenerated, setDeliveryGenerated] = useState(false)
	const [modalBoxInputValue, setModalBoxInputValue] = useState('')
	const [totalPieces, setTotalPieces] = useState(0)
	const [nacexForm, setNacexForm] = useState({})
	const [manualShippingForm, setManualShippingForm] = useState({})
	const [combineForm, setCombineForm] = useState({})
	const [formType, setFormType] = useState(0)
	const [hasPressedSend, setHasPressedSend] = useState(false)
	const [boxAssignedList, setBoxAssignedList] = useState([])
	const [errorNacexForm, setErrorNacexForm] = useState(false)
	const [saveDeliveryNoteInS3, setSaveDeliveryNoteInS3] = useState(false)
	const [isAlreadyRequestedLabel, setIsAlreadyRequestedLabel] = useState(false)
	const [isShipmentFilesSaved, setIsShipmentFilesSaved] = useState(false)

	const [labelShipmentToSave, setLabelShipmentToSave] = useState(null)
	const commentChecked = orderInfo?.expeditionInfo?.readCommentsExpedition
	const alabaranGenerated = orderInfo?.expeditionInfo?.printedDeliveryNote
	const boxLabelDownloaded = orderInfo?.expeditionInfo?.printedLabels

	const { COURIER, COURIER_URL } = useCouriers(t)

	const handleGetLabel = (shipment) => {
		setLabelShipmentToSave(shipment)
		switch (shipment?.transporter?.id) {
			case Couriers.NACEX:
				handleGetNacexLabels(shipment)
				return
			case Couriers.UPS:
				handleGetUpsLabels(shipment)
				return
		}
	}

	const expeditions = useMemo(() => expeditionOrderList?.find((order) => order.id === selectedExpeditionOrderId?.orderId)?.shipments || [], [expeditionOrderList, selectedExpeditionOrderId, uploadedFiles])
	const expeditionsHeader = ['date', 'courier', 'tracking', 'delivery-note', 'label', 'weight']
	const getParsedExpeditions = () => {
		return expeditions.map((expedition) => {
			return <tr key={`shipment_${expedition.id}`} valign="middle">
				<td>{getDateWithHour(expedition.shippingDate)}</td>
				<td>{COURIER[expedition?.transporter?.id]}</td>
				<td>{expedition?.transporter?.id === Couriers.HAND_DELIVERY ? (expedition.trackingNumber) : (<a
					href={COURIER_URL[expedition?.transporter?.id] + expedition.trackingNumber}
					target="_blank"
					rel="noopener noreferrer"
				>{expedition.trackingNumber}</a>)}</td>
				<td>{expedition.deliveryNote?.src != null && (
					<a
						href={expedition.deliveryNote.src}
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconTooltip
							key={`delivery_note_${expedition.id}`}
							name={`delivery_note_${expedition.id}`}
							icon="bx bxs-file fs-3"
							message={expedition.deliveryNote.name}
							position="top"
						/>
					</a>
				)}</td>
				<td>{expedition.label?.src != null ? (
					<a
						href={expedition.label.src}
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconTooltip
							key={`label_${expedition.id}`}
							name={`label_${expedition.id}`}
							icon="bx bxs-box fs-3"
							message={expedition.label.name}
							position="top"
						/>
					</a>
				) : (
					<IconTooltip
						key={`label_${expedition.id}`}
						name={`label_${expedition.id}`}
						icon="bx bxs-box fs-3"
						message={t('generate_label', { ns: 'naming' })}
						position="top"
						onClick={() => handleGetLabel(expedition)}
					/>
				)}</td>
				<td>{expedition.weight < 1000 ? `${roundAtDecimals(expedition.weight, 0)} g` : `${roundAtDecimals(expedition.weight / 1000, 2)} kg`}</td>
			</tr>
		})
	}

	const totalScannedPartsWeight = useMemo(() => {
		let total = 0
		orderInfo?.orderItems?.forEach((item) => {
			total += (item?.scannedQuantity || 0) * (item?.part?.weight || 0)
		})
		return total.toFixed(2)
	}, [orderInfo])

	useEffect(() => {
		if (totalScannedPartsWeight != null && nacexForm.kil == null) {
			setNacexForm((prevNacexForm) => ({
				...prevNacexForm,
				kil: (parseFloat(totalScannedPartsWeight) / 1000).toFixed(2),
			}))
		}
	}, [nacexForm, totalScannedPartsWeight])
	const SPAIN = 'ES'
	const totalScannedParts = useMemo(() => {
		let total = 0
		orderInfo?.orderItems?.forEach((item) => {
			total += item.scannedQuantity
		})
		return total
	}, [orderInfo])
	const ELEMENTS_TABLE_HEADER = [
		'item',
		'image',
		'ID',
		'name',
		'sent/total',
		'scanned/pending',
		'',
	]

	setTimeout(() => {
		const miElemento = document.getElementById('tablaOrderInfo')
		if (miElemento) {
			const rows = miElemento.getElementsByTagName('tr')
			if (rows) {
				const rowsArray = Array.from(rows)
				rowsArray.shift()
				rowsArray.forEach((row) => {
					const tds = row.getElementsByTagName('td')
					if (tds) {
						const tdArray = Array.from(tds)
						const tdPartId = tdArray[2]?.innerText
						if (tdPartId == lastScannedPart?.partId) {
							tdArray.forEach((td) => {
								td.style.backgroundColor = 'lightblue'
							})
							tdArray[2].scrollIntoView({ behavior: 'smooth', block: 'center' })
						} else {
							tdArray.forEach(td => {
								td.style.backgroundColor = 'white'
							})
						}
					}
				})
			}
		}
	}, 0)

	const getTotalPieces = (items) => {
		let total = 0
		items.forEach((item) => {
			total += item.quantity
		})
		setTotalPieces(total)
	}

	const [isOpenSimulateScanModal, setIsOpenSimulateScanModal] = useState(false)
	const [simulateScanModalOrderItem, setSimulateScanModalOrderItem] = useState(null)
	const [simulateScanModalOptions, setSimulateScanModalOptions] = useState([])
	const [simulateScanModalQuantity, setSimulateScanModalQuantity] = useState(1)

	useEffect(() => {
		if (scanOrderExpeditionPart_Status.loading == false) {
			if (scanOrderExpeditionPart_Status.success == true) {
				setIsOpenSimulateScanModal(false)
				const newOrderInfoState = { ...orderInfoState }
				const index = orderInfoState?.orderItems?.findIndex(x => x.id == simulateScanModalOrderItem?.id)
				if (index != -1 && newOrderInfoState?.orderItems?.[index] != null) {
					newOrderInfoState.orderItems[index].scannedQuantity += simulateScanModalQuantity
					setOrderInfoState(newOrderInfoState)
				}
			} else if (scanOrderExpeditionPart_Status.error != null) {
				alert(t('error_message', { ns: 'naming' }))
			}
		}
	}, [scanOrderExpeditionPart_Status.loading])

	const SimulateScanModal = useMemo(() => (
		<Modal
			isOpen={isOpenSimulateScanModal}
			closeModal={() => setIsOpenSimulateScanModal(false)}
			title={t('scanPart', { ns: 'naming' })}
			closeButtonColor="secondary"
			body={
				<div className="d-flex mt-5 mb-5 mx-auto align-items-center gap-2">
					{t('quantity', { ns: 'naming' })}{':'}
					<br />
					<Select
						style={{ width: '8em' }}
						value={simulateScanModalQuantity}
						onChange={e => {
							setSimulateScanModalQuantity(Number(e.currentTarget.value))
						}}
						options={simulateScanModalOptions}
						classNamePrefix="select2-selection"
					/>
				</div>
			}
			buttons={[(
				<button
					key={'saveScannedQuantityButton'}
					className='btn btn-primary'
					onClick={() => {
						scanOrderExpeditionPart({
							data: {
								orderId: simulateScanModalOrderItem.orderId,
								partId: simulateScanModalOrderItem.part.id,
								scannedQuantity: simulateScanModalQuantity,
							},
						})
					}}
					disabled={scanOrderExpeditionPart_Status.loading}
				>
					{scanOrderExpeditionPart_Status.loading ? (
						<div className="d-flex justify-content-center align-items-center">
							<Loader />
						</div>
					) : t('save', { ns: 'naming' })}
				</button>
			)]}
		/>
	), [
		isOpenSimulateScanModal,
		simulateScanModalOrderItem,
		simulateScanModalOptions,
		simulateScanModalQuantity,
	])

	const parseOrder = (orderItems, orderDetails) => {
		if (Object.keys(orderDetails)?.length === 0) return null
		const parsedElements = []
		orderItems.forEach((element, index) => {
			const shippedQuantity = element.shippedQuantity
			const elementQuantity = element.quantity
			const pendingQuantity = elementQuantity - shippedQuantity >= 0 ? elementQuantity - shippedQuantity : 0
			const parsedElement = {
				item: (index + 1).toString() + '.',
				image: <img src={element.part.imageUrl} alt="PartImg" height={50} width={50} />,
				id: element.part.id,
				name: <div className='d-flex flex-row align-items-center'>
					<PartName name={element.part.name} maxLength={50} />
					{element.qualityState == null && <IconTooltip key='part-not-verified' icon="bx bxs-error ms-2 text-primary" message={t('part-not-verified', { ns: 'naming' })} position="top" name={`NotVerifiedPart_${element.part.id}`} />}
				</div>,
				sentAndTotal: shippedQuantity >= elementQuantity ? <div className="d-flex align-items-center">
					<span style={{ textDecoration: 'line-through' }}>{shippedQuantity} {t('of', { ns: 'naming' })} {elementQuantity} {t('pieces', { ns: 'naming' })}</span>
					<i className="mdi mdi-check-circle ms-2" style={{ color: 'green' }} />
				</div> : `${shippedQuantity} ${t('of', { ns: 'naming' })} ${elementQuantity} ${t('pieces', { ns: 'naming' })}`,
				scannedAndPending: element.scannedQuantity >= pendingQuantity ? <div className="d-flex align-items-center">
					<span style={{ textDecoration: 'line-through' }}> {element.scannedQuantity} {t('of', { ns: 'naming' })} {pendingQuantity} {t('pieces', { ns: 'naming' })}</span>
					<i className="mdi mdi-check-circle ms-2" style={{ color: 'green' }} />
				</div> : element.scannedQuantity + ' ' + t('of', { ns: 'naming' }) + ' ' + pendingQuantity + ' ' + t('pieces', { ns: 'naming' }),
				actions: (
					<div className='d-flex gap-1 justify-content-end'>
						{element.scannedQuantity < pendingQuantity &&
							<TooltipMessage place="top" message={t('scanPart', { ns: 'naming' })}>
								<button
									style={{ backgroundColor: 'transparent', border: '1px solid black', borderRadius: '4px', color: 'black', width: '30px', height: '30px' }}
									className="btn btn-sm align-self-center mt-2"
									onClick={() => {
										setSimulateScanModalOrderItem(element)
										setSimulateScanModalOptions(Array.from({
											length: pendingQuantity - element?.scannedQuantity,
										})?.map((_, i) => ({
											label: i + 1, value: i + 1,
										})))
										setIsOpenSimulateScanModal(true)
									}}
								>
									<i className='bx bx-station'></i>
								</button>
							</TooltipMessage>
						}
						<button
							style={{ backgroundColor: 'transparent', border: '1px solid black', borderRadius: '4px', color: 'black', width: '30px', height: '30px' }}
							className="btn btn-sm align-self-center mt-2"
							onClick={() => { resetScannedPiecesFromExpeditionPiece(element.part.id, orderDetails.id); scanOrderExpeditionPart({ data: { orderId: orderDetails.id, partId: element.part.id, scannedQuantity: -(element.scannedQuantity) } }) }}
						>
							<i className="mdi mdi-refresh" />
						</button>
					</div>
				),
			}
			parsedElements.push(parsedElement)
		})
		return parsedElements
	}

	const [orderInfoState, setOrderInfoState] = useState(orderInfo)
	useEffect(() => {
		console.log('setOrderInfoState(orderInfo)')
		setOrderInfoState(orderInfo)
	}, [orderInfo])

	const TableMemo = useMemo(() => {
		const parsedOrderInfo = parseOrder(orderInfoState?.orderItems, orderInfoState)
		const getMainTableBody = () => {
			return parsedOrderInfo.map((orderItem) => {
				return <tr key={`item_${orderItem.id}`} valign="middle">
					<td>{orderItem.item}</td>
					<td>{orderItem.image}</td>
					<td>{orderItem.id}</td>
					<td>{orderItem.name}</td>
					<td>{orderItem.sentAndTotal}</td>
					<td>{orderItem.scannedAndPending}</td>
					<td>{orderItem.actions}</td>
				</tr>
			})
		}
		return Object.keys(parsedOrderInfo).length !== 0 && (
			<>
				<div id="tablaOrderInfo" style={{ border: '0.5px solid lightgray', height: '100%' }}>
					<SimpleTable
						header={ELEMENTS_TABLE_HEADER}
						getTableBody={() => getMainTableBody()}
						striped={false}
					/>
				</div>
			</>
		)
	}, [orderInfoState])

	function checkIfFormCompleted() {
		if (orderInfo?.shippingAddress?.country == SPAIN) {
			if (nacexForm.bul && nacexForm.tip_cob && nacexForm.nom_ent && nacexForm.dir_ent && nacexForm.pais_ent && nacexForm.cp_ent && nacexForm.pob_ent && nacexForm.tel_ent && nacexForm.tip_ser) {
				return true
			} else {
				return false
			}
		}
		else {
			let isCompleted = false
			if (nacexForm.bul && nacexForm.tip_cob && nacexForm.nom_ent && nacexForm.dir_ent && nacexForm.pais_ent && nacexForm.cp_ent && nacexForm.pob_ent && nacexForm.tel_ent && nacexForm.tip_ser) {
				for (let i = 0; i < nacexForm.bul; i++) {
					if (!nacexForm['packageSize' + i]) {
						return false
					}
				}
				isCompleted = true
			}
			return isCompleted
		}
	}

	function formatNacexFormValues() {
		setNacexForm({
			...nacexForm,
			bul: nacexForm.bul.toString().padStart(3, '0'),
		})
	}

	function handleUnassignOrderBoxes() {
		updateBoxesByRef({
			data: {
				boxes: boxAssignedList.map((box) => ({
					ref: box.ref,
					orderId: null,
				})),
			},
		})
		resetExpeditionOrderBoxCode(orderInfo?.id)
	}

	function resetAllPieces() {
		orderInfo?.orderItems?.forEach((item) => {
			scanOrderExpeditionPart({
				data: {
					orderId: orderInfo?.id,
					partId: item?.part?.id,
					scannedQuantity: -(item?.scannedQuantity),
				},
			})
		})
	}
	function handleClickManual() {
		const todayDate = new Date().toISOString().slice(0, 10)
		createShipmentExport({ data: { shipmentExport: { orderId: orderInfo?.id, shippingDate: todayDate, weight: totalScannedPartsWeight, shippingCost: 0, trackingNumber: manualShippingForm.trackingNumber, transporter: { id: Number(manualShippingForm.carrier) } } } })
		manageScannedQuantityOnDelivery(orderInfo?.id)
		orderInfo?.orderItems?.forEach((item) => {
			updateOrderItem({ data: { orderItem: { orderId: orderInfo?.id, partId: item?.part?.id, shippedQuantity: item?.shippedQuantity + item?.scannedQuantity, scannedQuantity: 0 } } })
		})
		setCompletedExpeditionOrder(orderInfo?.id)
		setManualShippingForm({})
		handleCompleteProduction({ data: { orderItems: orderInfo?.orderItems } })
		const isCompleted = orderInfo?.orderItems?.every((item) => item?.scannedQuantity >= (item?.quantity - item?.shippedQuantity))
		if (isCompleted) {
			const order = {
				id: orderInfo?.id,
				partialShipment: false,
				statusId: OrderStatus.SENT,
				deliveryDate: new Date(),
			}
			updateOrder({ data: { order } })
			handleUnassignOrderBoxes()
		} else {
			const order = {
				id: orderInfo?.id,
				partialShipment: true,
			}
			updateOrder({ data: { order } })
		}
		handleSentOrder({ orderId: orderInfo?.id })
	}

	function handleClickCombine() {
		const todayDate = new Date().toISOString().slice(0, 10)
		const selectedShipping = lastThreeDaysShipments?.find((shipment) => shipment?.id == combineForm.shipmentId)
		createShipmentExport({ data: { shipmentExport: { orderId: orderInfo?.id, shippingDate: todayDate, weight: totalScannedPartsWeight, shippingCost: 0, trackingNumber: selectedShipping?.trackingNumber, transporter: { id: selectedShipping?.transporter?.id } } } })
		manageScannedQuantityOnDelivery(orderInfo?.id)
		orderInfo?.orderItems?.forEach((item) => {
			updateOrderItem({ data: { orderItem: { orderId: orderInfo?.id, partId: item?.part?.id, shippedQuantity: item?.shippedQuantity + item?.scannedQuantity, scannedQuantity: 0 } } })
		},
		)
		setCompletedExpeditionOrder(orderInfo?.id)
		setCombineForm({})
		handleCompleteProduction({ data: { orderItems: orderInfo?.orderItems } })
		const isCompleted = orderInfo?.orderItems?.every((item) => item?.scannedQuantity >= (item?.quantity - item?.shippedQuantity))
		if (isCompleted) {
			const order = {
				id: orderInfo?.id,
				partialShipment: false,
				statusId: OrderStatus.SENT,
				deliveryDate: new Date(),
			}
			updateOrder({ data: { order } })
			handleUnassignOrderBoxes()
		} else {
			const order = {
				id: orderInfo?.id,
				partialShipment: true,
			}
			updateOrder({ data: { order } })
		}
	}

	function getPackages() {
		const packages = []
		for (let i = 0; i < nacexForm.bul; i++) {
			const packageSize = packagesSize.find((packageSize) => packageSize.value == nacexForm['packageSize' + i])
			packages.push({
				dimensions: {
					height: packageSize.height,
					width: packageSize.width,
					length: packageSize.depth,
				},
				packagingCode: nacexForm.tip_env == '07' ? packageSize.codeExp : packageSize.codeSta,
				packageWeight: String(((totalScannedPartsWeight / 1000) / nacexForm.bul).toFixed(2)),
			})
		}
		return packages
	}

	async function handleCreationUPSExpedition() {
		if (checkIfFormCompleted()) {
			createNewUpsExpedition({
				data: {
					shipmentChargeType: nacexForm.tip_cob,
					shipmentTypeCode: String(nacexForm.tip_ser),
					shipmentDescription: String(orderInfo?.id) + '_Pedido',
					packages: getPackages(),
					customerData: {
						companyName: nacexForm.per_ent,
						attentionName: nacexForm.nom_ent,
						phoneNumber: nacexForm.tel_ent,
						email: orderInfo?.shippingAddress?.email,
					},
					address: {
						addressLine: [nacexForm.dir_ent],
						city: nacexForm.pob_ent,
						countryCode: nacexForm.pais_ent,
						postalCode: nacexForm.cp_ent,
					},
				},
			})
		}
	}

	async function handleCreationNacexExpedition() {
		if (checkIfFormCompleted()) {
			formatNacexFormValues()
			await createNewNacexExpedition({
				data: {
					del_cli: DEL_CLI,
					num_cli: NUM_CLI,
					tip_ser: nacexForm.tip_ser,
					tip_cob: nacexForm.tip_cob,
					tip_env: PACKAGING.BOX,
					bul: nacexForm.bul,
					kil: nacexForm.kil,
					nom_ent: nacexForm.nom_ent,
					dir_ent: nacexForm.dir_ent,
					pais_ent: nacexForm.pais_ent,
					cp_ent: nacexForm.cp_ent,
					pob_ent: nacexForm.pob_ent,
					tel_ent: nacexForm.tel_ent,
					modelo: nacexForm.modelo,
					obs1: nacexForm.obs1,
					per_ent: nacexForm.per_ent,
					ref_cli: nacexForm.ref_cli,
				},
			})
		}
	}

	function updateNacexForm(key, value) {
		setNacexForm({ ...nacexForm, [key]: value })
	}

	function updateManualShippingForm(key, value) {
		setManualShippingForm({ ...manualShippingForm, [key]: value })
	}

	useEffect(() => {
		if (nacexExpeditionInfo) {
			if (!nacexExpeditionInfo?.response?.expeditionCode) setErrorNacexForm(true)

			else {
				setErrorNacexForm(false)
				const todayDate = new Date().toISOString().slice(0, 10)
				createShipmentExport({ data: { shipmentExport: { orderId: orderInfo?.id, shippingDate: todayDate, weight: totalScannedPartsWeight, shippingCost: 0, trackingNumber: nacexExpeditionInfo?.response?.trackingNumber, transporter: { id: Couriers.NACEX } } } })
				manageScannedQuantityOnDelivery(orderInfo?.id)
				orderInfo?.orderItems?.forEach((item) => {
					updateOrderItem({ data: { orderItem: { orderId: orderInfo?.id, partId: item?.part?.id, shippedQuantity: item?.shippedQuantity + item?.scannedQuantity, scannedQuantity: 0 } } })
				})
				setCompletedExpeditionOrder(orderInfo?.id)
				setManualShippingForm({})
				handleCompleteProduction({ data: { orderItems: orderInfo?.orderItems } })
				const isCompleted = orderInfo?.orderItems?.every((item) => item?.scannedQuantity >= (item?.quantity - item?.shippedQuantity))
				if (isCompleted) {
					const order = {
						id: orderInfo?.id,
						partialShipment: false,
						statusId: OrderStatus.SENT,
						deliveryDate: new Date(),
					}
					updateOrder({ data: { order } })
					handleUnassignOrderBoxes()
				} else {
					const order = {
						id: orderInfo?.id,
						partialShipment: true,
					}
					updateOrder({ data: { order } })
				}
				handleSentOrder({ orderId: orderInfo?.id })
				setIsOpenDeliveryModal(false)
				setFormType(0)
				resetNacexInfo()
			}

		}
	}, [nacexExpeditionInfo])

	useEffect(() => {
		if (shipmentExport != null && !isAlreadyRequestedLabel) {
			setIsAlreadyRequestedLabel(true)
			setIsShipmentFilesSaved(false)
			if (shipmentExport.transporter.id === Couriers.NACEX) {
				handleGetNacexLabels(shipmentExport)
			} else {
				handleGetUpsLabels(shipmentExport)
			}

		}
	}, [shipmentExport, isAlreadyRequestedLabel])

	const handleGetNacexLabels = (expedition) => {
		const trackingNumber = expedition?.trackingNumber
		getExpeditionNacexLabels({
			data: {
				agencyCode: '0802',
				deliveryNoteNumber: trackingNumber,
			},
		})
	}

	const handleGetUpsLabels = (expedition) => {
		const trackingNumber = expedition?.trackingNumber
		getExpeditionUpsLabels({
			data: {
				trackingNumber,
				translateCode: '01',
				dialectCode: 'US',
				languageCode: 'eng',
			},
		})
	}

	useEffect(() => {
		if (generatedLabel != null && labelShipmentToSave != null) {
			const labelSrc = URL.createObjectURL(generatedLabel)
			const labelName = `Label_${labelShipmentToSave.id}.pdf`
			updateOrderExpeditionShipment(orderInfo?.id, { ...labelShipmentToSave, label: { src: labelSrc, name: labelName } })
			uploadOrderShipmentFiles({
				label: new File([generatedLabel], labelName, { type: 'application/pdf' }),
				data: {
					shipmentId: labelShipmentToSave.id,
					orders: [{
						orderId: selectedExpeditionOrderId?.orderId,
						labelName: generatedLabel?.filename,
					}],
				},
			})
		}
	}, [generatedLabel, labelShipmentToSave])

	useEffect(() => {
		if (generatedPdf != null && shipmentExport != null && !isShipmentFilesSaved) {
			setIsShipmentFilesSaved(true)
			const deliveryNoteSrc = URL.createObjectURL(generatedPdf.pdf)

			addExpeditionToOrder(orderInfo?.id, {
				...shipmentExport,
				deliveryNote: { src: deliveryNoteSrc, name: generatedPdf.filename },
				label: null,
			})
			uploadOrderShipmentFiles({
				deliveryNotes: [generatedPdf.pdf],
				data: {
					shipmentId: shipmentExport.id,
					orders: [{
						orderId: selectedExpeditionOrderId?.orderId,
						deliveryNoteName: generatedPdf?.filename,
					}],
				},
			})
		}
	}, [generatedPdf, shipmentExport])

	useEffect(() => {
		if (upsExpeditionInfo) {
			if (!upsExpeditionInfo?.response?.ShipmentResponse?.ShipmentResults?.ShipmentIdentificationNumber) setErrorNacexForm(true)
			else {
				setErrorNacexForm(false)
				const todayDate = new Date().toISOString().slice(0, 10)
				createShipmentExport({ data: { shipmentExport: { orderId: orderInfo?.id, shippingDate: todayDate, weight: totalScannedPartsWeight, shippingCost: 0, trackingNumber: upsExpeditionInfo?.response?.ShipmentResponse?.ShipmentResults?.ShipmentIdentificationNumber, transporter: { id: Couriers.UPS } } } })
				manageScannedQuantityOnDelivery(orderInfo?.id)
				orderInfo?.orderItems?.forEach((item) => {
					updateOrderItem({ data: { orderItem: { orderId: orderInfo?.id, partId: item?.part?.id, shippedQuantity: item?.shippedQuantity + item?.scannedQuantity, scannedQuantity: 0 } } })
				})
				setCompletedExpeditionOrder(orderInfo?.id)
				setManualShippingForm({})
				handleCompleteProduction({ data: { orderItems: orderInfo?.orderItems } })
				const isCompleted = orderInfo?.orderItems?.every((item) => item?.scannedQuantity >= (item?.quantity - item?.shippedQuantity))
				if (isCompleted) {
					const order = {
						id: orderInfo?.id,
						partialShipment: false,
						statusId: OrderStatus.SENT,
						deliveryDate: new Date(),
					}
					updateOrder({ data: { order } })
					handleUnassignOrderBoxes()
				}
				else {
					const order = {
						id: orderInfo?.id,
						partialShipment: true,
					}
					updateOrder({ data: { order } })
				}
				handleSentOrder({ orderId: orderInfo?.id })
				setIsOpenDeliveryModal(false)
				setFormType(0)
				resetNacexInfo()
			}
		}
	}, [upsExpeditionInfo])

	useEffect(() => {
		if (Object.keys(suppliers).length == 0) {
			getSuppliers()
		}
	}, [])

	useEffect(() => {
		if (Object.keys(orderInfo)?.length !== 0) {
			getTotalPieces(orderInfo?.orderItems)
			const addedBoxes = orderInfo?.boxCode?.split(',')
			const boxes = []
			addedBoxes?.forEach((box) => {
				boxList?.forEach((boxItem) => {
					if (boxItem.ref == box.trim()) {
						boxes.push(boxItem)
					}
				},
				)
			})
			setBoxAssignedList(boxes)
			setNacexForm({
				...nacexForm,
				dir_ent: orderInfo?.shippingAddress?.address1,
				pob_ent: orderInfo?.shippingAddress?.city,
				pais_ent: orderInfo?.shippingAddress?.country,
				cp_ent: orderInfo?.shippingAddress?.postalCode,
				tel_ent: orderInfo?.shippingAddress?.phoneNumber,
				per_ent: orderInfo?.shippingAddress?.firstName + ' ' + orderInfo?.shippingAddress?.lastName,
				nom_ent: orderInfo?.shippingAddress.company,
				ref_cli: `PEDIDO ${orderInfo?.id}`,
			})
			getLastThreeDaysShipments(orderInfo?.accountId)
		}
	}, [orderInfo])

	useEffect(() => {
		getAllOrderShipmentFiles(orderInfo.id)
	}, [selectedExpeditionOrderId])

	const handleGenerateBoxLabel = () => {
		setExpeditionBoxLabelGenerated(orderInfo?.id)
		updateOrderExpeditionInfo({ data: { orderExpeditionInfo: { orderId: orderInfo?.id, printedLabels: true } } })
	}

	const getDefaultTipSer = () => {
		let tipSer = UPS_EXPRESS
		if (orderInfo?.shippingAddress?.country == SPAIN) {
			tipSer = E_NACEX
		} else if (new Date() < new Date(orderInfo.estimatedShipmentDate)) {
			tipSer = UPS_EXPRESS_SAVER
		}
		setNacexForm((prevNacexForm) => ({ ...prevNacexForm, tip_ser: tipSer }))
		return tipSer
	}

	const defaultTipSer = useMemo(() => {
		return getDefaultTipSer()
	}, [orderInfo])

	const closeOpenDeliveryModal = () => {
		setIsOpenDeliveryModal(false)
		setFormType(0)
		setErrorNacexForm(false)
	}

	return (
		<>
			<div
				className="w-100 p-2 d-flex align-items-center"
				style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
			>
				<h5 className="pt-2">{t('activeOrder', { ns: 'naming' })} #{orderInfo?.id} | {orderInfo?.shippingAddress?.company}</h5>
			</div>
			<div className="w-100 p-2 d-flex align-items-center" style={{ border: '0.5px solid lightgray' }}>
				<div className="col-8 d-flex d-row">
					<h4 className="pt-2">
						<i className="bx bx-box me-2" />
						{t('assignedBoxes', { ns: 'naming' })}: <strong>{orderInfo?.boxCode || t('noBoxes', { ns: 'naming' })}</strong>
					</h4>
				</div>
				<div className="col-4 d-flex justify-content-end">
					<button
						style={{ border: '0px', borderRadius: '4px', color: 'gray' }}
						className="p-2 d-flex justify-content-center align-items-center"
						onClick={() => setOpenModal(true)}
					>
						<p className="m-0" onClick={() => setOpenModal(true)}>
							{t('addExtraBox', { ns: 'naming' })}<i className="mdi mdi-arrow-right ms-2" />
						</p>
					</button>
				</div>
			</div>
			<div className="d-flex d-row w-100 p-2 align-items-center justify-content-between" style={{ border: '0.5px solid lightgray' }}>
				<p className="pt-3">{t('orderElements', { ns: 'naming' })}</p>
				<div className="d-flex d-row">
					<button
						style={{ border: '1px solid', borderColor: 'transparent', borderRadius: '4px', color: 'gray', width: '30px', height: '30px' }}
						className="d-flex justify-content-center align-items-center me-2"
						onClick={() => { resetScannedPiecesFromAllExpeditionPieces(orderInfo?.id); resetAllPieces() }}
					>
						<i className="mdi mdi-refresh" style={{ fontSize: '15px' }} />
					</button>
					<button
						style={{
							border: '1px solid',
							borderColor: commentChecked == true ? 'transparent' : 'orange',
							borderRadius: '4px',
							color: commentChecked == true ? 'gray' : 'white',
							backgroundColor: commentChecked == true ? '' : 'orange',
							width: '30px',
							height: '30px',
						}}
						className="d-flex justify-content-center align-items-center me-2"
						onClick={() => { setOpenModalComment(true); setExpeditionCommentReaded(orderInfo?.id); updateOrderExpeditionInfo({ data: { orderExpeditionInfo: { orderId: orderInfo?.id, readCommentsExpedition: true } } }) }}
					>
						<i className="mdi mdi-email-outline" style={{ fontSize: '15px' }} />
					</button>
					<BoxLabelPrinter boxLabelDownloaded={boxLabelDownloaded} handleGenerateBoxLabel={handleGenerateBoxLabel} orderInfo={orderInfo} />
					<button
						style={{
							border: '1px solid',
							borderColor: alabaranGenerated == true ? 'transparent' : 'orange',
							borderRadius: '4px',
							color: alabaranGenerated == true ? 'gray' : 'orange',
							backgroundColor: alabaranGenerated == true ? '' : 'transparent',
							width: '30px',
							height: '30px',
						}}
						className="d-flex justify-content-center align-items-center me-2"
						onClick={() => { setIsOpenDeliveryNoteModal(true); setExpeditionDeliveryNotePrinted(orderInfo?.id); updateOrderExpeditionInfo({ data: { orderExpeditionInfo: { orderId: orderInfo?.id, printedDeliveryNote: true } } }) }}
					>
						<i className="bx bxs-truck" style={{ fontSize: '15px' }} />
					</button>
					<button
						style={{
							border: '1px solid',
							borderColor: deliveryGenerated ? 'transparent' : 'orange',
							borderRadius: '4px',
							color: deliveryGenerated ? 'gray' : 'orange',
							backgroundColor: deliveryGenerated ? '' : 'transparent',
							width: 'fit-content',
							height: '30px',
						}}
						className="d-flex justify-content-center align-items-center me-2"
						onClick={() => { setSaveDeliveryNoteInS3(true); setDeliveryGenerated(true); setIsOpenDeliveryModal(true) }}
					>
						{t('createDelivery', { ns: 'naming' })}
					</button>
				</div>
			</div>
			<div className="w-100">
				{TableMemo}
				<div
					className="w-100 ps-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					<p className="pt-3">{t('thisOrdersContains', { ns: 'naming' })} {orderInfo?.orderItems?.length} {t('referencesFromATotalOf', { ns: 'naming' })} {totalPieces} {t('pieces', { ns: 'naming' })}</p>
				</div>
			</div>
			{expeditions.length !== 0 && (
				<>
					<div
						className="w-100 mt-4 p-2 d-flex align-items-center"
						style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
					>
						<h5 className="pt-2">{t('shipments', { ns: 'naming' })}</h5>
					</div>
					<div style={{
						border: '0.5px solid lightgray',
						borderBottomLeftRadius: '4px',
						borderBottomRightRadius: '4px',
						overflowY: 'auto',
					}}>
						<SimpleTable
							key="order_shipments_table"
							header={expeditionsHeader}
							getTableBody={() => getParsedExpeditions()}
						/>
					</div>
				</>
			)}

			<Modal
				isOpen={openModalComment}
				closeModal={() => {
					setOpenModalComment(false)
				}}
				title={t('comment', { ns: 'naming' })}
				closeButtonColor="secondary"
				body={
					<div className="d-flex mt-5 mb-5 ms-3 me-3 align-items-center">
						{orderInfo?.comment ? orderInfo?.comment : t('noComments', { ns: 'naming' })}
					</div>
				}
			/>
			<Modal
				isOpen={openModal}
				closeModal={() => {
					setOpenModal(false)
				}}
				disableInteractions={true}
				closeButtonColor="secondary"
				title={t('assignABox', { ns: 'naming' })}
				body={
					<div className="d-flex flex-column p-3">
						<Input
							type="select"
							name="box"
							id="box"
							defaultValue={''}
							onChange={(e) => setModalBoxInputValue(e.target.value)}
						>
							<option value="" disabled hidden>
								{t('assignABox', { ns: 'naming' })}
							</option>
							{boxList?.map((box) => (
								<option key={box.id} value={box.ref}>
									{box.ref}
								</option>
							))}
						</Input>
						<button
							className="btn btn-primary mt-4 align-self-end"
							onClick={() => { setOpenModal(false); setExpeditionOrderBoxCode(modalBoxInputValue, orderInfo?.id); updateBoxesByRef({ data: { boxes: [{ ref: modalBoxInputValue, orderId: orderInfo?.id }] } }) }}
						>
							{t('assign', { ns: 'naming' })}
						</button>
					</div>
				}
			/>
			<ModalPrintDeliveryNote
				isOpenDeliveryNoteModal={isOpenDeliveryNoteModal}
				setIsOpenDeliveryNoteModal={setIsOpenDeliveryNoteModal}
				orderInfo={orderInfo}
				isSavingPdfInS3={saveDeliveryNoteInS3}
				setSaveDeliveryNoteInS3={setSaveDeliveryNoteInS3}
			/>
			<Modal
				size="lg"
				isOpen={isOpenDeliveryModal}
				closeModal={closeOpenDeliveryModal}
				closeButtonColor="secondary"
				dontShowCloseButton={formType === 1}
				title={formType === 0 || formType === 1 ? t('generate_delivery', { ns: 'naming' }) : formType === 2 ? t('combineShipping', { ns: 'naming' }) : t('manualShipping', { ns: 'naming' })}
				buttons={[
					formType == 0 && totalScannedParts !== 0 && commentChecked && boxLabelDownloaded && alabaranGenerated && (
						<button
							key="continue-button"
							className="btn btn-primary"
							onClick={() => {
								setFormType(1)
							}}
						>
							{t('continue', { ns: 'naming' })}
						</button>
					),
					formType == 2 && (
						<button
							key="continue-button"
							className="btn btn-primary"
							disabled={lastThreeDaysShipments?.length === 0}
							onClick={() => {
								handleClickCombine()
							}}
						>
							{t('send', { ns: 'naming' })}
						</button>
					),
					formType == 3 && (
						<button
							key="continue-button"
							className="btn btn-primary"
							onClick={() => {
								handleClickManual()
							}}
						>
							{t('send', { ns: 'naming' })}
						</button>
					),
				]}
				body={
					<div className="d-flex flex-column px-3 mt-4 mb-4 ms-3 me-3 justify-content-center align-items-center">
						{formType === 0 && totalScannedParts !== 0 && commentChecked && boxLabelDownloaded && alabaranGenerated && (
							<div className="d-flex flex-column justify-content-flex-start align-items-start">
								<h6><strong>{t('confirmationText', { ns: 'naming' })}</strong></h6>
								&nbsp;
								<h6><strong>{t('clientMailText', { ns: 'naming' })}</strong></h6>
								<button className="btn" style={{ width: '98%', color: 'white', backgroundColor: '#4899C4', border: '0px', borderRadius: '4px', marginTop: '10px' }} onClick={() => setFormType(2)}>
									{t('combineShipping', { ns: 'naming' })}
								</button>
								<button className="btn" style={{ width: '98%', color: 'white', backgroundColor: '#46CFCF', border: '0px', borderRadius: '4px', marginTop: '10px' }} onClick={() => setFormType(3)}>
									{t('manualShipping', { ns: 'naming' })}
								</button>
							</div>
						)}
						{formType === 3 && (
							<div className="d-flex flex-column justify-content-flex-start align-items-start w-100">
								<form className="w-100">
									<div className="row">
										<FormGroup className="col-md-12 mt-4 px-4">
											<h6 className="mt-1"><span className="text-primary">✲</span> {t('carrier', { ns: 'naming' })}</h6>
											<Input
												type="select"
												name="carrier"
												id="carrier"
												defaultValue={''}
												onChange={(e) => setManualShippingForm({ ...manualShippingForm, carrier: e.target.value })}
											>
												<option value="" disabled hidden>
													{t('selectAnOption', { ns: 'naming' })}
												</option>
												<option value="4">{t('UPS', { ns: 'naming' })}</option>
												<option value="2">{t('NACEX', { ns: 'naming' })}</option>
												<option value="3">{t('hand-delivery', { ns: 'naming' })}</option>
											</Input>
										</FormGroup>
										<FormGroup className="mb-3 col-md-12">
											<TextInput
												title={t('trackingNumber', { ns: 'naming' })}
												value={manualShippingForm.trackingNumber}
												setValue={updateManualShippingForm}
												id="trackingNumber"
												name="trackingNumber"
												objectElement="trackingNumber"
											/>
										</FormGroup>
									</div>
								</form>

							</div>
						)}
						{formType === 2 && (
							<>
								{lastThreeDaysShipments?.length === 0 ? (
									<Alert color="danger" className="mb-3 w-100 text-center">
										{t('noOrdersToCombine', { ns: 'naming' })}
									</Alert>
								) : (
									<>
										<FormGroup className="col-md-12 mt-2 px-4">
											<h6 className="mt-1">
												<span className="text-primary">✲</span>{' '}
												{t('shipment', { ns: 'naming' })}
											</h6>
											<Input
												type="select"
												name="shipping"
												id="shipping"
												defaultValue={''}
												onChange={(e) => setCombineForm({ ...combineForm, shipmentId: e.target.value })}
											>
												<option value="" disabled hidden>
													{t('selectAnOption', { ns: 'naming' })}
												</option>
												{lastThreeDaysShipments?.map((shipment) => (
													<option value={shipment.id} key={shipment.id}>
														{t('trackingNumber', { ns: 'naming' })}: {shipment.trackingNumber ? shipment.trackingNumber : t('hand-delivery', { ns: 'naming' })} - {t('date', { ns: 'naming' })}: {shipment.shippingDate.slice(0, 10)}									</option>
												))}
											</Input>
										</FormGroup>
									</>
								)}
							</>
						)}

						{formType === 1 && (
							<>
								<form className="w-100">
									<div className="row">
										<FormGroup className="col-md-12 mt-2 px-4">
											<h6 className="mt-1"><span className="text-primary">✲</span> {t('logisticCompany', { ns: 'naming' })}</h6>
											<Input
												type="select"
												name="carrier"
												id="carrier"
												defaultValue={''}
												onChange={(e) => setNacexForm({ ...nacexForm, carrier: e.target.value })}
											>
												{orderInfo?.shippingAddress?.country == SPAIN ? (
													<option value="2">{t('NACEX', { ns: 'naming' })}</option>
												) : (
													<option value="3">{t('UPS', { ns: 'naming' })}</option>
												)}
											</Input>
										</FormGroup>
										<FormGroup className="col-md-12 mt-2 px-4">
											<h6 className="mt-1"><span className="text-primary">✲</span> {t('shippingPrice', { ns: 'naming' })}</h6>
											<Input
												type="select"
												name="tip_ser"
												id="tip_ser"
												defaultValue={defaultTipSer}
												invalid={hasPressedSend && !nacexForm.tip_ser}
												onChange={(e) => setNacexForm({ ...nacexForm, tip_ser: e.target.value })}
											>
												{orderInfo?.shippingAddress?.country == SPAIN ? (
													<>
														<option value={NACEX_10}>NACEX 10:00</option>
														<option value={NACEX_12}>NACEX 12:00</option>
														<option value={E_NACEX}>E-NACEX</option>
													</>
												) : (
													<>
														<option value={UPS_EXPRESS}>UPS Express</option>
														<option value={UPS_EXPRESS_SAVER}>UPS Express Saver</option>
													</>
												)}
											</Input>
											{hasPressedSend && !nacexForm.tip_ser &&
												<small className="text-red ms-2">{t('mandatory_field', { ns: 'naming' })}</small>
											}
										</FormGroup>
									</div>
									<div className="row">
										<FormGroup className="col-md-6 mt-2 px-4">
											<h6 className="mt-2"><span className="text-primary">✲</span> {t('typePayment', { ns: 'naming' })}</h6>
											<Input
												type="select"
												name="cobro"
												id="cobro"
												defaultValue={''}
												invalid={hasPressedSend && !nacexForm.tip_cob}
												onChange={(e) => setNacexForm({ ...nacexForm, tip_cob: e.target.value })}
											>
												<option value="" disabled hidden>
													{t('selectAnOption', { ns: 'naming' })}
												</option>
												{orderInfo?.shippingAddress?.country == SPAIN ? (
													<>
														<option value="O">{t('origin', { ns: 'naming' })}</option>
														<option value="D">{t('destination', { ns: 'naming' })}</option>
														<option value="T">{t('third', { ns: 'naming' })}</option>
													</>
												) : (
													<option value="01">{t('transportation', { ns: 'naming' })}</option>
												)}
											</Input>
											{hasPressedSend && !nacexForm.tip_cob &&
												<small className="text-red ms-2">{t('mandatory_field', { ns: 'naming' })}</small>
											}
										</FormGroup>
										<FormGroup className="col-md-6 mt-2">
											<TextInput
												title={t('nameDelivery', { ns: 'naming' })}
												value={nacexForm.nom_ent}
												setValue={updateNacexForm}
												objectElement="nom_ent"
												hasPressed={hasPressedSend}
												isRequired={true}
											/>
										</FormGroup>
									</div>
									<div className="row">
										<FormGroup className="col-md-6 mt-2">
											<TextInput
												title={t('addressDelivery', { ns: 'naming' })}
												value={nacexForm.dir_ent}
												setValue={updateNacexForm}
												objectElement="dir_ent"
												hasPressed={hasPressedSend}
												isRequired={true}
											/>
										</FormGroup>
										<FormGroup className="col-md-6 mt-2">
											<TextInput
												title={t('countryDelivery', { ns: 'naming' })}
												value={nacexForm.pais_ent}
												setValue={updateNacexForm}
												objectElement="pais_ent"
												hasPressed={hasPressedSend}
												isRequired={true}
											/>
										</FormGroup>
									</div>
									<div className="row">
										<FormGroup className="col-md-6 mt-2">
											<TextInput
												title={t('postalCodeDelivery', { ns: 'naming' })}
												value={nacexForm.cp_ent}
												setValue={updateNacexForm}
												objectElement="cp_ent"
												hasPressed={hasPressedSend}
												isRequired={true}
											/>
										</FormGroup>
										<FormGroup className="col-md-6 mt-2">
											<TextInput
												title={t('cityDelivery', { ns: 'naming' })}
												value={nacexForm.pob_ent}
												setValue={updateNacexForm}
												objectElement="pob_ent"
												hasPressed={hasPressedSend}
												isRequired={true}
											/>
										</FormGroup>
									</div>
									<div className="row">
										<FormGroup className="col-md-6 mt-2">
											<TextInput
												title={t('phoneDelivery', { ns: 'naming' })}
												value={nacexForm.tel_ent}
												setValue={updateNacexForm}
												objectElement="tel_ent"
												hasPressed={hasPressedSend}
												isRequired={true}
											/>
										</FormGroup>
										<FormGroup className="col-md-6 mt-2 mb-3">
											<h6 className="mt-2"><span className="text-primary">✲</span> {t('numberOfPackages', { ns: 'naming' })}</h6>
											<Input
												type="select"
												name="bul"
												id="bul"
												defaultValue={''}
												invalid={hasPressedSend && !nacexForm.bul}
												onChange={(e) => setNacexForm({ ...nacexForm, bul: e.target.value })}
											>
												<option value="" disabled hidden>
													{t('selectAnOption', { ns: 'naming' })}
												</option>
												{[...Array(20)].map((_, i) => (
													<option key={i} value={i + 1}>
														{i + 1}
													</option>
												))}
											</Input>
											{hasPressedSend && !nacexForm.bul &&
												<small className="text-red ms-2">{t('mandatory_field', { ns: 'naming' })}</small>
											}
										</FormGroup>
									</div>
									<FormGroup className="mb-3 col-md-6">
										<h5 className="mt-4 ms-1"><span className="text-primary"></span> {t('packageInfo', { ns: 'naming' })}</h5>
										<h6 className="mt-4 ms-1"><span className="text-primary"></span> {t('totalWeight', { ns: 'naming' })}</h6>
										<h3 className="mt-2 ms-1">{totalScannedPartsWeight > 1000 ? `${roundAtDecimals((totalScannedPartsWeight / 1000), 1)} kg` : `${roundAtDecimals(totalScannedPartsWeight, 0)} g`}</h3>
									</FormGroup>
									{Array.from({ length: nacexForm?.bul }).map((_, index) => (
										nacexForm?.bul > 0 && orderInfo?.shippingAddress?.country != SPAIN && (
											<FormGroup key={index} className="mb-3 mt-4">
												<h6 className="mt-2"><span className="text-primary">✲</span> {t('package', { ns: 'naming' })} {index + 1}</h6>
												<Input
													type="select"
													name="packageSize"
													id="packageSize"
													defaultValue={''}
													invalid={hasPressedSend && !nacexForm['packageSize' + index]}
													onChange={(e) => setNacexForm({ ...nacexForm, ['packageSize' + index]: e.target.value })}
												>
													<option value="" disabled hidden>
														{t('selectAnOption', { ns: 'naming' })}
													</option>
													{packagesSize?.map((packageSize) => (
														<option key={packageSize.value} value={packageSize.value}>
															{packageSize.label}
														</option>
													))}
												</Input>

											</FormGroup>
										)
									))}
									<FormGroup className="mb-3 mt-4">
										<TextInput
											title={t('orderReference', { ns: 'naming' })}
											value={orderInfo?.id + '_Pedido'}
											setValue={updateNacexForm}
											objectElement="obs1"
											disabled={true}
											isRequired={false}
										/>
									</FormGroup>
									<FormGroup className="mb-3 mt-4">
										<TextInput
											title={t('observations', { ns: 'naming' })}
											value={nacexForm.obs1}
											setValue={updateNacexForm}
											objectElement="obs1"
											isRequired={false}
										/>
									</FormGroup>
									{errorNacexForm && (
										<FormGroup className="mb-3 mt-4">
											<Alert color="danger" className="mb-3 w-100 text-center">
												{errorNacexForm && t('error_message', { ns: 'naming' })}
											</Alert>
										</FormGroup>
									)}
								</form>
								<div className="d-flex w-100 align-items-end justify-content-end mt-1 gap-2">
									<div
										className="d-inline-flex align-items-center cursor-pointer btn btn-primary"
										key="send-button"
										type="submit"
										onClick={() => {
											if (orderInfo?.shippingAddress?.country == SPAIN) {
												handleCreationNacexExpedition()
											}
											else {
												handleCreationUPSExpedition()
											}
											setHasPressedSend(true)
										}}>
										{t('send', { ns: 'naming' })}
									</div>
									<button
										className='btn btn-secondary'
										onClick={closeOpenDeliveryModal}
									>
										{t('close', { ns: 'naming' })}
									</button>
								</div>
							</>
						)}
						{totalScannedParts == 0 &&
							<Alert color="warning" className="mb-3 w-100 text-center">
								{t('noScannedPieces', { ns: 'naming' })}
							</Alert>
						}
						{!commentChecked &&
							<Alert color="danger" className="mb-3 w-100 text-center">
								{t('commentNotRead', { ns: 'naming' })}
							</Alert>
						}
						{!boxLabelDownloaded &&
							<Alert color="danger" className="mb-3 w-100 text-center">
								{t('noLabelsPrinted', { ns: 'naming' })}
							</Alert>
						}
						{!alabaranGenerated &&
							<Alert color="danger" className="w-100 text-center">
								{t('noDeliveryNotePrinted', { ns: 'naming' })}
							</Alert>
						}
					</div>
				}
			/>
			{SimulateScanModal}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		suppliers: state.Users.suppliers,
		nacexExpeditionInfo: state.Orders.nacexExpeditionInfo,
		errorNacex: state.Orders.error,
		boxList: state.Orders.boxList,
		lastScannedPart: state.Orders.lastScannedPart,
		lastThreeDaysShipments: state.Orders.lastThreeDaysShipments,
		upsExpeditionInfo: state.Orders.upsExpeditionInfo,
		expeditionOrderList: state.Orders.expeditionOrderList,
		selectedExpeditionOrderId: state.Orders.selectedExpeditionOrderId,
		imagesInBase64: state.CloudStorage.imagesInBase64,
		isShipmentCreated: state.Orders.isShipmentCreated,
		generatedLabel: state.Orders.generatedLabel,
		generatedPdf: state.Orders.generatedPdf,
		shipmentExport: state.Orders.shipmentExport,
		scanOrderExpeditionPart_Status: state.Orders.scanOrderExpeditionPart_Status,
	}
}

SelectedExpeditionOrder.propTypes = {
	orderInfo: PropTypes.object,
	productionItems: PropTypes.array,
	getSuppliers: PropTypes.func,
	suppliers: PropTypes.array,
	resetScannedPiecesFromExpeditionPiece: PropTypes.func,
	resetScannedPiecesFromAllExpeditionPieces: PropTypes.func,
	setExpeditionOrderBoxCode: PropTypes.func,
	createNewNacexExpedition: PropTypes.func,
	nacexExpeditionInfo: PropTypes.object,
	setCompletedExpeditionOrder: PropTypes.func,
	addExpeditionToOrder: PropTypes.func,
	errorNacex: PropTypes.any,
	manageScannedQuantityOnDelivery: PropTypes.func,
	boxList: PropTypes.array,
	updateBoxesByRef: PropTypes.func,
	updateOrder: PropTypes.func,
	scanOrderExpeditionPart: PropTypes.func,
	setExpeditionBoxLabelGenerated: PropTypes.func,
	setExpeditionDeliveryNotePrinted: PropTypes.func,
	setExpeditionCommentReaded: PropTypes.func,
	updateOrderExpeditionInfo: PropTypes.func,
	lastScannedPart: PropTypes.object,
	createShipmentExport: PropTypes.func,
	updateOrderItem: PropTypes.func,
	getLastThreeDaysShipments: PropTypes.func,
	lastThreeDaysShipments: PropTypes.array,
	resetExpeditionOrderBoxCode: PropTypes.func,
	resetNacexInfo: PropTypes.func,
	createNewUpsExpedition: PropTypes.func,
	upsExpeditionInfo: PropTypes.object,
	selectedExpeditionOrderId: PropTypes.any,
	expeditionOrderList: PropTypes.array,
	isShipmentCreated: PropTypes.bool,
	getExpeditionNacexLabels: PropTypes.func,
	getExpeditionUpsLabels: PropTypes.func,
	generatedLabel: PropTypes.any,
	generatedPdf: PropTypes.any,
	shipmentExport: PropTypes.any,
	uploadOrderShipmentFiles: PropTypes.func,
	uploadedFiles: PropTypes.object,
	handleCompleteProduction: PropTypes.func,
	getAllOrderShipmentFiles: PropTypes.func,
	handleSentOrder: PropTypes.func,
}

export default connect(mapStateToProps,
	{
		getSuppliers,
		resetScannedPiecesFromExpeditionPiece,
		resetScannedPiecesFromAllExpeditionPieces,
		setExpeditionOrderBoxCode,
		createNewNacexExpedition,
		setCompletedExpeditionOrder,
		addExpeditionToOrder,
		manageScannedQuantityOnDelivery,
		updateBoxesByRef,
		updateOrder,
		scanOrderExpeditionPart,
		setExpeditionBoxLabelGenerated,
		setExpeditionDeliveryNotePrinted,
		setExpeditionCommentReaded,
		updateOrderExpeditionInfo,
		createShipmentExport,
		updateOrderItem,
		getLastThreeDaysShipments,
		resetExpeditionOrderBoxCode,
		resetNacexInfo,
		createNewUpsExpedition,
		getExpeditionNacexLabels,
		getExpeditionUpsLabels,
		uploadOrderShipmentFiles,
		handleCompleteProduction,
		updateOrderExpeditionShipment,
		getAllOrderShipmentFiles,
		handleSentOrder,
	})(withTranslation()(SelectedExpeditionOrder))
