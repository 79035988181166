import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { getDate, getDateWithoutHour } from 'common/Utils/StringUtilities'
import { weekdays } from 'constants/dates'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
const RealshipmentDate = ({ date, realDate, t, delayDate }) => {
	const DAY = t('day', { ns: 'naming' })
	const DAYS = t('days', { ns: 'naming' })
	const REMAINING = t('remaining', { ns: 'naming' })
	const REMAININGS = t('remainings', { ns: 'naming' })
	const LATE = t('late', { ns: 'naming' })

	const getWeekDay = (dateStr, language) => {
		const date = new Date(dateStr)
		const days = date.getDay()
		return weekdays[days][language] + ' '
	}

	const getRemainingDays = date => {
		const shipmentDate = getDateWithoutHour(new Date(date))
		const actualDate = getDateWithoutHour(new Date())
		return Math.floor((shipmentDate - actualDate) / 86400000)
	}

	useEffect(() => {
		if (date) {
			const remainingDays = getRemainingDays(date)
			setRemainingDays(remainingDays)
			setClassNameIcon(getClassName(remainingDays))
		}
	}, [date])

	let leftOrLate = t('remaining', { ns: 'naming' })

	const getClassName = days => {
		switch (true) {
			case 1 == days || 0 == days:
				return 'bx bxs-circle text-warning me-1'
			case 0 > days:
				leftOrLate = t('late', { ns: 'naming' })
				return 'bx bxs-circle text-danger me-1'
			case 0 < days:
				return 'bx bxs-circle text-success me-1'
		}
	}
	const [remainingDays, setRemainingDays] = useState(getRemainingDays(date))
	const [classNanemIcon, setClassNameIcon] = useState(
		getClassName(remainingDays),
	)

	return (
		<div>
			{date && (
				<div>
					<span>
						{getDate(date, getLanguage())} {getWeekDay(date, getLanguage())}
						<small>(ESTM)</small>
					</span>
					<div>
						{realDate == '' || realDate == undefined ? (
							<div className="pb-1">
								<i className={classNanemIcon}></i>
								<span>
									{remainingDays}
									{Math.abs(remainingDays) == 1
										? ` ${DAY} ${leftOrLate}`
										: ` ${DAYS} ${leftOrLate}`}
								</span>
							</div>
						) : (
							<div>
								<strong>
									{' '}
									{realDate && getDate(realDate, getLanguage())}{' '}
									{realDate && getWeekDay(realDate, getLanguage())}
									<small> (REAL)</small>
								</strong>
							</div>
						)}
						{delayDate && !realDate && (
							<div>
								<span className="bg-red rounded p-1 text-white">
									{' '}
									{getDate(delayDate, getLanguage())}{' '}
									{getWeekDay(delayDate, getLanguage())}
									<small>(DELAY)</small>
								</span>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

RealshipmentDate.propTypes = {
	date: PropTypes.string,
	realDate: PropTypes.string,
	delayDate: PropTypes.string,
	t: PropTypes.func,
}
export default withTranslation()(RealshipmentDate)
