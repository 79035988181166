import { CANCELED_CODE } from 'constants/errorsCode'

import {
	DOWNLOAD_BLUEPRINT,
	DOWNLOAD_BLUEPRINT_FAIL,
	DOWNLOAD_BLUEPRINT_SUCCESS,
	GET_DOMAIN_OFFERS,
	GET_DOMAIN_OFFERS_FAIL,
	GET_DOMAIN_OFFERS_SUCCESS,
	GET_FILTERED_OFFERS,
	GET_FILTERED_OFFERS_FAIL,
	GET_FILTERED_OFFERS_SUCCESS,
	GET_OFFERS_DASHBOARD,
	GET_OFFERS_DASHBOARD_FAIL,
	GET_OFFERS_DASHBOARD_SUCCESS,
	GET_OFFER_DETAIL,
	GET_OFFER_DETAIL_FAIL,
	GET_OFFER_DETAIL_SUCCESS,
	GET_OFFER_LIST,
	GET_OFFER_LIST_COUNT,
	GET_OFFER_LIST_COUNT_FAIL,
	GET_OFFER_LIST_COUNT_SUCCESS,
	GET_OFFER_LIST_FAIL,
	GET_OFFER_LIST_SUCCESS,
	UPDATE_OFFER_CUSTOMER,
	UPDATE_OFFER_CUSTOMER_FAIL,
	UPDATE_OFFER_CUSTOMER_SUCCESS,
	UPDATE_QUOTE_MARGIN,
	UPDATE_QUOTE_MARGIN_FAIL,
	UPDATE_QUOTE_MARGIN_SUCCESS,
	UPLOAD_BLUEPRINT,
	UPLOAD_BLUEPRINT_FAIL,
	UPLOAD_BLUEPRINT_SUCCESS,
	UPLOAD_OFFER,
	UPLOAD_OFFER_FAIL,
	UPLOAD_OFFER_SUCCESS,
} from './actionTypes'

const header = {
	id: 'id',
	image: 'image',
	size: 'size',
	weight: 'weight',
	name: 'name',
	files: 'files',
	technology: 'technology',
	material: 'material',
	finishing: 'finishing',
	status: 'status',
	note: 'note',
	chat: 'chat',
	bindingNote: 'bindingNote',
}

const extraInfoTableHeader = {
	volume: 'volume',
	area: 'area',
	faces: 'faces',
	boundaryBoxVolume: 'boundaryBoxVolume',
	chipVolume: 'chipVolume',
	process: 'process',
	similarity: 'similarity',
}

const costsAndMarginsHeader = {
	quantity: 'quantity',
	quotation: 'unitCost',
	totalCost: 'totalCost',
	margin: 'margin',
	unitSell: 'unitSell',
	totalSell: 'totalSell',
	shipmentPrice: 'shipment',
	aiPrediction: 'aiPrediction',
}

const costsAndMarginsSupplierHeader = {
	quotation: 'sellerUnitCost',
	time: 'manufacturingTime',
}

const INIT_STATE = {
	offerList: {},
	offerDetail: {},
	error: null,
	isUpdatingMargins: false,
	updatingMarginsError: false,
	isUploadingOffer: false,
	uploadingOfferError: null,
	isUploadingBlueprint: false,
	uploadingBlueprintError: false,
	uploadingBlueprintSuccess: false,
	isUpdatingMarginsResponse: '',
	blueprints: [],
	domainOffers: [],
	offersDashboard: [],
	offerStats: {},
	previousOfferStats: {},

}

const offers = (state = INIT_STATE, action) => {
	switch (action.type) {

		case GET_OFFER_LIST:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_OFFER_LIST_SUCCESS: {
			const offers = action.payload.offers.map(offer =>
				mapOffer(offer),
			)

			return {
				...state,
				offerList: {
					offers,
				},
				count: action.payload.count,
				error: null,
				isLoading: false,
			}
		}
		case GET_OFFER_LIST_FAIL:
			return {
				...state,
				offerList: {},
				count: 0,
				error: action.payload,
				isLoading: action.payload.code === CANCELED_CODE,
			}
		case GET_OFFER_LIST_COUNT:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_OFFER_LIST_COUNT_SUCCESS:
			return {
				...state,
				offerList: {
					...state.offerList,
					count: action.payload.count,
				},
				error: null,
				isLoading: false,
			}

		case GET_OFFER_LIST_COUNT_FAIL:
			return {
				...state,
				offerList: {},
				error: action.payload,
				isLoading: false,
			}

		case GET_OFFER_DETAIL:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_OFFER_DETAIL_SUCCESS: {
			const { offer, organization, account } = action.payload
			const mappedOffer = mapOfferDetail(offer, organization, account)

			mappedOffer.crmInteractions && mappedOffer.crmInteractions.length !== 0 &&
				mappedOffer.crmInteractions.forEach(interaction => {
					interaction.date = new Date(interaction.date)
				})

			// offer.parts.forEach(part => {
			//   part.image.onClick = (id) => {alert("Mostrar vista en 3d de 'pgview("+id+")'")}
			// })

			return {
				...state,
				offerDetail: {
					header,
					extraInfoTableHeader,
					costsAndMarginsHeader,
					costsAndMarginsSupplierHeader,
					offer: mappedOffer,
					organization,
					account,
				},
				error: null,
				isLoading: false,
			}
		}
		case GET_OFFER_DETAIL_FAIL:
			return {
				...state,
				offerDetail: {},
				error: action.payload,
				isLoading: false,
			}
		case UPDATE_QUOTE_MARGIN:
			return {
				...state,
				isUpdatingMargins: true,
				updatingMarginsError: false,
				isUpdatingMarginsResponse: '',
			}
		case UPDATE_QUOTE_MARGIN_SUCCESS:
			return {
				...state,
				isUpdatingMargins: false,
				isUpdatingMarginsResponse: 'success',
			}
		case UPDATE_QUOTE_MARGIN_FAIL:
			return {
				...state,
				isUpdatingMargins: false,
				updatingMarginsError: true,
				isUpdatingMarginsResponse: 'error',
			}
		case UPLOAD_OFFER:
			return {
				...state,
				isUploadingOffer: true,
				updatingMarginsError: false,
			}
		case UPLOAD_OFFER_SUCCESS:
			return {
				...state,
				isUploadingOffer: false,
				uploadingOfferError: action.payload.response.message,
			}

		case UPLOAD_OFFER_FAIL:
			return {
				...state,
				isUploadingOffer: false,
				uploadingOfferError: true,
			}
		case UPLOAD_BLUEPRINT:
			return {
				...state,
				isUploadingBlueprint: true,
				uploadingBlueprintError: false,
			}
		case UPLOAD_BLUEPRINT_SUCCESS:
			return {
				...state,
				isUploadingBlueprint: false,
				uploadingBlueprintError: false,
				uploadingBlueprintSuccess: true,
			}
		case UPLOAD_BLUEPRINT_FAIL:
			return {
				...state,
				isUploadingBlueprint: false,
				uploadingBlueprintError: true,
				uploadingBlueprintSuccess: false,
			}
		case DOWNLOAD_BLUEPRINT:
			return {
				...state,
				isUploadingBlueprint: true,
				uploadingBlueprintError: false,
			}
		case DOWNLOAD_BLUEPRINT_SUCCESS: {
			const response = action.payload.response
			const { data } = response
			const blueprints = data.parts.map(blueprint => {
				return blueprint.blueprints
			})

			return {
				...state,
				blueprints: blueprints,
			}
		}
		case DOWNLOAD_BLUEPRINT_FAIL:
			return {
				...state,
				isUploadingBlueprint: false,
				uploadingBlueprintError: true,
				uploadingBlueprintSuccess: false,
			}
		case UPDATE_OFFER_CUSTOMER:
			return {
				...state,
				isUpdatingOfferCustomer: true,
				updateOfferCustomerError: false,
			}
		case UPDATE_OFFER_CUSTOMER_SUCCESS:
			return {
				...state,
				isUpdatingOfferCustomer: false,
			}

		case UPDATE_OFFER_CUSTOMER_FAIL:
			return {
				...state,
				isUpdatingOfferCustomer: false,
				updateOfferCustomerError: true,
			}

		case GET_DOMAIN_OFFERS:
			return {
				...state,
				isLoading: true,
			}
		case GET_DOMAIN_OFFERS_SUCCESS:
			return {
				...state,
				domainOffers: action.payload.response.offers,
				isLoading: false,
			}

		case GET_DOMAIN_OFFERS_FAIL:
			return {
				...state,
				error: action.payload.error.message,
				isLoading: false,
			}
		case GET_FILTERED_OFFERS:
			return {
				...state,
				isLoading: true,
			}
		case GET_FILTERED_OFFERS_SUCCESS: {
			const offersFiltred = action.payload.offers.map(offer => mapOffer(offer))
			return {
				...state,
				isLoading: false,
				offerList: { offers: offersFiltred },
				count: action.payload.count,
				error: null,
			}
		}
		case GET_FILTERED_OFFERS_FAIL:
			return {
				...state,
				isLoading: action.payload.error.code === CANCELED_CODE,
				offerList: [],
				error: action.payload.error,
			}
		case GET_OFFERS_DASHBOARD:
			return {
				...state,
				isLoading: true,
			}
		case GET_OFFERS_DASHBOARD_SUCCESS:
			return {
				...state,
				offersDashboard: action.payload.offers,
				offerStats: action.payload.offerStats,
				offerDsahboardCustomers: action.payload.customers,
				previousOfferStats: action.payload.previousOfferStats,
				isLoading: false,
			}
		case GET_OFFERS_DASHBOARD_FAIL:
			return {
				...state,
				error: action.payload.error.message,
				isLoading: false,
			}
		default:
			return state
	}
}
function findSupplierQuotationOnState(state, partId, supplierId) {
	const part = state.offerDetail.offer.parts.find(part => part.id === partId)
	const quotes = part.costsAndMargins.suppliers.find(supplier => supplier.id === supplierId)

	return quotes
}
function setDateToOffer(offer) {
	const newOffer = { ...offer }
	if (typeof newOffer.date.value !== 'undefined') newOffer.date.value = new Date(offer.date.value)
	else newOffer.date = new Date(offer.date)
	return newOffer
}
function mapOffer(offer) {
	const newOffer = {
		...offer,
		customer: {
			value: {
				organizationId: offer.customer.organizationId,
				title: offer.customer.title,
				crmLink: offer.customer.crmLink,
				email: offer.customer.email,
			},
			id: offer.customer,
		},
		date: { value: new Date(offer.date) },
	}
	return newOffer
}

function mapOfferDetail(offer, organization, account) {
	const newOffer = {
		...offer,
		customer: {
			value: {
				organizationId: offer.customer.organizationId,
				title: organization.organizationName,
				crmLink: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
				email: account.loginCredentials.email,
			},
			id: account.id,
		},
		date: { value: new Date(offer.date) },
	}
	return newOffer
}

export default offers
