import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import { OrderStatus } from 'constants/orderStatus'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addScannedOrder, addScannedPieceToOrder, getBoxList, getOpenedOrders, getOrderDetailById, getProductionItemsByPartId, getProductionsPickedUpByCourier, setLastScannedPart, setOrderBoxCode, setSelectedOrderId, updateBoxesByRef, updateOrder, updateProductionItemReceivedQuantity } from 'store/actions'
import useScanDetection from 'use-scan-detection'
import ReOpenOrderModal from '../ReOpenOrderModal'
import SearchAndClientInfo from '../ReceptionSearchClientInfo/SearchAndClientInfo'
import OrderSummaryList from './OrderSummaryList'

// The productionStatusUpdate IS in UpdateProductionItemReceivedQuantity in pgo-productions, this may be confuse, but is the easiest way to do it rn
// TODO in future refactor this to a better way to update the production status.
const ReceptionOrders = ({
	t,
	getProductionsPickedUpByCourier,
	isLoadingProductions,
	productionsPickedUpByCourier,
	orderDetail,
	getOrderDetailById,
	scannedOrderList,
	addScannedOrder,
	setSelectedOrderId,
	setOrderBoxCode,
	selectedOrderId,
	addScannedPieceToOrder,
	manualScanned,
	setManualScanned,
	boxList,
	getBoxListStatus = {},
	setLastScannedPart,
	updateBoxesByRef,
	getOpenedOrders,
	openedOrders,
	getBoxList,
	updateProductionItemReceivedQuantity,
	setIsOpenAssignBoxModal,
	isOpenAsignBoxModal,
	updateOrder,
}) => {

	const [scannedPart, setScannedPart] = useState(null)
	const [scannedQuantity, setScannedQuantity] = useState(null)
	const [scannedOrder, setScannedOrder] = useState(null)
	const [scannedFragment, setScannedFragment] = useState(null)
	const [scannedList, setScannedList] = useState([])
	const [modalBoxInputValue, setModalBoxInputValue] = useState('')
	const [isBoxModalOpen, _setIsBoxModalOpen] = useState(false)
	const [scannedAssignedBoxError, setscannedAssignedBoxError] = useState(false)
	const [isOpenReOpenOrderModal, setIsOpenReOpenOrderModal] = useState(false)

	const [closeBoxModalTimeoutId, setCloseBoxModalTimeoutId] = useState(null)
	const setIsBoxModalOpen = (b) => {
		_setIsBoxModalOpen(b)
		clearTimeout(closeBoxModalTimeoutId)
		const timeoutId = setTimeout(() => {
			_setIsBoxModalOpen(false)
		}, 3000)
		setCloseBoxModalTimeoutId(timeoutId)
	}

	const getBoxListByOrderId = (orderId) => {
		if (!orderId) return null
		return boxList.filter((box) => box.orderId === orderId)
	}

	const unassignedBoxes = useMemo(() => boxList.filter((box) => !box.orderId), [boxList])
	const selectedOrderBoxList = useMemo(() => getBoxListByOrderId(selectedOrderId?.orderId), [boxList, selectedOrderId])

	const assignBox = (boxId) => {
		const orderId = selectedOrderId?.orderId || scannedOrder
		handleCloseModal()
		setOrderBoxCode(boxId, orderId)
		updateBoxesByRef({ data: { boxes: [{ ref: boxId, orderId }] } })
	}

	const handleGetDataBySplit = (code, split) => {
		const splitCode = code.split(split)
		const orderIdAndFragmentId = splitCode[0]
		const idPart = splitCode[1]
		const quantity = splitCode[2]
		if (!orderIdAndFragmentId.includes('ShiftS')) return null
		const [orderNumber, fragment] = orderIdAndFragmentId.split('ShiftS')
		return { orderNumber, idPart, quantity, fragment }
	}

	const getScannedPartData = (code) => {
		if (code.includes('-')) {
			return handleGetDataBySplit(code, '-')
		}
		if (code.includes('\'')) {
			return handleGetDataBySplit(code, '\'')
		}
		return null
	}

	const checkIsOrderCompleted = (orderItems, quantity, partId) => {
		if (orderItems == null || orderItems.length == 0) return false
		const orderItemsClone = JSON.parse(JSON.stringify(orderItems))
		for (const orderItem of orderItemsClone) {
			if (orderItem.item.part.id == partId) {
				orderItem.receivedQuantity += quantity
			}
			if (orderItem.receivedQuantity < orderItem.item.quantity) {
				return false
			}
		}
		return true
	}

	const handleUpdateOrder = (orderNumber, quantity, partId) => {
		const order = scannedOrderList.find((order) => order.id == orderNumber)
		if (order != null) {
			const { orderItems, statusId } = order
			const isOrderCompleted = checkIsOrderCompleted(orderItems, quantity, partId)
			if (isOrderCompleted && statusId < OrderStatus.IN_QUALITY) {
				updateOrder({ data: { order: { id: order.id, statusId: OrderStatus.IN_QUALITY } } })
			}
		}
	}

	const handleScanActions = (orderNumber, idPart, quantity, fragment) => {
		setScannedQuantity(Number(quantity))
		setScannedFragment(Number(fragment))
		setScannedOrder(Number(orderNumber))
		setScannedPart(Number(idPart))
		setLastScannedPart(Number(idPart))
		handleUpdateOrder(Number(orderNumber), Number(quantity), Number(idPart))
		const currentBoxList = getBoxListByOrderId(Number(orderNumber))
		if (currentBoxList && currentBoxList.length !== 0) {
			setIsBoxModalOpen(true)
			setIsOpenAssignBoxModal(false)
		} else {
			setIsOpenAssignBoxModal(true)
			setIsBoxModalOpen(false)
		}
	}

	const scanPart = (code) => {
		const data = getScannedPartData(code)
		if (data == null) return null
		const { orderNumber, idPart, quantity, fragment } = data
		if (orderNumber == null || idPart == null || quantity == null || fragment == null) return null
		handleScanActions(orderNumber, idPart, quantity, fragment)
	}

	const getBoxIdByCode = (code) => {
		if (code.includes('-')) {
			const splitCode = code.split('-')
			return splitCode[1]
		}
		if (code.includes('\'')) {
			const splitCode = code.split('\'')
			return splitCode[1]
		}
		return null
	}

	const scanBox = (code) => {
		const boxId = getBoxIdByCode(code)
		const box = boxList.find((box) => box.id == boxId)
		if (box != null) {
			const isBoxAvailable = unassignedBoxes.find((box) => box.id == boxId)
			if (isBoxAvailable != null) {
				assignBox(isBoxAvailable.ref)
			} else {
				setscannedAssignedBoxError(true)
			}
		} else {
			setscannedAssignedBoxError(true)
		}
	}

	useScanDetection({
		onComplete: (code) => {
			if (isOpenAsignBoxModal === true) {
				scanBox(code)
			} else {
				scanPart(code)
			}
		},
	})

	function handleCloseModal() {
		setIsOpenAssignBoxModal(false)
	}

	useEffect(() => {
		getOpenedOrders()
		getBoxList()
	}, [])

	function getTotalPieces(items) {
		let total = 0
		items.forEach((item) => {
			total += item.item.quantity
		})
		return total
	}

	function checkDisconformity(items) {
		items.forEach((item) => {
			if (item.disconformity === true) {
				return true
			}
		})
		return false
	}

	function parseScannedOrders() {
		const parsedScannedrderList = []
		scannedOrderList.forEach((order) => {
			const orderData =
			{
				id: order.id,
				organizationName: order?.billingInfo?.companyName,
				references: order.orderItems.length,
				totalPieces: getTotalPieces(order.orderItems),
				shipmentDate: (order?.productions[0]?.shipmentDateEstimate)?.slice(0, 10) || (order?.productions[0]?.shipmentDateReal)?.slice(0, 10),
				completed: order.completed,
				box: order.boxCode,
				disconformity: checkDisconformity(order.productions),
				receivingCountry: order.billingInfo.country,
			}
			parsedScannedrderList.push(orderData)
		})
		setScannedList(parsedScannedrderList)
	}

	useEffect(() => {
		if (productionsPickedUpByCourier.length === 0) {
			getProductionsPickedUpByCourier()
		}
	}, [])

	const checkIfOrderIsCompleted = (orderItems) => {
		return orderItems.every((item) => {
			return item.item.quantity <= item.receivedQuantity
		})
	}

	const checkBoxCode = (orderId) => {
		const boxCodes = []
		boxList.forEach((box) => {
			if (box.orderId == orderId) {
				boxCodes.push(box.ref)
			}
		})
		return boxCodes.join(', ')
	}

	useEffect(() => {
		if (openedOrders.length > 0 && boxList) {
			for (const order of openedOrders) {
				const isOrderCompleted = checkIfOrderIsCompleted(order.orderItems)
				const orderData = {
					...order,
					completed: isOrderCompleted,
					boxCode: checkBoxCode(order.id),
				}
				addScannedOrder(orderData)
				if (isOrderCompleted && order.statusId < OrderStatus.IN_QUALITY) {
					updateOrder({ data: { order: { id: order.id, statusId: OrderStatus.IN_QUALITY } } })
				}
			}
		}
	}, [openedOrders, getBoxListStatus])

	useEffect(() => {
		if (scannedOrder && scannedPart && scannedQuantity && scannedFragment) {
			const orderExists = scannedOrderList.find((order) => order.id == scannedOrder)
			if (!orderExists) getOrderDetailById(scannedOrder)
			else {

				addScannedPieceToOrder(scannedPart, orderExists.id, scannedQuantity)
				updateProductionItemReceivedQuantity({
					data: {
						orderId: orderExists.id,
						partId: scannedPart,
						quantity: scannedQuantity,
						orderFragmentId: scannedFragment,
					},
				})
				setSelectedOrderId(orderExists.id)
				setScannedPart(null)
				setScannedQuantity(1)
				setScannedOrder(null)
				setScannedFragment(null)
				if (manualScanned) setManualScanned(null)
			}
		}
	}, [scannedOrder, scannedPart, scannedQuantity, scannedFragment])

	useEffect(() => {
		if (Object.keys(orderDetail).length > 0 && scannedOrder) {

			if (orderDetail.order.order.statusId === OrderStatus.SENT) {
				setIsOpenReOpenOrderModal(true)
			}
			const orderDetailAdapted = {
				statusId: orderDetail.order.order.statusId,
				billingInfo: orderDetail.order.order.billingInfo,
				completed: orderDetail.order.order.orderItems?.length === 1 && orderDetail.order.order.orderItems[0].quantity == scannedQuantity ? true : false,
				boxCode: checkBoxCode(orderDetail.order.order.id),
				id: orderDetail.order.order.id,
				orderItems: orderDetail.order.order.orderItems.map((item) => {
					if (item.part.id === scannedPart) {
						return {
							...item,
							item: item,
							receivedQuantity: scannedQuantity,
						}
					}
					else {
						return {
							...item,
							item: item,
							receivedQuantity: 0,
						}
					}
				}),
				productions: orderDetail.order.order.productions,
			}
			addScannedOrder(orderDetailAdapted)
			updateProductionItemReceivedQuantity({
				data: {
					orderId: orderDetail.order.order.id,
					partId: scannedPart,
					quantity: scannedQuantity,
					orderFragmentId: scannedFragment,
				},
			})
			setSelectedOrderId(orderDetailAdapted.id)
			setScannedPart(null)
			setScannedQuantity(1)
			setScannedOrder(null)
			if (manualScanned) setManualScanned(null)
		}
	}, [orderDetail])

	useEffect(() => {
		parseScannedOrders()
	}, [scannedOrderList])

	useEffect(() => {
		if (manualScanned) {
			const regex = /^(\d+)S(\d+)-(\d+)-(\d+)$/
			const resultado = manualScanned.match(regex)
			if (resultado) {
				setScannedOrder(Number(resultado[1]))
				setScannedFragment(Number(resultado[2]))
				setScannedPart(Number(resultado[3]))
				setLastScannedPart(Number(resultado[3]))
				setScannedQuantity(Number(resultado[4]))
			}
		}
	}, [manualScanned])

	return (
		<div>
			<div className="mb-3 p-4 bg-white">
				<OrderSummaryList title={t('opened_orders', { ns: 'naming' })} orders={scannedList} />
			</div>
			<div className="mb-3 p-4 bg-white">
				<SearchAndClientInfo handleScanActions={handleScanActions} setManualScanned={setManualScanned} />

			</div>
			<div className="my-3 p-4 bg-white">
				<OrderSummaryList title={t('receive_in_few_days', { ns: 'naming' })} orders={productionsPickedUpByCourier} isProductions isLoading={isLoadingProductions} />
			</div>
			<Modal
				isOpen={isOpenAsignBoxModal}
				closeModal={() => {
					handleCloseModal()
				}}
				title={t('assignABox', { ns: 'naming' })}
				disableInteractions

				body={
					<div className="d-flex flex-column p-3">
						<OptionsInput
							options={unassignedBoxes.map(box => box.ref)}
							setValue={(e) => setModalBoxInputValue(e)}
						/>
						{(scannedAssignedBoxError) && <small><strong className='text-danger ms-3'>{t('assigned_box_scanned_error', { ns: 'naming' })}</strong></small>}
						<div className='d-flex gap-2 flex-row-reverse'>
							<button
								className="btn btn-primary mt-4 align-self-end"
								onClick={() => { assignBox(modalBoxInputValue) }}
								disabled={!modalBoxInputValue}
							>
								{t('assign', { ns: 'naming' })}
							</button>
							<button
								className="btn btn-secondary mt-4 align-self-end"
								onClick={() => setIsOpenAssignBoxModal(false)}
							>
								{t('close', { ns: 'naming' })}
							</button>
						</div>
					</div>
				}
			/>
			<Modal
				isOpen={isBoxModalOpen}
				closeModal={() => setIsBoxModalOpen(false)}
				focusOnCloseButton={true}
				title={t('drop_part', { ns: 'naming' })}
				size='lg'
				body={
					<div className='d-flex flex-row justify-content-center flex-wrap	'>
						{selectedOrderBoxList?.length > 0 && selectedOrderBoxList.map((box) => {
							return (<div className='d-flex p-4' key={box.ref}><h1>{box.ref}</h1></div>)
						})}
					</div>
				}
			/>
			<ReOpenOrderModal orderId={orderDetail?.order?.order?.id} closeModal={() => setIsOpenReOpenOrderModal(false)} isOpenModal={isOpenReOpenOrderModal} />
		</div>
	)
}

const mapStateToProps = state => {
	return {
		isLoadingProductions: state.Productions.isLoading,
		productionsPickedUpByCourier: state.Productions.productionsPickedUpByCourier,
		isLoadingOrders: state.Orders.isLoading,
		orderDetail: state.Orders.orderDetail,
		scannedOrderList: state.Orders.scannedOrderList,
		productionItemsfromScannedOrders: state.Productions.productionItemsfromScannedOrders,
		productionItemsByOrderId: state.Productions.productionItemsByOrderId,
		selectedOrderId: state.Orders.selectedOrderId,
		productionItemsByPartId: state.Productions.productionItemsByPartId,
		boxList: state.Orders.boxList,
		getBoxListStatus: state.Orders.getBoxListStatus,
		openedOrders: state.Orders.openedOrders,
	}
}

ReceptionOrders.propTypes = {
	t: PropTypes.func,
	isLoadingProductions: PropTypes.bool,
	productionsPickedUpByCourier: PropTypes.array,
	getProductionsPickedUpByCourier: PropTypes.func,
	isLoadingOrders: PropTypes.bool,
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	scannedOrderList: PropTypes.array,
	addScannedOrder: PropTypes.func,
	setSelectedOrderId: PropTypes.func,
	setOrderBoxCode: PropTypes.func,
	selectedOrderId: PropTypes.number,
	productionItemsByPartId: PropTypes.array,
	getProductionItemsByPartId: PropTypes.func,
	addScannedPieceToOrder: PropTypes.func,
	manualScanned: PropTypes.number,
	setManualScanned: PropTypes.func,
	boxList: PropTypes.array,
	getBoxListStatus: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
	setLastScannedPart: PropTypes.func,
	updateBoxesByRef: PropTypes.func,
	getOpenedOrders: PropTypes.func,
	openedOrders: PropTypes.array,
	getBoxList: PropTypes.func,
	updateProductionItemReceivedQuantity: PropTypes.func,
	setIsOpenAssignBoxModal: PropTypes.func,
	isOpenAsignBoxModal: PropTypes.bool,
	updateOrder: PropTypes.func,
}

export default connect(mapStateToProps,
	{
		getProductionsPickedUpByCourier,
		getOrderDetailById,
		addScannedOrder,
		setSelectedOrderId,
		setOrderBoxCode,
		getProductionItemsByPartId,
		addScannedPieceToOrder,
		setLastScannedPart,
		updateBoxesByRef,
		getOpenedOrders,
		getBoxList,
		updateProductionItemReceivedQuantity,
		updateOrder,
	})(withTranslation()(ReceptionOrders))
