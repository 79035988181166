import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { setSelectedOrderId } from 'store/actions'

const SearchAndClientInfo = ({ scannedOrderList, isLoading, handleScanActions, selectedOrderId }) => {
	const { t } = useTranslation()
	const [orderInfo, setOrderInfo] = useState({})
	const [inputManualCode, setInputManualCode] = useState('')
	const [invalidCode, setInvalidCode] = useState(false)

	useEffect(() => {
		if (scannedOrderList.length > 0 && selectedOrderId) {
			const selectedScannedOrder = scannedOrderList.find((order) => order.id == selectedOrderId.orderId)
			setOrderInfo(selectedScannedOrder)
		}
	}, [selectedOrderId, scannedOrderList])

	/**
	 * FORMATO DE CODIGO:
	 * orderId
	 * S
	 * orderFragmentId
	 * - o \
	 * partId
	 * - o \
	 * quantity
	 * 
	 * EJEMPLO:
	 * 40860S1-1605813196-666
	 * 40860S1\1605813196\666
	 */
	const getCodeDataBySplit = (split) => {
		const splitCode = inputManualCode.split(split)
		if (splitCode.length === 3 && splitCode[0].includes('S')) {
			const orderIdAndFragmentId = splitCode[0]
			const idPart = splitCode[1]
			const quantity = splitCode[2]
			const [orderNumber, fragment] = orderIdAndFragmentId.split('S')
			return { orderNumber, idPart, quantity, fragment }
		} else {
			setInvalidCode(true)
			return null
		}
	}

	const getManualScanData = (inputManualCode) => {
		let code = null
		if (inputManualCode.includes('-')) {
			code = getCodeDataBySplit('-')
		} else {
			code = getCodeDataBySplit('\'')
		}
		if (code != null) {
			const { orderNumber, idPart, quantity, fragment } = code
			handleScanActions(orderNumber, idPart, quantity, fragment)
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			const inputManualCodeData = getManualScanData(inputManualCode)
			if (inputManualCodeData != null) {
				handleScanActions(inputManualCodeData)
			}
		}
	}
	return (
		<>
			<div className='bg-white'>
				<div
					className="w-100 p-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', backgroundColor: 'whitesmoke' }}
				>
					{scannedOrderList?.length} {t('ordersInTheList', { ns: 'naming' })}
				</div>
				<div
					className="w-100 p-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					<Input
						placeholder="Introducir código manualmente"
						onChange={(e) => setInputManualCode(e.target.value)}
						onKeyDown={(e) => handleKeyDown(e)}
						invalid={invalidCode}
					/>
				</div>
			</div>
			{Object.keys(orderInfo).length != 0 && !isLoading &&
				<div className='mt-3 bg-white'>
					<div
						className="w-100 p-2 d-flex align-items-center"
						style={{
							border: '0.5px solid lightgray',
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
							backgroundColor: 'whitesmoke',
						}}
					>
						{t('shipment-data', { ns: 'naming' })}
					</div>
					<div
						className="w-100 ps-2 d-column align-items-center"
						style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
					>
						<p className="pt-3">
							{t('name', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.shippingAddress?.firstName} {orderInfo?.shippingAddress?.lastName}
							</strong>
						</p>
						<p>{t('company', { ns: 'naming' })}: <strong>{orderInfo?.shippingAddress?.company}</strong></p>
						<p>
							{t('address', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.shippingAddress?.postalCode} -&nbsp;
								{orderInfo?.shippingAddress?.city} -&nbsp;
								{orderInfo?.shippingAddress?.country}
							</strong>
						</p>
					</div>
				</div>
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		orderDetail: state.Orders.orderDetail,
		isLoading: state.Orders.isLoading,
		scannedOrderList: state.Orders.scannedOrderList,
		selectedOrderId: state.Orders.selectedOrderId,
	}
}

SearchAndClientInfo.propTypes = {
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	isLoading: PropTypes.bool,
	setSelectedOrderId: PropTypes.func,
	scannedOrderList: PropTypes.array,
	selectedOrderId: PropTypes.object,
	setManualScanned: PropTypes.func,
	handleScanActions: PropTypes.func,
}

export default connect(mapStateToProps, { setSelectedOrderId })(
	withTranslation()(SearchAndClientInfo),
)
