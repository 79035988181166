import { VerificationStatus } from 'constants/verificationStatus'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
	addScannedVerificationOrder,
	getBoxList,
	getOpenedVerificationOrders,
	getOrderDetailById,
	setLastScannedPart,
	setOrderBoxCode,
	setSelectedVerificationOrderId,
} from 'store/actions'
import useScanDetection from 'use-scan-detection'
import OrderSummaryList from './OrderSummaryList'

const VerificationOrders = ({
	t,
	orderDetail,
	getOrderDetailById,
	isLoadingOrders,
	scannedOrderList,
	addScannedVerificationOrder,
	setSelectedVerificationOrderId,
	selectedOrderId,
	manualScanned,
	setManualScanned,
	boxList,
	setLastScannedPart,
	getOpenedVerificationOrders,
	openedOrders,
	getBoxList,
	getBoxListStatus = {},
}) => {
	const [scannedPart, setScannedPart] = useState(null)
	const [scannedOrder, setScannedOrder] = useState(null)
	const [scannedList, setScannedList] = useState([])
	const [scannedQuantity, setScannedQuantity] = useState(1)

	const scanPart = (code) => {
		if (code.includes('-')) {
			const shiftIndex = code.indexOf('ShiftS')
			const orderId = code.substring(0, shiftIndex)
			const startIndex = code.indexOf('-')
			const endIndex = code.lastIndexOf('-')
			const partId = code.substring(startIndex + 1, endIndex)
			return { orderId, partId }
		}
		if (code.includes('\'')) {
			const shiftIndex = code.indexOf('ShiftS')
			const orderId = code.substring(0, shiftIndex)
			const startIndex = code.indexOf('\'')
			const endIndex = code.lastIndexOf('\'')
			const partId = code.substring(startIndex + 1, endIndex)
			return { orderId, partId }
		}
		// eslint-disable-next-line no-dupe-keys
		return { undefined, undefined }
	}

	useScanDetection({
		onComplete: code => {
			const { orderId, partId } = scanPart(code)
			if (orderId && partId) {
				setScannedOrder(Number(orderId))
				setScannedPart(Number(partId))
				setLastScannedPart(Number(partId))
			}
		},
	})

	useEffect(() => {
		getOpenedVerificationOrders()
		getBoxList()
	}, [])

	function getTotalPieces(items) {
		let total = 0
		items?.forEach(item => {
			total += item.quantity
		})
		return total
	}

	function parseScannedOrders() {
		const parsedScannedOrderList = []
		scannedOrderList.forEach(scannedOrder => {
			const orderData = {
				id: scannedOrder.order?.id,
				organizationName: scannedOrder.order?.shippingAddress?.company,
				references: scannedOrder.order?.orderItems?.length,
				totalPieces: getTotalPieces(scannedOrder.order?.orderItems),
				shipmentDate: scannedOrder.order?.estimatedShipmentDate?.slice(0, 10) || scannedOrder.order?.productions[0]?.realShipmentDate?.slice(0, 10),
				completed: scannedOrder.completed,
				box: scannedOrder.boxCode != '' ? scannedOrder.boxCode : t('no-box', { ns: 'naming' }),
				isOK: checkOrderState(scannedOrder.qualityPartStates),
				receivingCountry: scannedOrder.order?.shippingAddress?.country,
			}
			parsedScannedOrderList.push(orderData)
		})
		setScannedList(parsedScannedOrderList)
	}

	const checkIfOrderIsCompleted = orderDetail => {
		if (orderDetail.qualityPartStates == null || orderDetail.qualityPartStates.length == 0) return VerificationStatus.PENDING
		if (orderDetail.qualityPartStates.length < orderDetail.order.orderItems.length) return VerificationStatus.PROCESSING
		if (orderDetail.qualityPartStates.length == orderDetail.order.orderItems.length) return VerificationStatus.COMPLETED
	}

	const checkBoxCode = orderId => {
		const boxCodes = []
		boxList.forEach(box => {
			if (box.orderId == orderId) {
				boxCodes.push(box.ref)
			}
		})
		return boxCodes.join(', ')
	}

	const checkOrderState = qualityPartStates => {
		return qualityPartStates?.every(e => e.state)
	}

	useEffect(() => {
		if (openedOrders.length > 0 && boxList) {
			openedOrders.forEach(openedOrder => {
				const orderData = {
					...openedOrder,
					completed: checkIfOrderIsCompleted(openedOrder),
					boxCode: checkBoxCode(openedOrder.order.id),
				}
				addScannedVerificationOrder(orderData)
			})
		}
	}, [openedOrders, getBoxListStatus])

	useEffect(() => {
		if (scannedOrder && scannedPart) {
			const orderExists = scannedOrderList.find(order => order.order.id == scannedOrder)
			if (!orderExists) getOrderDetailById(scannedOrder)
			else {
				setSelectedVerificationOrderId(orderExists.order.id)
				setScannedPart(null)
				setScannedQuantity(1)
				setScannedOrder(null)
				if (manualScanned) setManualScanned(null)
			}
		}
	}, [scannedOrder, scannedPart])

	useEffect(() => {
		if (Object.keys(orderDetail).length > 0 && scannedOrder) {
			const orderDetailAdapted = {
				billingInfo: orderDetail.order.billingInfo,
				completed: checkIfOrderIsCompleted(orderDetail),
				boxCode: checkBoxCode(orderDetail.order.id),
				id: orderDetail.order.id,
				orderItems: orderDetail.order.orderItems,
				isOK: checkOrderState(orderDetail.qualityPartStates),
			}
			addScannedVerificationOrder(orderDetailAdapted)
			setSelectedVerificationOrderId(orderDetailAdapted.id)
			setScannedPart(null)
			setScannedQuantity(1)
			setScannedOrder(null)
			if (manualScanned) setManualScanned(null)
		}
	}, [orderDetail])

	useEffect(() => {
		parseScannedOrders()
	}, [scannedOrderList])

	useEffect(() => {
		if (manualScanned) {
			const regex = /^(\d+)S\d+-(\d+)-(\d+)$/
			const resultado = manualScanned.match(regex)
			setScannedOrder(Number(resultado[1]))
			setScannedPart(Number(resultado[2]))
			setLastScannedPart(Number(resultado[2]))
			setScannedQuantity(Number(resultado[3]))
		}
	}, [manualScanned])

	return (
		<div>
			<div className="mb-2 p-4 bg-white">
				<OrderSummaryList
					title={t('opened_orders', { ns: 'naming' })}
					orders={scannedList}
					isLoading={isLoadingOrders}
					selectedOrderId={selectedOrderId}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		isLoadingOrders: state.Orders.isLoading,
		orderDetail: state.Orders.orderDetail,
		scannedOrderList: state.Orders.scannedVerificationOrderList,
		selectedOrderId: state.Orders.selectedVerificationOrderId?.orderId,
		boxList: state.Orders.boxList,
		getBoxListStatus: state.Orders.getBoxListStatus,
		openedOrders: state.Orders.openedVerificationOrders,
	}
}

VerificationOrders.propTypes = {
	t: PropTypes.func,
	isLoadingOrders: PropTypes.bool,
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	scannedOrderList: PropTypes.array,
	addScannedVerificationOrder: PropTypes.func,
	setSelectedVerificationOrderId: PropTypes.func,
	selectedOrderId: PropTypes.number,
	manualScanned: PropTypes.number,
	setManualScanned: PropTypes.func,
	getBoxList: PropTypes.func,
	boxList: PropTypes.array,
	getBoxListStatus: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
	setLastScannedPart: PropTypes.func,
	getOpenedVerificationOrders: PropTypes.func,
	openedOrders: PropTypes.array,
}

export default connect(mapStateToProps, {
	getOrderDetailById,
	addScannedVerificationOrder,
	setSelectedVerificationOrderId,
	setOrderBoxCode,
	setLastScannedPart,
	getOpenedVerificationOrders,
	getBoxList,
})(withTranslation()(VerificationOrders))
