import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { ConfigTranslations } from 'common/Utils/PartConfigTranslations'
import Table from 'components/Table/Table'
import AppRoutes from 'constants/appRoutes'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const OrderTable = ({ t, i18n: { language }, orderItemList, partConfigOptions }) => {
	const header = {
		item: t('item', { ns: 'naming' }),
		id: t('id', { ns: 'naming' }),
		part: t('part', { ns: 'naming' }),
		technology: t('technology', { ns: 'naming' }),
		files: t('files', { ns: 'naming' }),
		description: t('description', { ns: 'naming' }),
		price: t('price', { ns: 'naming' }),
		quantity: t('quantity', { ns: 'naming' }),
		total: t('total', { ns: 'naming' }),
		weight: t('weight', { ns: 'naming' }),
		comment: t('comment', { ns: 'naming' }),
		offer: t('offer', { ns: 'naming' }),
	}

	return (
		<div className="mb-4 px-3">
			<Table
				disableInteractions
				header={header}
				items={orderItemList}
				selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
				language={getLanguage()}
				paginationProps={{
					custom: true,
					sizePerPage: 200,
				}}
				rowQuantityList={[{ text: '32', value: 32 }]}
				allowAllItemsPerPage
				defaultSort={{
					dataField: 'item',
					order: 'desc',
				}}
				pageToNavigate={AppRoutes.OFFER_DETAIL_ROUTE_NO_ID}
				styleForFieldList={[
					{
						field: 'technology',
						styles: [
							{
								values: [ConfigTranslations.getTechnologyName({ id: 1, partConfigOptions, language })],
								class: 'rounded-pill bg-olive text-white',
							},
							{
								values: [ConfigTranslations.getTechnologyName({ id: 2, partConfigOptions, language })],
								class: 'rounded-pill bg-turquoise text-white',
							},
							{
								values: [ConfigTranslations.getTechnologyName({ id: 4, partConfigOptions, language })],
								class: 'rounded-pill bg-pink text-white',
							},
						],
					},
					{
						field: 'offer',
						styles: [
							{
								type: 'link',
								class: '',
							},
						],
					},
					{
						field: 'description',
						styles: [{ type: 'list', class: 'd-block' }],
					},
				]}
			></Table>
		</div>
	)
}
OrderTable.propTypes = {
	t: PropTypes.func,
	orderItemList: PropTypes.array,
	partConfigOptions: PropTypes.object,
}
export default withTranslation()(OrderTable)
