import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { roundCurrency } from 'common/Utils/NumberUtilities'
import { PartConfigTranslations, getClassnameByTechnology } from 'common/Utils/PartConfigTranslations'
import { getFullPartDescription } from 'common/Utils/PartUtils'
import BlueprintAndStp from 'components/Common/BlueprintAndStp'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import PartThumbnail from 'components/Common/PartThumbnail'
import { NoteTooltip } from 'components/NoteTooltip'
import ProductionComment from 'pages/NewProduction/ProductionComment'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { getOrderDetail, getProductionDetail } from 'store/actions'
import ProductionCustomer from './ProductionCustomer'
import ProductionParts from './ProductionParts'
import ProductionSummary from './ProductionSummary'
import ProductionSupplier from './ProductionSupplier'

const ProductionDetail = ({
	t,
	i18n: { language },
	productionDetail,
	isLoading,
	getProductionDetail,
	getOrderDetail,
	orderDetail,
	isLoadingOrderDetail,
	partConfigOptions,
	partConfigOptionsLoading,
}) => {

	const [itemsToRender, setItemsToRender] = useState()

	useEffect(() => {
		const productionId = getProductionIdFromURL()
		const splitProductionId = productionId.split('-')
		getProductionDetail(splitProductionId[0], splitProductionId[1])
	}, [])

	useEffect(() => {
		if (productionDetail && productionDetail.production && productionDetail?.production?.productionItemList?.length !== 0) {
			getPartList()
		}
		if (productionDetail && productionDetail?.production?.orderId) {
			getOrderDetail(productionDetail.production.orderId)
		}
	}, [productionDetail?.production?.productionItemList, partConfigOptionsLoading])

	const [languageChangedOnce, setLanguageChangedOnce] = useState(false)
	useEffect(() => {
		if (languageChangedOnce) history.go(0)
		else setLanguageChangedOnce(true)
	}, [language])

	const getPartList = () => {
		const partList = []
		for (const productionItem of productionDetail?.production?.productionItemList) {
			const { part } = productionItem
			partList.push({
				partId: { value: part.id },
				part: <PartThumbnail propsStlUrl={part.fileLinks.stlModel} propsImageUrl={part.fileLinks.thumbnail} stlData={{
					partId: part.id,
					size: part.size,
					weight: part.weight,
					partName: part.name,
				}}
				/>,
				technology: (
					<div className={getClassnameByTechnology({ part })}>
						{PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language })}
					</div>
				),
				files: <BlueprintAndStp propsBlueprintUrl={part.fileLinks.modifiedBlueprint ? part.fileLinks.modifiedBlueprint : part.fileLinks.originalBlueprint} propsModel3dUrl={part.fileLinks.stepModel} />,
				description: {
					value: {
						name: part.name,
						title: getFullPartDescription(part, partConfigOptions, language),
					},
				},
				price: { value: `${roundCurrency(productionItem.unitPurchasePrice)}€` },
				quantity: { value: productionItem.totalQuantity },
				total: { value: `${roundCurrency(productionItem.totalPurchasePrice)}€` },
				// chat: <ChatIcon quantityUnread={2} production={productionItem} />, --> TODO ProductionChat no funciona todavía
				note: <NoteTooltip note={productionItem?.part?.comment} t={t} />,
			})
		}
		setItemsToRender(partList)
	}

	return (
		<div className="page-content">
			<MetaTags>
				<title>Proto&Go! | {t('production-detail', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('management', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('productions', { ns: 'naming' }),
						link: '/productions',
					},
					{
						item: t('production-detail', { ns: 'naming' }),
					},
				]}
			/>
			{isLoading ? (
				<Loader className="font-size-80 mx-auto" />
			) : itemsToRender && (
				<div>
					<Row className="mx-1">
						<Col className="col bg-white p-3 me-3 border">
							<ProductionCustomer
								orderDetail={orderDetail}
								isLoadingOrderDetail={isLoadingOrderDetail}
							/>
						</Col>
						<Col className="col bg-white p-3 me-3 border">
							<ProductionSupplier production={productionDetail.production} />
						</Col>
						<Col className="col bg-white p-3 border ">
							<ProductionSummary
								production={productionDetail.production}
								orderDetail={orderDetail}
								isLoadingOrderDetail={isLoadingOrderDetail}
							/>
						</Col>
					</Row>
					<ProductionComment t={t} clientComment={productionDetail.production.comment} />
					<Row className="mx-1 mt-3">
						<Col className="col bg-white p-3"><ProductionParts partList={itemsToRender} /></Col>
					</Row>
				</div>
			)}
		</div>
	)
}

const getProductionIdFromURL = () => {
	const id = window.location.pathname.split('/')
	return id[id.length - 1]
}

const mapStateToProps = state => {
	return {
		productionDetail: state.Productions.productionDetail,
		isLoading: state.Productions.isLoading,
		isLoadingOrderDetail: state.Orders.isLoading,
		orderDetail: state.Orders.orderDetail,
		error: state.error,
	}
}

ProductionDetail.propTypes = {
	t: PropTypes.func,
	productionDetail: PropTypes.object,
	isLoading: PropTypes.bool,
	isLoadingOrderDetail: PropTypes.bool,
	getProductionDetail: PropTypes.func,
	getOrderDetail: PropTypes.func,
	orderDetail: PropTypes.object,
	getSuppliers: PropTypes.func,
}

export default withGetPartConfigOptions(connect(mapStateToProps, { getProductionDetail, getOrderDetail })(
	withTranslation()(ProductionDetail),
))
