import { downloadXLSX } from 'common/Utils/downloadUtils/downloadXlsx'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useDraggable } from 'react-use-draggable-scroll'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

/**
 * @example
 * args: {
 *   headers: {
 * 	   [columnName: string]: any;
 *   };
 *   headerProps?: {
 * 	   [columnName: string]: HTMLProps;
 *   };
 *   rows: {
 * 	   [columnName: string]: any;
 *   }[];
 *   rowsExport: {
 * 	   [columnName: string]: number | string;
 *   }[];
 *   currentPage: number;
 *   currentPageSize: number;
 *   setPage: (page: number) => void;
 *   setPageSize: (pageSize: number) => void;
 *   totalCount: number;
 *   showButtonExport?: boolean;
 * }
 */
export const Table2 = ({
	t,
	headers,
	headerProps,
	rows,
	rowsExport,
	exportFileName,
	showExportButton,
	currentPage,
	currentPageSize,
	setPage,
	setPageSize,
	totalCount,
	hidePagination,
}) => {

	currentPageSize = currentPageSize || totalCount
	const resultsFound = rows != null && rows.length > 0

	const tableRef = useRef()
	const { events } = useDraggable(tableRef)

	const tableElement = (
		<div className="table-responsive" {...events} ref={tableRef}>
			<table className="table align-middle table-nowrap table-bordered table2style">
				<thead>
					<tr>
						{Object.keys(headers || {}).map((key) => {
							const value = headers[key]
							return (
								<th key={`header-${key}`} {...headerProps?.[key]}>
									{typeof value === 'function' ? value() : value}
								</th>
							)
						})}
					</tr>
				</thead>
				<tbody>
					{resultsFound && rows.map((row, i) => (
						<tr key={`row-${i}`}>
							{Object.keys(headers).map((key) => {
								const value = row[key]
								return (
									<td key={`row-${i}-${key}`}>
										{typeof value === 'function' ? value() : value}
									</td>
								)
							})}
						</tr>
					))}
				</tbody>
			</table>
			{!resultsFound && (
				<div className="d-flex justify-content-center">
					{t('no_results_found', { ns: 'naming' })}
				</div>
			)}
		</div>
	)

	const [dropdownPageSizeOpen, setDropdownPageSizeOpen] = useState(false)
	const [dropdownPageOpen, setDropdownPageOpen] = useState(false)

	const DEFAULT_PAGE_SIZES = [10, 25, 50, 100, 150, 200]

	const selectPageSizeElement = (
		<Dropdown
			isOpen={dropdownPageSizeOpen}
			toggle={() => setDropdownPageSizeOpen(prevState => !prevState)}
		>
			<DropdownToggle caret>
				{currentPageSize}{' / '}{totalCount}
			</DropdownToggle>
			<DropdownMenu>
				{DEFAULT_PAGE_SIZES
					.map(pageSize => (
						<DropdownItem
							key={`pageSize-${pageSize}`}
							onClick={() => {
								setPageSize(pageSize)
								setPage(1)
							}}
						>
							{pageSize}{' / '}{totalCount}
						</DropdownItem>
					))}
				<DropdownItem
					onClick={() => setPageSize(null)}
				>
					{t('all', { ns: 'naming' })}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	)

	const maxPage = Math.ceil(totalCount / currentPageSize)
	const pageMinus = currentPage - 2
	const pagePlus = currentPage + 2
	const firstPageButtonVisible = currentPage < 4
	const lastPageButtonVisible = currentPage > maxPage - 4

	const selectSpecificPageElement = (
		<Dropdown
			isOpen={dropdownPageOpen}
			toggle={() => setDropdownPageOpen(prevState => !prevState)}
		>
			<DropdownToggle
				className="page-link page-dropdown-button"
				caret
			>
				{'...'}
			</DropdownToggle>
			<DropdownMenu
				end
				className="dropdown-menu"
			>
				{Array.from({ length: maxPage })
					.map((x, i) => i + 1)
					.map(pageNum => (
						<DropdownItem
							key={`select-page-${pageNum}`}
							onClick={() => setPage(pageNum)}
						>
							{pageNum}
						</DropdownItem>
					))}
			</DropdownMenu>
		</Dropdown>
	)

	const selectPageElement = (
		<ul className="pagination react-bootstrap-table-page-btns-ul">
			{!firstPageButtonVisible && (
				<li className="page-item" title="previous page">
					<a onClick={() => setPage(currentPage - 1)} className="page-link">&lt;</a>
				</li>
			)}
			{(!firstPageButtonVisible && lastPageButtonVisible) && selectSpecificPageElement}
			{Array.from({ length: maxPage })
				.map((x, i) => i + 1)
				.filter(pageNum => pageMinus <= pageNum && pageNum <= pagePlus)
				.map(pageNum => {
					return (
						<li
							key={`pageNum-${pageNum}`}
							className={`${currentPage === pageNum ? 'active' : ''} page-item`}
							title={`${pageNum}`}
						>
							<a
								onClick={() => setPage(pageNum)}
								className="page-link">
								{pageNum}
							</a>
						</li>
					)
				})}
			{(firstPageButtonVisible && !lastPageButtonVisible) && selectSpecificPageElement}
			{!lastPageButtonVisible && (
				<li className="page-item" title="next page">
					<a onClick={() => setPage(currentPage + 1)} className="page-link">&gt;</a>
				</li>
			)}
			{(!firstPageButtonVisible && !lastPageButtonVisible) && selectSpecificPageElement}
		</ul>
	)

	const paginationElement = resultsFound && (
		<div className="align-items-md-center mt-30 row">
			<div className="inner-custom-pagination d-flex col">
				<div className="d-inline">
					{selectPageSizeElement}
				</div>
				<div className="text-md-right ms-auto">
					{selectPageElement}
				</div>
			</div>
		</div>
	)

	const buttonExportElement = (
		<button
			className="btn btn-secondary"
			onClick={() => {
				downloadXLSX({
					rows: [headers, ...rowsExport],
					fileName: exportFileName,
				})
			}}
		>
			{t('export', { ns: 'naming' })}
		</button>
	)

	return (
		<div>
			{tableElement}
			{!hidePagination && paginationElement}
			{rowsExport && showExportButton && buttonExportElement}
		</div>
	)
}

Table2.propTypes = {
	t: PropTypes.func.isRequired,
	headers: PropTypes.object.isRequired,
	headerProps: PropTypes.object,
	rows: PropTypes.array,
	rowsExport: PropTypes.arrayOf(
		PropTypes.objectOf(
			PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]))),
	exportFileName: PropTypes.string,
	showExportButton: PropTypes.bool,
	currentPage: PropTypes.number.isRequired,
	currentPageSize: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	setPageSize: PropTypes.func.isRequired,
	totalCount: PropTypes.number,
	hidePagination: PropTypes.bool,
}
